/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * IdeafactoryAPI
 * Ideafactory API
 *
 * OpenAPI spec version: 1.0
 * Contact: jan.illetschko@3kraft.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "http://localhost/api".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface ActivityLog
 */
export interface ActivityLog {
    /**
     * 
     * @type {number}
     * @memberof ActivityLog
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityLog
     */
    type?: ActivityLog.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ActivityLog
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLog
     */
    details?: string;
    /**
     * 
     * @type {Date}
     * @memberof ActivityLog
     */
    timestamp?: Date;
    /**
     * 
     * @type {User}
     * @memberof ActivityLog
     */
    user?: User;
    /**
     * 
     * @type {Idea}
     * @memberof ActivityLog
     */
    idea?: Idea;
}

/**
 * @export
 * @namespace ActivityLog
 */
export namespace ActivityLog {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        IDEACREATE = <any> 'IDEA_CREATE',
        IDEADELETE = <any> 'IDEA_DELETE',
        IDEAEDIT = <any> 'IDEA_EDIT',
        IDEACOMMENT = <any> 'IDEA_COMMENT',
        IDEALIKE = <any> 'IDEA_LIKE',
        IDEARATING = <any> 'IDEA_RATING',
        IDEAVIEW = <any> 'IDEA_VIEW',
        IDEABOOKMARK = <any> 'IDEA_BOOKMARK',
        IDEASHARE = <any> 'IDEA_SHARE',
        IDEASUBMIT = <any> 'IDEA_SUBMIT',
        COMMENTREPLY = <any> 'COMMENT_REPLY',
        COMMENTLIKE = <any> 'COMMENT_LIKE',
        COMMENTEDIT = <any> 'COMMENT_EDIT',
        EVALUATIONCREATED = <any> 'EVALUATION_CREATED',
        EVALUATIONEDITED = <any> 'EVALUATION_EDITED',
        EVALUATIONDELETED = <any> 'EVALUATION_DELETED',
        EVALUATIONSUBMITTED = <any> 'EVALUATION_SUBMITTED',
        EVALUATIONASSESSED = <any> 'EVALUATION_ASSESSED',
        USERLOGIN = <any> 'USER_LOGIN',
        USERCREATED = <any> 'USER_CREATED',
        PROFILEIMAGE = <any> 'PROFILE_IMAGE',
        UNKNOWN = <any> 'UNKNOWN'
    }
}

/**
 * 
 * @export
 * @interface AppState
 */
export interface AppState {
    [key: string]: string;

}

/**
 * 
 * @export
 * @interface AppVars
 */
export interface AppVars {
    /**
     * 
     * @type {boolean}
     * @memberof AppVars
     */
    showLikesInOverview?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AppVars
     */
    newsWidgetsPageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof AppVars
     */
    campaignsPageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof AppVars
     */
    homeListsLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof AppVars
     */
    homeNewsLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof AppVars
     */
    homeCommentsLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof AppVars
     */
    notificationsLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof AppVars
     */
    rankingsLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof AppVars
     */
    evaluationDeadline?: number;
    /**
     * 
     * @type {number}
     * @memberof AppVars
     */
    inactiveUserIntervalMonths?: number;
    /**
     * 
     * @type {number}
     * @memberof AppVars
     */
    evaluationReminderIntervalDays?: number;
    /**
     * 
     * @type {number}
     * @memberof AppVars
     */
    recentIntervalDays?: number;
    /**
     * 
     * @type {string}
     * @memberof AppVars
     */
    emailFrom?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppVars
     */
    emailCc?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppVars
     */
    emailBcc?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof AppVars
     */
    userScoreTypePoints?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof AppVars
     */
    textLengthIndicators?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: Array<number>; }}
     * @memberof AppVars
     */
    userAwardThresholds?: { [key: string]: Array<number>; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof AppVars
     */
    scoreBonus?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: OptionItem; }}
     * @memberof AppVars
     */
    departments?: { [key: string]: OptionItem; };
    /**
     * 
     * @type {{ [key: string]: OptionItem; }}
     * @memberof AppVars
     */
    positions?: { [key: string]: OptionItem; };
}

/**
 * 
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    filename?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Attachment
     */
    image?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Attachment
     */
    mainImage?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Attachment
     */
    timestamp?: Date;
}

/**
 * 
 * @export
 * @interface Attachment2
 */
export interface Attachment2 {
    /**
     * 
     * @type {number}
     * @memberof Attachment2
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Attachment2
     */
    filename?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Attachment2
     */
    image?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Attachment2
     */
    mainImage?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Attachment2
     */
    timestamp?: Date;
}

/**
 * 
 * @export
 * @interface Campaign
 */
export interface Campaign {
    /**
     * 
     * @type {number}
     * @memberof Campaign
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    start?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    end?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    sliderImage?: string;
    /**
     * 
     * @type {User}
     * @memberof Campaign
     */
    createdBy?: User;
    /**
     * 
     * @type {Date}
     * @memberof Campaign
     */
    timestamp?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Campaign
     */
    created?: Date;
    /**
     * 
     * @type {Widget}
     * @memberof Campaign
     */
    widget?: Widget;
    /**
     * 
     * @type {Array<Idea>}
     * @memberof Campaign
     */
    ideaList?: Array<Idea>;
}

/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    defaultImage?: string;
}

/**
 * 
 * @export
 * @interface CertificateInfo
 */
export interface CertificateInfo {
    /**
     * 
     * @type {boolean}
     * @memberof CertificateInfo
     */
    issued: boolean;
    /**
     * 
     * @type {string}
     * @memberof CertificateInfo
     */
    text?: string;
}

/**
 * 
 * @export
 * @interface Comment
 */
export interface Comment {
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof Comment
     */
    timestamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    likesCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    likesCntRecent?: number;
    /**
     * 
     * @type {Array<Comment>}
     * @memberof Comment
     */
    replyList?: Array<Comment>;
    /**
     * 
     * @type {Idea}
     * @memberof Comment
     */
    idea?: Idea;
    /**
     * 
     * @type {User}
     * @memberof Comment
     */
    user?: User;
    /**
     * 
     * @type {Array<Attachment2>}
     * @memberof Comment
     */
    attachmentList?: Array<Attachment2>;
}

/**
 * 
 * @export
 * @interface CommentActivityLog
 */
export interface CommentActivityLog {
    /**
     * 
     * @type {number}
     * @memberof CommentActivityLog
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CommentActivityLog
     */
    type?: CommentActivityLog.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CommentActivityLog
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentActivityLog
     */
    details?: string;
    /**
     * 
     * @type {Date}
     * @memberof CommentActivityLog
     */
    timestamp?: Date;
    /**
     * 
     * @type {User}
     * @memberof CommentActivityLog
     */
    user?: User;
    /**
     * 
     * @type {Comment}
     * @memberof CommentActivityLog
     */
    comment?: Comment;
}

/**
 * @export
 * @namespace CommentActivityLog
 */
export namespace CommentActivityLog {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        IDEACREATE = <any> 'IDEA_CREATE',
        IDEADELETE = <any> 'IDEA_DELETE',
        IDEAEDIT = <any> 'IDEA_EDIT',
        IDEACOMMENT = <any> 'IDEA_COMMENT',
        IDEALIKE = <any> 'IDEA_LIKE',
        IDEARATING = <any> 'IDEA_RATING',
        IDEAVIEW = <any> 'IDEA_VIEW',
        IDEABOOKMARK = <any> 'IDEA_BOOKMARK',
        IDEASHARE = <any> 'IDEA_SHARE',
        IDEASUBMIT = <any> 'IDEA_SUBMIT',
        COMMENTREPLY = <any> 'COMMENT_REPLY',
        COMMENTLIKE = <any> 'COMMENT_LIKE',
        COMMENTEDIT = <any> 'COMMENT_EDIT',
        EVALUATIONCREATED = <any> 'EVALUATION_CREATED',
        EVALUATIONEDITED = <any> 'EVALUATION_EDITED',
        EVALUATIONDELETED = <any> 'EVALUATION_DELETED',
        EVALUATIONSUBMITTED = <any> 'EVALUATION_SUBMITTED',
        EVALUATIONASSESSED = <any> 'EVALUATION_ASSESSED',
        USERLOGIN = <any> 'USER_LOGIN',
        USERCREATED = <any> 'USER_CREATED',
        PROFILEIMAGE = <any> 'PROFILE_IMAGE',
        UNKNOWN = <any> 'UNKNOWN'
    }
}

/**
 * 
 * @export
 * @interface Evaluation
 */
export interface Evaluation {
    /**
     * 
     * @type {number}
     * @memberof Evaluation
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Evaluation
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Evaluation
     */
    adminDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof Evaluation
     */
    implementationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Evaluation
     */
    implementationDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof Evaluation
     */
    score?: number;
    /**
     * 
     * @type {number}
     * @memberof Evaluation
     */
    bonus?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Evaluation
     */
    submitted: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Evaluation
     */
    timestamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof Evaluation
     */
    implement?: Evaluation.ImplementEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Evaluation
     */
    revise?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Evaluation
     */
    evaluated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Evaluation
     */
    postponeUntil?: string;
    /**
     * 
     * @type {string}
     * @memberof Evaluation
     */
    postponeReason?: Evaluation.PostponeReasonEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Evaluation
     */
    postponeAccepted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Evaluation
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Evaluation
     */
    created?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Evaluation
     */
    expertApproved?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Evaluation
     */
    submittedTimestamp?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Evaluation
     */
    postponeTimestamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof Evaluation
     */
    assessmentComment?: string;
    /**
     * 
     * @type {string}
     * @memberof Evaluation
     */
    handoverComment?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Evaluation
     */
    assessed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Evaluation
     */
    implementationApproved?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Evaluation
     */
    archived?: boolean;
    /**
     * 
     * @type {User}
     * @memberof Evaluation
     */
    expertUser?: User;
    /**
     * 
     * @type {User}
     * @memberof Evaluation
     */
    handoverUser?: User;
    /**
     * 
     * @type {Idea}
     * @memberof Evaluation
     */
    idea?: Idea;
    /**
     * 
     * @type {Array<EvaluationCriteria>}
     * @memberof Evaluation
     */
    criteriaList?: Array<EvaluationCriteria>;
    /**
     * 
     * @type {Array<EvaluationAssessmentCriteria>}
     * @memberof Evaluation
     */
    assessmentCriteriaList?: Array<EvaluationAssessmentCriteria>;
    /**
     * 
     * @type {Array<Attachment2>}
     * @memberof Evaluation
     */
    attachmentList?: Array<Attachment2>;
    /**
     * 
     * @type {Array<Attachment2>}
     * @memberof Evaluation
     */
    implementationAttachmentList?: Array<Attachment2>;
}

/**
 * @export
 * @namespace Evaluation
 */
export namespace Evaluation {
    /**
     * @export
     * @enum {string}
     */
    export enum ImplementEnum {
        Implement = <any> 'implement',
        Rejected = <any> 'rejected',
        NotNew = <any> 'notNew'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum PostponeReasonEnum {
        WaitForDecision = <any> 'waitForDecision',
        MoreInformation = <any> 'moreInformation',
        Absence = <any> 'absence',
        Unknown = <any> 'unknown'
    }
}

/**
 * 
 * @export
 * @interface EvaluationAssessmentCriteria
 */
export interface EvaluationAssessmentCriteria {
    /**
     * 
     * @type {number}
     * @memberof EvaluationAssessmentCriteria
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof EvaluationAssessmentCriteria
     */
    score?: number;
    /**
     * 
     * @type {Date}
     * @memberof EvaluationAssessmentCriteria
     */
    timestamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof EvaluationAssessmentCriteria
     */
    type?: EvaluationAssessmentCriteria.TypeEnum;
}

/**
 * @export
 * @namespace EvaluationAssessmentCriteria
 */
export namespace EvaluationAssessmentCriteria {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Plausability = <any> 'plausability',
        ResponseTime = <any> 'responseTime',
        Wording = <any> 'wording'
    }
}

/**
 * 
 * @export
 * @interface EvaluationCriteria
 */
export interface EvaluationCriteria {
    /**
     * 
     * @type {number}
     * @memberof EvaluationCriteria
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof EvaluationCriteria
     */
    score?: number;
    /**
     * 
     * @type {string}
     * @memberof EvaluationCriteria
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof EvaluationCriteria
     */
    timestamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof EvaluationCriteria
     */
    type?: EvaluationCriteria.TypeEnum;
}

/**
 * @export
 * @namespace EvaluationCriteria
 */
export namespace EvaluationCriteria {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Relevance = <any> 'relevance',
        Benefit = <any> 'benefit',
        InnovationPotential = <any> 'innovationPotential',
        ImplementationEffort = <any> 'implementationEffort',
        Usp = <any> 'usp'
    }
}

/**
 * 
 * @export
 * @interface ExtraProfile
 */
export interface ExtraProfile {
    /**
     * 
     * @type {string}
     * @memberof ExtraProfile
     */
    position?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtraProfile
     */
    relation?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtraProfile
     */
    employment?: string;
}

/**
 * 
 * @export
 * @interface Idea
 */
export interface Idea {
    /**
     * 
     * @type {number}
     * @memberof Idea
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Idea
     */
    state?: Idea.StateEnum;
    /**
     * 
     * @type {string}
     * @memberof Idea
     */
    type?: Idea.TypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Idea
     */
    anonymous: boolean;
    /**
     * 
     * @type {Campaign}
     * @memberof Idea
     */
    campaign?: Campaign;
    /**
     * 
     * @type {number}
     * @memberof Idea
     */
    expertEval?: number;
    /**
     * 
     * @type {number}
     * @memberof Idea
     */
    evaluationsCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof Idea
     */
    commentCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof Idea
     */
    likesCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof Idea
     */
    viewsCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof Idea
     */
    ratingsCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof Idea
     */
    ratingsSum?: number;
    /**
     * 
     * @type {number}
     * @memberof Idea
     */
    evaluationsCntRecent?: number;
    /**
     * 
     * @type {number}
     * @memberof Idea
     */
    commentCntRecent?: number;
    /**
     * 
     * @type {number}
     * @memberof Idea
     */
    likesCntRecent?: number;
    /**
     * 
     * @type {number}
     * @memberof Idea
     */
    viewsCntRecent?: number;
    /**
     * 
     * @type {Date}
     * @memberof Idea
     */
    timestamp?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Idea
     */
    created?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Idea
     */
    deleted: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Idea
     */
    statechanged?: Date;
    /**
     * 
     * @type {string}
     * @memberof Idea
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Idea
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Idea
     */
    advantage?: string;
    /**
     * 
     * @type {string}
     * @memberof Idea
     */
    audience?: string;
    /**
     * 
     * @type {string}
     * @memberof Idea
     */
    evaluation?: string;
    /**
     * 
     * @type {string}
     * @memberof Idea
     */
    editComment?: string;
    /**
     * 
     * @type {string}
     * @memberof Idea
     */
    feedback?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Idea
     */
    highlighted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Idea
     */
    published?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Idea
     */
    memberRegistration?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Idea
     */
    certificateText?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Idea
     */
    certificateIssued?: boolean;
    /**
     * 
     * @type {Array<Attachment2>}
     * @memberof Idea
     */
    attachmentList?: Array<Attachment2>;
    /**
     * 
     * @type {User}
     * @memberof Idea
     */
    modifiedUser?: User;
    /**
     * 
     * @type {User}
     * @memberof Idea
     */
    user?: User;
    /**
     * 
     * @type {Category}
     * @memberof Idea
     */
    category?: Category;
}

/**
 * @export
 * @namespace Idea
 */
export namespace Idea {
    /**
     * @export
     * @enum {string}
     */
    export enum StateEnum {
        Submission = <any> 'submission',
        Submitted = <any> 'submitted',
        Evaluation = <any> 'evaluation',
        Implementation = <any> 'implementation',
        Implemented = <any> 'implemented',
        Canceled = <any> 'canceled',
        NotNew = <any> 'notNew',
        Unknown = <any> 'unknown'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Decision = <any> 'decision',
        Unknown = <any> 'unknown'
    }
}

/**
 * 
 * @export
 * @interface IdeaMember
 */
export interface IdeaMember {
    /**
     * 
     * @type {number}
     * @memberof IdeaMember
     */
    id?: number;
    /**
     * 
     * @type {Idea}
     * @memberof IdeaMember
     */
    idea?: Idea;
    /**
     * 
     * @type {User}
     * @memberof IdeaMember
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof IdeaMember
     */
    role?: IdeaMember.RoleEnum;
    /**
     * 
     * @type {boolean}
     * @memberof IdeaMember
     */
    accepted?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof IdeaMember
     */
    timestamp?: Date;
}

/**
 * @export
 * @namespace IdeaMember
 */
export namespace IdeaMember {
    /**
     * @export
     * @enum {string}
     */
    export enum RoleEnum {
        Submitter = <any> 'submitter',
        Codeveloper = <any> 'codeveloper',
        Moderator = <any> 'moderator',
        ProjectManager = <any> 'project_manager'
    }
}

/**
 * 
 * @export
 * @interface IdeaStateDuration
 */
export interface IdeaStateDuration {
    /**
     * 
     * @type {number}
     * @memberof IdeaStateDuration
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof IdeaStateDuration
     */
    state: IdeaStateDuration.StateEnum;
    /**
     * 
     * @type {number}
     * @memberof IdeaStateDuration
     */
    duration?: number;
    /**
     * 
     * @type {string}
     * @memberof IdeaStateDuration
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof IdeaStateDuration
     */
    endDate?: string;
    /**
     * 
     * @type {Idea}
     * @memberof IdeaStateDuration
     */
    idea?: Idea;
}

/**
 * @export
 * @namespace IdeaStateDuration
 */
export namespace IdeaStateDuration {
    /**
     * @export
     * @enum {string}
     */
    export enum StateEnum {
        Submission = <any> 'submission',
        Submitted = <any> 'submitted',
        Evaluation = <any> 'evaluation',
        Implementation = <any> 'implementation',
        Implemented = <any> 'implemented',
        Canceled = <any> 'canceled',
        NotNew = <any> 'notNew',
        Unknown = <any> 'unknown'
    }
}

/**
 * 
 * @export
 * @interface OptionItem
 */
export interface OptionItem {
    /**
     * 
     * @type {string}
     * @memberof OptionItem
     */
    text?: string;
    /**
     * 
     * @type {number}
     * @memberof OptionItem
     */
    order?: number;
}

/**
 * 
 * @export
 * @interface PostponeDto
 */
export interface PostponeDto {
    /**
     * 
     * @type {string}
     * @memberof PostponeDto
     */
    postponeUntil?: string;
    /**
     * 
     * @type {string}
     * @memberof PostponeDto
     */
    postponeReason?: PostponeDto.PostponeReasonEnum;
}

/**
 * @export
 * @namespace PostponeDto
 */
export namespace PostponeDto {
    /**
     * @export
     * @enum {string}
     */
    export enum PostponeReasonEnum {
        WaitForDecision = <any> 'waitForDecision',
        MoreInformation = <any> 'moreInformation',
        Absence = <any> 'absence',
        Unknown = <any> 'unknown'
    }
}

/**
 * 
 * @export
 * @interface Rating
 */
export interface Rating {
    /**
     * 
     * @type {number}
     * @memberof Rating
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof Rating
     */
    sum?: number;
}

/**
 * 
 * @export
 * @interface ShareIdeaEmailDto
 */
export interface ShareIdeaEmailDto {
    /**
     * a personal message
     * @type {string}
     * @memberof ShareIdeaEmailDto
     */
    message?: string;
    /**
     * a list of usernames (from active directory)
     * @type {Array<string>}
     * @memberof ShareIdeaEmailDto
     */
    recipients?: Array<string>;
}

/**
 * 
 * @export
 * @interface Translation
 */
export interface Translation {
    /**
     * 
     * @type {string}
     * @memberof Translation
     */
    lang: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof Translation
     */
    translations: { [key: string]: any; };
}

/**
 * used to upload attachments data in a post or put request without using multipart file uploads
 * @export
 * @interface UploadDto
 */
export interface UploadDto {
    /**
     * 
     * @type {string}
     * @memberof UploadDto
     */
    filename?: string;
    /**
     * the mime type of the uploaded file
     * @type {string}
     * @memberof UploadDto
     */
    mimeType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UploadDto
     */
    mainImage?: boolean;
    /**
     * base64 encoded file data
     * @type {string}
     * @memberof UploadDto
     */
    data?: string;
}

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    prename?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    department?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    telephone?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    aboutme?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    admin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    adminHidden?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    timestamp?: Date;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    created?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    deleted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    excludeFromRankings?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    tutorialDismissed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    registrationCompleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    external?: boolean;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserAwardLevel {
    None = <any> 'none',
    Bronze = <any> 'bronze',
    Silver = <any> 'silver',
    Gold = <any> 'gold'
}

/**
 * 
 * @export
 * @interface UserAwardsDto
 */
export interface UserAwardsDto {
    /**
     * 
     * @type {UserAwardLevel}
     * @memberof UserAwardsDto
     */
    comments?: UserAwardLevel;
    /**
     * 
     * @type {UserAwardLevel}
     * @memberof UserAwardsDto
     */
    ideasSubmitted?: UserAwardLevel;
    /**
     * 
     * @type {UserAwardLevel}
     * @memberof UserAwardsDto
     */
    ideasImplemented?: UserAwardLevel;
    /**
     * 
     * @type {UserAwardLevel}
     * @memberof UserAwardsDto
     */
    codeveloper?: UserAwardLevel;
    /**
     * 
     * @type {UserAwardLevel}
     * @memberof UserAwardsDto
     */
    evaluator?: UserAwardLevel;
    /**
     * 
     * @type {UserAwardLevel}
     * @memberof UserAwardsDto
     */
    perfectIdeas?: UserAwardLevel;
}

/**
 * 
 * @export
 * @interface UserNotification
 */
export interface UserNotification {
    /**
     * 
     * @type {number}
     * @memberof UserNotification
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserNotification
     */
    type?: UserNotification.TypeEnum;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof UserNotification
     */
    details?: { [key: string]: any; };
    /**
     * 
     * @type {Date}
     * @memberof UserNotification
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserNotification
     */
    dismissed?: Date;
}

/**
 * @export
 * @namespace UserNotification
 */
export namespace UserNotification {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        IDEALIKED = <any> 'IDEA_LIKED',
        IDEARATED = <any> 'IDEA_RATED',
        COMMENTLIKED = <any> 'COMMENT_LIKED',
        IDEACOMMENTED = <any> 'IDEA_COMMENTED',
        COMMENTREPLIED = <any> 'COMMENT_REPLIED',
        AWARD = <any> 'AWARD',
        REVISEIDEA = <any> 'REVISE_IDEA',
        IDEASUBMITTED = <any> 'IDEA_SUBMITTED',
        IDEAEVALUATIONSTARTED = <any> 'IDEA_EVALUATION_STARTED',
        IDEAEVALUATED = <any> 'IDEA_EVALUATED',
        EVALUATIONPOSTPONED = <any> 'EVALUATION_POSTPONED',
        EVALUATORCHANGED = <any> 'EVALUATOR_CHANGED',
        EVALUATIONASSIGNED = <any> 'EVALUATION_ASSIGNED',
        EVALUATIONREMINDER = <any> 'EVALUATION_REMINDER',
        EVALUATIONASSESSED = <any> 'EVALUATION_ASSESSED',
        IDEAIMPLEMENTATIONSTARTED = <any> 'IDEA_IMPLEMENTATION_STARTED',
        IDEAIMPLEMENTED = <any> 'IDEA_IMPLEMENTED',
        BOOKMARKEDIDEASTATECHANGED = <any> 'BOOKMARKED_IDEA_STATE_CHANGED',
        IDEAMEMBERAPPROVAL = <any> 'IDEA_MEMBER_APPROVAL',
        UNKNOWN = <any> 'UNKNOWN'
    }
}

/**
 * 
 * @export
 * @interface UserRankingDto
 */
export interface UserRankingDto {
    /**
     * 
     * @type {number}
     * @memberof UserRankingDto
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserRankingDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRankingDto
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof UserRankingDto
     */
    value?: number;
    /**
     * 
     * @type {number}
     * @memberof UserRankingDto
     */
    ranking?: number;
}

/**
 * 
 * @export
 * @interface UserRankingsDto
 */
export interface UserRankingsDto {
    /**
     * 
     * @type {Array<UserRankingDto>}
     * @memberof UserRankingsDto
     */
    top?: Array<UserRankingDto>;
    /**
     * 
     * @type {Array<UserRankingDto>}
     * @memberof UserRankingsDto
     */
    near?: Array<UserRankingDto>;
}

/**
 * 
 * @export
 * @interface UserScore
 */
export interface UserScore {
    /**
     * 
     * @type {number}
     * @memberof UserScore
     */
    id?: number;
    /**
     * 
     * @type {User}
     * @memberof UserScore
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof UserScore
     */
    type?: UserScore.TypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof UserScore
     */
    created?: Date;
    /**
     * 
     * @type {number}
     * @memberof UserScore
     */
    points?: number;
}

/**
 * @export
 * @namespace UserScore
 */
export namespace UserScore {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        IDEASEND = <any> 'IDEA_SEND',
        COMMENT = <any> 'COMMENT',
        IDEASUBMIT = <any> 'IDEA_SUBMIT',
        IDEASUBMITWITHDESC = <any> 'IDEA_SUBMIT_WITH_DESC',
        IDEASUBMITWITHIMAGE = <any> 'IDEA_SUBMIT_WITH_IMAGE',
        IDEASUBMITCOMPLETE = <any> 'IDEA_SUBMIT_COMPLETE',
        PROFILEIMAGE = <any> 'PROFILE_IMAGE',
        IDEAMEMBERREGISTRY = <any> 'IDEA_MEMBER_REGISTRY',
        IDEAMEMBERAPPROVAL = <any> 'IDEA_MEMBER_APPROVAL',
        IDEAIMPLEMENTED = <any> 'IDEA_IMPLEMENTED',
        IDEALIKED = <any> 'IDEA_LIKED'
    }
}

/**
 * 
 * @export
 * @interface Widget
 */
export interface Widget {
    /**
     * 
     * @type {number}
     * @memberof Widget
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Widget
     */
    type?: Widget.TypeEnum;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof Widget
     */
    content?: { [key: string]: any; };
    /**
     * 
     * @type {Array<Widget>}
     * @memberof Widget
     */
    children?: Array<Widget>;
}

/**
 * @export
 * @namespace Widget
 */
export namespace Widget {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        PAGE = <any> 'PAGE',
        WIDGETCONTAINER = <any> 'WIDGET_CONTAINER',
        RICHTEXT = <any> 'RICH_TEXT',
        VIDEO = <any> 'VIDEO',
        CAMPAIGN = <any> 'CAMPAIGN',
        NEWS = <any> 'NEWS',
        IDEA = <any> 'IDEA',
        RANKING = <any> 'RANKING',
        IMAGE = <any> 'IMAGE',
        GALLERY = <any> 'GALLERY',
        CITE = <any> 'CITE',
        COUNTDOWN = <any> 'COUNTDOWN',
        CAMPAIGNTEASER = <any> 'CAMPAIGN_TEASER',
        DASHBOARDTODOS = <any> 'DASHBOARD_TODOS',
        EMPTY = <any> 'EMPTY'
    }
}

/**
 * 
 * @export
 * @interface ActivityLogDto
 */
export interface ActivityLogDto {
    /**
     * 
     * @type {number}
     * @memberof ActivityLogDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityLogDto
     */
    type?: ActivityLogDto.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ActivityLogDto
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLogDto
     */
    details?: string;
    /**
     * 
     * @type {Date}
     * @memberof ActivityLogDto
     */
    timestamp?: Date;
    /**
     * 
     * @type {UserDto}
     * @memberof ActivityLogDto
     */
    user?: UserDto;
    /**
     * 
     * @type {Idea}
     * @memberof ActivityLogDto
     */
    idea?: Idea;
    /**
     * 
     * @type {IdeaDto}
     * @memberof ActivityLogDto
     */
    comment?: IdeaDto;
}

/**
 * @export
 * @namespace ActivityLogDto
 */
export namespace ActivityLogDto {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        IDEACREATE = <any> 'IDEA_CREATE',
        IDEADELETE = <any> 'IDEA_DELETE',
        IDEAEDIT = <any> 'IDEA_EDIT',
        IDEACOMMENT = <any> 'IDEA_COMMENT',
        IDEALIKE = <any> 'IDEA_LIKE',
        IDEARATING = <any> 'IDEA_RATING',
        IDEAVIEW = <any> 'IDEA_VIEW',
        IDEABOOKMARK = <any> 'IDEA_BOOKMARK',
        IDEASHARE = <any> 'IDEA_SHARE',
        IDEASUBMIT = <any> 'IDEA_SUBMIT',
        COMMENTREPLY = <any> 'COMMENT_REPLY',
        COMMENTLIKE = <any> 'COMMENT_LIKE',
        COMMENTEDIT = <any> 'COMMENT_EDIT',
        EVALUATIONCREATED = <any> 'EVALUATION_CREATED',
        EVALUATIONEDITED = <any> 'EVALUATION_EDITED',
        EVALUATIONDELETED = <any> 'EVALUATION_DELETED',
        EVALUATIONSUBMITTED = <any> 'EVALUATION_SUBMITTED',
        EVALUATIONASSESSED = <any> 'EVALUATION_ASSESSED',
        USERLOGIN = <any> 'USER_LOGIN',
        USERCREATED = <any> 'USER_CREATED',
        PROFILEIMAGE = <any> 'PROFILE_IMAGE',
        UNKNOWN = <any> 'UNKNOWN'
    }
}

/**
 * 
 * @export
 * @interface AppVarsDto
 */
export interface AppVarsDto {
    /**
     * 
     * @type {boolean}
     * @memberof AppVarsDto
     */
    showLikesInOverview?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AppVarsDto
     */
    newsWidgetsPageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof AppVarsDto
     */
    campaignsPageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof AppVarsDto
     */
    homeListsLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof AppVarsDto
     */
    homeNewsLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof AppVarsDto
     */
    homeCommentsLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof AppVarsDto
     */
    notificationsLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof AppVarsDto
     */
    rankingsLimit?: number;
    /**
     * 
     * @type {number}
     * @memberof AppVarsDto
     */
    evaluationDeadline?: number;
    /**
     * 
     * @type {number}
     * @memberof AppVarsDto
     */
    inactiveUserIntervalMonths?: number;
    /**
     * 
     * @type {number}
     * @memberof AppVarsDto
     */
    evaluationReminderIntervalDays?: number;
    /**
     * 
     * @type {number}
     * @memberof AppVarsDto
     */
    recentIntervalDays?: number;
    /**
     * 
     * @type {string}
     * @memberof AppVarsDto
     */
    emailFrom?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppVarsDto
     */
    emailCc?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppVarsDto
     */
    emailBcc?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof AppVarsDto
     */
    userScoreTypePoints?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof AppVarsDto
     */
    textLengthIndicators?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: Array<number>; }}
     * @memberof AppVarsDto
     */
    userAwardThresholds?: { [key: string]: Array<number>; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof AppVarsDto
     */
    scoreBonus?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: OptionItem; }}
     * @memberof AppVarsDto
     */
    departments?: { [key: string]: OptionItem; };
    /**
     * 
     * @type {{ [key: string]: OptionItem; }}
     * @memberof AppVarsDto
     */
    positions?: { [key: string]: OptionItem; };
}

/**
 * 
 * @export
 * @interface AttachmentDto
 */
export interface AttachmentDto {
    /**
     * 
     * @type {number}
     * @memberof AttachmentDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    filename?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AttachmentDto
     */
    image?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AttachmentDto
     */
    mainImage?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof AttachmentDto
     */
    timestamp?: Date;
}

/**
 * 
 * @export
 * @interface CampaignDto
 */
export interface CampaignDto {
    /**
     * 
     * @type {number}
     * @memberof CampaignDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    start?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    end?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    sliderImage?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof CampaignDto
     */
    createdBy?: UserDto;
    /**
     * 
     * @type {Date}
     * @memberof CampaignDto
     */
    timestamp?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CampaignDto
     */
    created?: Date;
    /**
     * 
     * @type {WidgetDto}
     * @memberof CampaignDto
     */
    widget?: WidgetDto;
    /**
     * 
     * @type {Array<IdeaDto>}
     * @memberof CampaignDto
     */
    ideaList?: Array<IdeaDto>;
}

/**
 * 
 * @export
 * @interface CategoryDto
 */
export interface CategoryDto {
    /**
     * 
     * @type {number}
     * @memberof CategoryDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    defaultImage?: string;
    /**
     * 
     * @type {UploadDto}
     * @memberof CategoryDto
     */
    defaultImageUpload?: UploadDto;
}

/**
 * 
 * @export
 * @interface CommentActivityLogDto
 */
export interface CommentActivityLogDto {
    /**
     * 
     * @type {number}
     * @memberof CommentActivityLogDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CommentActivityLogDto
     */
    type?: CommentActivityLogDto.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CommentActivityLogDto
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentActivityLogDto
     */
    details?: string;
    /**
     * 
     * @type {Date}
     * @memberof CommentActivityLogDto
     */
    timestamp?: Date;
    /**
     * 
     * @type {UserDto}
     * @memberof CommentActivityLogDto
     */
    user?: UserDto;
    /**
     * 
     * @type {CommentDto}
     * @memberof CommentActivityLogDto
     */
    comment?: CommentDto;
}

/**
 * @export
 * @namespace CommentActivityLogDto
 */
export namespace CommentActivityLogDto {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        IDEACREATE = <any> 'IDEA_CREATE',
        IDEADELETE = <any> 'IDEA_DELETE',
        IDEAEDIT = <any> 'IDEA_EDIT',
        IDEACOMMENT = <any> 'IDEA_COMMENT',
        IDEALIKE = <any> 'IDEA_LIKE',
        IDEARATING = <any> 'IDEA_RATING',
        IDEAVIEW = <any> 'IDEA_VIEW',
        IDEABOOKMARK = <any> 'IDEA_BOOKMARK',
        IDEASHARE = <any> 'IDEA_SHARE',
        IDEASUBMIT = <any> 'IDEA_SUBMIT',
        COMMENTREPLY = <any> 'COMMENT_REPLY',
        COMMENTLIKE = <any> 'COMMENT_LIKE',
        COMMENTEDIT = <any> 'COMMENT_EDIT',
        EVALUATIONCREATED = <any> 'EVALUATION_CREATED',
        EVALUATIONEDITED = <any> 'EVALUATION_EDITED',
        EVALUATIONDELETED = <any> 'EVALUATION_DELETED',
        EVALUATIONSUBMITTED = <any> 'EVALUATION_SUBMITTED',
        EVALUATIONASSESSED = <any> 'EVALUATION_ASSESSED',
        USERLOGIN = <any> 'USER_LOGIN',
        USERCREATED = <any> 'USER_CREATED',
        PROFILEIMAGE = <any> 'PROFILE_IMAGE',
        UNKNOWN = <any> 'UNKNOWN'
    }
}

/**
 * 
 * @export
 * @interface CommentDto
 */
export interface CommentDto {
    /**
     * 
     * @type {number}
     * @memberof CommentDto
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof CommentDto
     */
    timestamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof CommentDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentDto
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof CommentDto
     */
    likesCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof CommentDto
     */
    likesCntRecent?: number;
    /**
     * 
     * @type {Array<CommentDto>}
     * @memberof CommentDto
     */
    replyList?: Array<CommentDto>;
    /**
     * 
     * @type {IdeaDto}
     * @memberof CommentDto
     */
    idea?: IdeaDto;
    /**
     * 
     * @type {UserDto}
     * @memberof CommentDto
     */
    user?: UserDto;
    /**
     * 
     * @type {Array<AttachmentDto>}
     * @memberof CommentDto
     */
    attachmentList?: Array<AttachmentDto>;
    /**
     * 
     * @type {Array<UploadDto>}
     * @memberof CommentDto
     */
    uploads?: Array<UploadDto>;
}

/**
 * 
 * @export
 * @interface EvaluationAssessmentCriteriaDto
 */
export interface EvaluationAssessmentCriteriaDto {
    /**
     * 
     * @type {number}
     * @memberof EvaluationAssessmentCriteriaDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof EvaluationAssessmentCriteriaDto
     */
    score?: number;
    /**
     * 
     * @type {Date}
     * @memberof EvaluationAssessmentCriteriaDto
     */
    timestamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof EvaluationAssessmentCriteriaDto
     */
    type?: EvaluationAssessmentCriteriaDto.TypeEnum;
}

/**
 * @export
 * @namespace EvaluationAssessmentCriteriaDto
 */
export namespace EvaluationAssessmentCriteriaDto {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Plausability = <any> 'plausability',
        ResponseTime = <any> 'responseTime',
        Wording = <any> 'wording'
    }
}

/**
 * 
 * @export
 * @interface EvaluationCriteriaDto
 */
export interface EvaluationCriteriaDto {
    /**
     * 
     * @type {number}
     * @memberof EvaluationCriteriaDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof EvaluationCriteriaDto
     */
    score?: number;
    /**
     * 
     * @type {string}
     * @memberof EvaluationCriteriaDto
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof EvaluationCriteriaDto
     */
    timestamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof EvaluationCriteriaDto
     */
    type?: EvaluationCriteriaDto.TypeEnum;
}

/**
 * @export
 * @namespace EvaluationCriteriaDto
 */
export namespace EvaluationCriteriaDto {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Relevance = <any> 'relevance',
        Benefit = <any> 'benefit',
        InnovationPotential = <any> 'innovationPotential',
        ImplementationEffort = <any> 'implementationEffort',
        Usp = <any> 'usp'
    }
}

/**
 * 
 * @export
 * @interface EvaluationDto
 */
export interface EvaluationDto {
    /**
     * 
     * @type {number}
     * @memberof EvaluationDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EvaluationDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationDto
     */
    adminDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationDto
     */
    implementationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationDto
     */
    implementationDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof EvaluationDto
     */
    score?: number;
    /**
     * 
     * @type {number}
     * @memberof EvaluationDto
     */
    bonus?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EvaluationDto
     */
    submitted: boolean;
    /**
     * 
     * @type {Date}
     * @memberof EvaluationDto
     */
    timestamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof EvaluationDto
     */
    implement?: EvaluationDto.ImplementEnum;
    /**
     * 
     * @type {boolean}
     * @memberof EvaluationDto
     */
    revise?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EvaluationDto
     */
    evaluated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EvaluationDto
     */
    postponeUntil?: string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationDto
     */
    postponeReason?: EvaluationDto.PostponeReasonEnum;
    /**
     * 
     * @type {boolean}
     * @memberof EvaluationDto
     */
    postponeAccepted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EvaluationDto
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EvaluationDto
     */
    created?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EvaluationDto
     */
    expertApproved?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof EvaluationDto
     */
    submittedTimestamp?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EvaluationDto
     */
    postponeTimestamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof EvaluationDto
     */
    assessmentComment?: string;
    /**
     * 
     * @type {string}
     * @memberof EvaluationDto
     */
    handoverComment?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EvaluationDto
     */
    assessed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EvaluationDto
     */
    implementationApproved?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EvaluationDto
     */
    archived?: boolean;
    /**
     * 
     * @type {UserDto}
     * @memberof EvaluationDto
     */
    expertUser?: UserDto;
    /**
     * 
     * @type {UserDto}
     * @memberof EvaluationDto
     */
    handoverUser?: UserDto;
    /**
     * 
     * @type {IdeaDto}
     * @memberof EvaluationDto
     */
    idea?: IdeaDto;
    /**
     * 
     * @type {Array<EvaluationCriteriaDto>}
     * @memberof EvaluationDto
     */
    criteriaList?: Array<EvaluationCriteriaDto>;
    /**
     * 
     * @type {Array<EvaluationAssessmentCriteriaDto>}
     * @memberof EvaluationDto
     */
    assessmentCriteriaList?: Array<EvaluationAssessmentCriteriaDto>;
    /**
     * 
     * @type {Array<AttachmentDto>}
     * @memberof EvaluationDto
     */
    attachmentList?: Array<AttachmentDto>;
    /**
     * 
     * @type {Array<AttachmentDto>}
     * @memberof EvaluationDto
     */
    implementationAttachmentList?: Array<AttachmentDto>;
    /**
     * 
     * @type {Array<UploadDto>}
     * @memberof EvaluationDto
     */
    uploads?: Array<UploadDto>;
    /**
     * 
     * @type {Array<UploadDto>}
     * @memberof EvaluationDto
     */
    implementationUploads?: Array<UploadDto>;
}

/**
 * @export
 * @namespace EvaluationDto
 */
export namespace EvaluationDto {
    /**
     * @export
     * @enum {string}
     */
    export enum ImplementEnum {
        Implement = <any> 'implement',
        Rejected = <any> 'rejected',
        NotNew = <any> 'notNew'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum PostponeReasonEnum {
        WaitForDecision = <any> 'waitForDecision',
        MoreInformation = <any> 'moreInformation',
        Absence = <any> 'absence',
        Unknown = <any> 'unknown'
    }
}

/**
 * 
 * @export
 * @interface ExtraProfileDto
 */
export interface ExtraProfileDto {
    /**
     * 
     * @type {string}
     * @memberof ExtraProfileDto
     */
    position?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtraProfileDto
     */
    relation?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtraProfileDto
     */
    employment?: string;
}

/**
 * 
 * @export
 * @interface IdeaDto
 */
export interface IdeaDto {
    /**
     * 
     * @type {number}
     * @memberof IdeaDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof IdeaDto
     */
    state?: IdeaDto.StateEnum;
    /**
     * 
     * @type {string}
     * @memberof IdeaDto
     */
    type?: IdeaDto.TypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof IdeaDto
     */
    anonymous: boolean;
    /**
     * 
     * @type {CampaignDto}
     * @memberof IdeaDto
     */
    campaign?: CampaignDto;
    /**
     * 
     * @type {number}
     * @memberof IdeaDto
     */
    expertEval?: number;
    /**
     * 
     * @type {number}
     * @memberof IdeaDto
     */
    evaluationsCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof IdeaDto
     */
    commentCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof IdeaDto
     */
    likesCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof IdeaDto
     */
    viewsCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof IdeaDto
     */
    ratingsCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof IdeaDto
     */
    ratingsSum?: number;
    /**
     * 
     * @type {number}
     * @memberof IdeaDto
     */
    evaluationsCntRecent?: number;
    /**
     * 
     * @type {number}
     * @memberof IdeaDto
     */
    commentCntRecent?: number;
    /**
     * 
     * @type {number}
     * @memberof IdeaDto
     */
    likesCntRecent?: number;
    /**
     * 
     * @type {number}
     * @memberof IdeaDto
     */
    viewsCntRecent?: number;
    /**
     * 
     * @type {Date}
     * @memberof IdeaDto
     */
    timestamp?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IdeaDto
     */
    created?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof IdeaDto
     */
    deleted: boolean;
    /**
     * 
     * @type {Date}
     * @memberof IdeaDto
     */
    statechanged?: Date;
    /**
     * 
     * @type {string}
     * @memberof IdeaDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof IdeaDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof IdeaDto
     */
    advantage?: string;
    /**
     * 
     * @type {string}
     * @memberof IdeaDto
     */
    audience?: string;
    /**
     * 
     * @type {string}
     * @memberof IdeaDto
     */
    evaluation?: string;
    /**
     * 
     * @type {string}
     * @memberof IdeaDto
     */
    editComment?: string;
    /**
     * 
     * @type {string}
     * @memberof IdeaDto
     */
    feedback?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IdeaDto
     */
    highlighted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IdeaDto
     */
    published?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IdeaDto
     */
    memberRegistration?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IdeaDto
     */
    certificateText?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IdeaDto
     */
    certificateIssued?: boolean;
    /**
     * 
     * @type {Array<AttachmentDto>}
     * @memberof IdeaDto
     */
    attachmentList?: Array<AttachmentDto>;
    /**
     * 
     * @type {UserDto}
     * @memberof IdeaDto
     */
    modifiedUser?: UserDto;
    /**
     * 
     * @type {UserDto}
     * @memberof IdeaDto
     */
    user?: UserDto;
    /**
     * 
     * @type {CategoryDto}
     * @memberof IdeaDto
     */
    category?: CategoryDto;
    /**
     * 
     * @type {Array<UploadDto>}
     * @memberof IdeaDto
     */
    uploads?: Array<UploadDto>;
}

/**
 * @export
 * @namespace IdeaDto
 */
export namespace IdeaDto {
    /**
     * @export
     * @enum {string}
     */
    export enum StateEnum {
        Submission = <any> 'submission',
        Submitted = <any> 'submitted',
        Evaluation = <any> 'evaluation',
        Implementation = <any> 'implementation',
        Implemented = <any> 'implemented',
        Canceled = <any> 'canceled',
        NotNew = <any> 'notNew',
        Unknown = <any> 'unknown'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        Decision = <any> 'decision',
        Unknown = <any> 'unknown'
    }
}

/**
 * 
 * @export
 * @interface IdeaMemberDto
 */
export interface IdeaMemberDto {
    /**
     * 
     * @type {number}
     * @memberof IdeaMemberDto
     */
    id?: number;
    /**
     * 
     * @type {IdeaDto}
     * @memberof IdeaMemberDto
     */
    idea?: IdeaDto;
    /**
     * 
     * @type {UserDto}
     * @memberof IdeaMemberDto
     */
    user?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof IdeaMemberDto
     */
    role?: IdeaMemberDto.RoleEnum;
    /**
     * 
     * @type {boolean}
     * @memberof IdeaMemberDto
     */
    accepted?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof IdeaMemberDto
     */
    timestamp?: Date;
}

/**
 * @export
 * @namespace IdeaMemberDto
 */
export namespace IdeaMemberDto {
    /**
     * @export
     * @enum {string}
     */
    export enum RoleEnum {
        Submitter = <any> 'submitter',
        Codeveloper = <any> 'codeveloper',
        Moderator = <any> 'moderator',
        ProjectManager = <any> 'project_manager'
    }
}

/**
 * 
 * @export
 * @interface IdeaStateDurationDto
 */
export interface IdeaStateDurationDto {
    /**
     * 
     * @type {number}
     * @memberof IdeaStateDurationDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof IdeaStateDurationDto
     */
    state: IdeaStateDurationDto.StateEnum;
    /**
     * 
     * @type {number}
     * @memberof IdeaStateDurationDto
     */
    duration?: number;
    /**
     * 
     * @type {string}
     * @memberof IdeaStateDurationDto
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof IdeaStateDurationDto
     */
    endDate?: string;
    /**
     * 
     * @type {IdeaDto}
     * @memberof IdeaStateDurationDto
     */
    idea?: IdeaDto;
}

/**
 * @export
 * @namespace IdeaStateDurationDto
 */
export namespace IdeaStateDurationDto {
    /**
     * @export
     * @enum {string}
     */
    export enum StateEnum {
        Submission = <any> 'submission',
        Submitted = <any> 'submitted',
        Evaluation = <any> 'evaluation',
        Implementation = <any> 'implementation',
        Implemented = <any> 'implemented',
        Canceled = <any> 'canceled',
        NotNew = <any> 'notNew',
        Unknown = <any> 'unknown'
    }
}

/**
 * 
 * @export
 * @interface TranslationDto
 */
export interface TranslationDto {
    /**
     * 
     * @type {string}
     * @memberof TranslationDto
     */
    lang: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof TranslationDto
     */
    translations: { [key: string]: any; };
}

/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    prename?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    department?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    telephone?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    aboutme?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    admin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    adminHidden?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof UserDto
     */
    timestamp?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserDto
     */
    created?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    deleted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    excludeFromRankings?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    tutorialDismissed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    registrationCompleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    external?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof UserDto
     */
    lastlogin?: Date;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    ideasCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    ideasCntRecent?: number;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    commentsCnt?: number;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    commentsCntRecent?: number;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    points?: number;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    pointsRecent?: number;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    commentsAward?: number;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    ideasSubmittedAward?: number;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    ideasImplementedAward?: number;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    codeveloperAward?: number;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    evaluatorAward?: number;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    perfectIdeasAward?: number;
    /**
     * 
     * @type {UserAwardLevel}
     * @memberof UserDto
     */
    commentsAwardLevel?: UserAwardLevel;
    /**
     * 
     * @type {Date}
     * @memberof UserDto
     */
    commentsAwardTimestamp?: Date;
    /**
     * 
     * @type {UserAwardLevel}
     * @memberof UserDto
     */
    ideasSubmittedAwardLevel?: UserAwardLevel;
    /**
     * 
     * @type {Date}
     * @memberof UserDto
     */
    ideasSubmittedAwardTimestamp?: Date;
    /**
     * 
     * @type {UserAwardLevel}
     * @memberof UserDto
     */
    ideasImplementedAwardLevel?: UserAwardLevel;
    /**
     * 
     * @type {Date}
     * @memberof UserDto
     */
    ideasImplementedAwardTimestamp?: Date;
    /**
     * 
     * @type {UserAwardLevel}
     * @memberof UserDto
     */
    codeveloperAwardLevel?: UserAwardLevel;
    /**
     * 
     * @type {Date}
     * @memberof UserDto
     */
    codeveloperAwardTimestamp?: Date;
    /**
     * 
     * @type {UserAwardLevel}
     * @memberof UserDto
     */
    evaluatorAwardLevel?: UserAwardLevel;
    /**
     * 
     * @type {Date}
     * @memberof UserDto
     */
    evaluatorAwardTimestamp?: Date;
    /**
     * 
     * @type {UserAwardLevel}
     * @memberof UserDto
     */
    perfectIdeasAwardLevel?: UserAwardLevel;
    /**
     * 
     * @type {Date}
     * @memberof UserDto
     */
    perfectIdeasAwardTimestamp?: Date;
    /**
     * 
     * @type {ExtraProfileDto}
     * @memberof UserDto
     */
    extraProfile?: ExtraProfileDto;
}

/**
 * 
 * @export
 * @interface UserNotificationDto
 */
export interface UserNotificationDto {
    /**
     * 
     * @type {number}
     * @memberof UserNotificationDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserNotificationDto
     */
    type?: UserNotificationDto.TypeEnum;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof UserNotificationDto
     */
    details?: { [key: string]: any; };
    /**
     * 
     * @type {Date}
     * @memberof UserNotificationDto
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserNotificationDto
     */
    dismissed?: Date;
}

/**
 * @export
 * @namespace UserNotificationDto
 */
export namespace UserNotificationDto {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        IDEALIKED = <any> 'IDEA_LIKED',
        IDEARATED = <any> 'IDEA_RATED',
        COMMENTLIKED = <any> 'COMMENT_LIKED',
        IDEACOMMENTED = <any> 'IDEA_COMMENTED',
        COMMENTREPLIED = <any> 'COMMENT_REPLIED',
        AWARD = <any> 'AWARD',
        REVISEIDEA = <any> 'REVISE_IDEA',
        IDEASUBMITTED = <any> 'IDEA_SUBMITTED',
        IDEAEVALUATIONSTARTED = <any> 'IDEA_EVALUATION_STARTED',
        IDEAEVALUATED = <any> 'IDEA_EVALUATED',
        EVALUATIONPOSTPONED = <any> 'EVALUATION_POSTPONED',
        EVALUATORCHANGED = <any> 'EVALUATOR_CHANGED',
        EVALUATIONASSIGNED = <any> 'EVALUATION_ASSIGNED',
        EVALUATIONREMINDER = <any> 'EVALUATION_REMINDER',
        EVALUATIONASSESSED = <any> 'EVALUATION_ASSESSED',
        IDEAIMPLEMENTATIONSTARTED = <any> 'IDEA_IMPLEMENTATION_STARTED',
        IDEAIMPLEMENTED = <any> 'IDEA_IMPLEMENTED',
        BOOKMARKEDIDEASTATECHANGED = <any> 'BOOKMARKED_IDEA_STATE_CHANGED',
        IDEAMEMBERAPPROVAL = <any> 'IDEA_MEMBER_APPROVAL',
        UNKNOWN = <any> 'UNKNOWN'
    }
}

/**
 * 
 * @export
 * @interface UserScoreDto
 */
export interface UserScoreDto {
    /**
     * 
     * @type {number}
     * @memberof UserScoreDto
     */
    id?: number;
    /**
     * 
     * @type {UserDto}
     * @memberof UserScoreDto
     */
    user?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof UserScoreDto
     */
    type?: UserScoreDto.TypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof UserScoreDto
     */
    created?: Date;
    /**
     * 
     * @type {number}
     * @memberof UserScoreDto
     */
    points?: number;
}

/**
 * @export
 * @namespace UserScoreDto
 */
export namespace UserScoreDto {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        IDEASEND = <any> 'IDEA_SEND',
        COMMENT = <any> 'COMMENT',
        IDEASUBMIT = <any> 'IDEA_SUBMIT',
        IDEASUBMITWITHDESC = <any> 'IDEA_SUBMIT_WITH_DESC',
        IDEASUBMITWITHIMAGE = <any> 'IDEA_SUBMIT_WITH_IMAGE',
        IDEASUBMITCOMPLETE = <any> 'IDEA_SUBMIT_COMPLETE',
        PROFILEIMAGE = <any> 'PROFILE_IMAGE',
        IDEAMEMBERREGISTRY = <any> 'IDEA_MEMBER_REGISTRY',
        IDEAMEMBERAPPROVAL = <any> 'IDEA_MEMBER_APPROVAL',
        IDEAIMPLEMENTED = <any> 'IDEA_IMPLEMENTED',
        IDEALIKED = <any> 'IDEA_LIKED'
    }
}

/**
 * 
 * @export
 * @interface WidgetDto
 */
export interface WidgetDto {
    /**
     * 
     * @type {number}
     * @memberof WidgetDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WidgetDto
     */
    type?: WidgetDto.TypeEnum;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof WidgetDto
     */
    content?: { [key: string]: any; };
    /**
     * 
     * @type {Array<WidgetDto>}
     * @memberof WidgetDto
     */
    children?: Array<WidgetDto>;
}

/**
 * @export
 * @namespace WidgetDto
 */
export namespace WidgetDto {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        PAGE = <any> 'PAGE',
        WIDGETCONTAINER = <any> 'WIDGET_CONTAINER',
        RICHTEXT = <any> 'RICH_TEXT',
        VIDEO = <any> 'VIDEO',
        CAMPAIGN = <any> 'CAMPAIGN',
        NEWS = <any> 'NEWS',
        IDEA = <any> 'IDEA',
        RANKING = <any> 'RANKING',
        IMAGE = <any> 'IMAGE',
        GALLERY = <any> 'GALLERY',
        CITE = <any> 'CITE',
        COUNTDOWN = <any> 'COUNTDOWN',
        CAMPAIGNTEASER = <any> 'CAMPAIGN_TEASER',
        DASHBOARDTODOS = <any> 'DASHBOARD_TODOS',
        EMPTY = <any> 'EMPTY'
    }
}


/**
 * ActivityLogApi - fetch parameter creator
 * @export
 */
export const ActivityLogApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * counts activity log entries
         * @param {number} [sinceDays] ideas count since days
         * @param {'IDEA_CREATE' | 'IDEA_DELETE' | 'IDEA_EDIT' | 'IDEA_COMMENT' | 'IDEA_LIKE' | 'IDEA_RATING' | 'IDEA_VIEW' | 'IDEA_BOOKMARK' | 'IDEA_SHARE' | 'IDEA_SUBMIT' | 'COMMENT_REPLY' | 'COMMENT_LIKE' | 'COMMENT_EDIT' | 'EVALUATION_CREATED' | 'EVALUATION_EDITED' | 'EVALUATION_DELETED' | 'EVALUATION_SUBMITTED' | 'EVALUATION_ASSESSED' | 'USER_LOGIN' | 'USER_CREATED' | 'PROFILE_IMAGE' | 'UNKNOWN'} [activityLogType] activity log type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLogsCount(sinceDays?: number, activityLogType?: 'IDEA_CREATE' | 'IDEA_DELETE' | 'IDEA_EDIT' | 'IDEA_COMMENT' | 'IDEA_LIKE' | 'IDEA_RATING' | 'IDEA_VIEW' | 'IDEA_BOOKMARK' | 'IDEA_SHARE' | 'IDEA_SUBMIT' | 'COMMENT_REPLY' | 'COMMENT_LIKE' | 'COMMENT_EDIT' | 'EVALUATION_CREATED' | 'EVALUATION_EDITED' | 'EVALUATION_DELETED' | 'EVALUATION_SUBMITTED' | 'EVALUATION_ASSESSED' | 'USER_LOGIN' | 'USER_CREATED' | 'PROFILE_IMAGE' | 'UNKNOWN', options: any = {}): FetchArgs {
            const localVarPath = `/activityLogs/count`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (sinceDays !== undefined) {
                localVarQueryParameter['sinceDays'] = sinceDays;
            }

            if (activityLogType !== undefined) {
                localVarQueryParameter['activityLogType'] = activityLogType;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * queries activity logs
         * @param {'IDEA_CREATE' | 'IDEA_DELETE' | 'IDEA_EDIT' | 'IDEA_COMMENT' | 'IDEA_LIKE' | 'IDEA_RATING' | 'IDEA_VIEW' | 'IDEA_BOOKMARK' | 'IDEA_SHARE' | 'IDEA_SUBMIT' | 'COMMENT_REPLY' | 'COMMENT_LIKE' | 'COMMENT_EDIT' | 'EVALUATION_CREATED' | 'EVALUATION_EDITED' | 'EVALUATION_DELETED' | 'EVALUATION_SUBMITTED' | 'EVALUATION_ASSESSED' | 'USER_LOGIN' | 'USER_CREATED' | 'PROFILE_IMAGE' | 'UNKNOWN'} [activityLogType] activity log type
         * @param {string} [since] filters activity logs by given start date
         * @param {string} [until] filters activity logs by given end date
         * @param {number} [userId] filters activity logs by user
         * @param {number} [ideaId] filters activity logs by idea
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryActivityLogs(activityLogType?: 'IDEA_CREATE' | 'IDEA_DELETE' | 'IDEA_EDIT' | 'IDEA_COMMENT' | 'IDEA_LIKE' | 'IDEA_RATING' | 'IDEA_VIEW' | 'IDEA_BOOKMARK' | 'IDEA_SHARE' | 'IDEA_SUBMIT' | 'COMMENT_REPLY' | 'COMMENT_LIKE' | 'COMMENT_EDIT' | 'EVALUATION_CREATED' | 'EVALUATION_EDITED' | 'EVALUATION_DELETED' | 'EVALUATION_SUBMITTED' | 'EVALUATION_ASSESSED' | 'USER_LOGIN' | 'USER_CREATED' | 'PROFILE_IMAGE' | 'UNKNOWN', since?: string, until?: string, userId?: number, ideaId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/activityLogs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (activityLogType !== undefined) {
                localVarQueryParameter['activityLogType'] = activityLogType;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString().substr(0,10) :
                    since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString().substr(0,10) :
                    until;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (ideaId !== undefined) {
                localVarQueryParameter['ideaId'] = ideaId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * queries comment activity logs
         * @param {'IDEA_CREATE' | 'IDEA_DELETE' | 'IDEA_EDIT' | 'IDEA_COMMENT' | 'IDEA_LIKE' | 'IDEA_RATING' | 'IDEA_VIEW' | 'IDEA_BOOKMARK' | 'IDEA_SHARE' | 'IDEA_SUBMIT' | 'COMMENT_REPLY' | 'COMMENT_LIKE' | 'COMMENT_EDIT' | 'EVALUATION_CREATED' | 'EVALUATION_EDITED' | 'EVALUATION_DELETED' | 'EVALUATION_SUBMITTED' | 'EVALUATION_ASSESSED' | 'USER_LOGIN' | 'USER_CREATED' | 'PROFILE_IMAGE' | 'UNKNOWN'} [activityLogType] activity log type
         * @param {string} [since] filters comment activity logs by given start date
         * @param {string} [until] filters comment activity logs by given end date
         * @param {number} [userId] filters comment activity logs by user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryCommentActivityLogs(activityLogType?: 'IDEA_CREATE' | 'IDEA_DELETE' | 'IDEA_EDIT' | 'IDEA_COMMENT' | 'IDEA_LIKE' | 'IDEA_RATING' | 'IDEA_VIEW' | 'IDEA_BOOKMARK' | 'IDEA_SHARE' | 'IDEA_SUBMIT' | 'COMMENT_REPLY' | 'COMMENT_LIKE' | 'COMMENT_EDIT' | 'EVALUATION_CREATED' | 'EVALUATION_EDITED' | 'EVALUATION_DELETED' | 'EVALUATION_SUBMITTED' | 'EVALUATION_ASSESSED' | 'USER_LOGIN' | 'USER_CREATED' | 'PROFILE_IMAGE' | 'UNKNOWN', since?: string, until?: string, userId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/commentActivityLogs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (activityLogType !== undefined) {
                localVarQueryParameter['activityLogType'] = activityLogType;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString().substr(0,10) :
                    since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString().substr(0,10) :
                    until;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityLogApi - functional programming interface
 * @export
 */
export const ActivityLogApiFp = function(configuration?: Configuration) {
    return {
        /**
         * counts activity log entries
         * @param {number} [sinceDays] ideas count since days
         * @param {'IDEA_CREATE' | 'IDEA_DELETE' | 'IDEA_EDIT' | 'IDEA_COMMENT' | 'IDEA_LIKE' | 'IDEA_RATING' | 'IDEA_VIEW' | 'IDEA_BOOKMARK' | 'IDEA_SHARE' | 'IDEA_SUBMIT' | 'COMMENT_REPLY' | 'COMMENT_LIKE' | 'COMMENT_EDIT' | 'EVALUATION_CREATED' | 'EVALUATION_EDITED' | 'EVALUATION_DELETED' | 'EVALUATION_SUBMITTED' | 'EVALUATION_ASSESSED' | 'USER_LOGIN' | 'USER_CREATED' | 'PROFILE_IMAGE' | 'UNKNOWN'} [activityLogType] activity log type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLogsCount(sinceDays?: number, activityLogType?: 'IDEA_CREATE' | 'IDEA_DELETE' | 'IDEA_EDIT' | 'IDEA_COMMENT' | 'IDEA_LIKE' | 'IDEA_RATING' | 'IDEA_VIEW' | 'IDEA_BOOKMARK' | 'IDEA_SHARE' | 'IDEA_SUBMIT' | 'COMMENT_REPLY' | 'COMMENT_LIKE' | 'COMMENT_EDIT' | 'EVALUATION_CREATED' | 'EVALUATION_EDITED' | 'EVALUATION_DELETED' | 'EVALUATION_SUBMITTED' | 'EVALUATION_ASSESSED' | 'USER_LOGIN' | 'USER_CREATED' | 'PROFILE_IMAGE' | 'UNKNOWN', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = ActivityLogApiFetchParamCreator(configuration).getActivityLogsCount(sinceDays, activityLogType, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * queries activity logs
         * @param {'IDEA_CREATE' | 'IDEA_DELETE' | 'IDEA_EDIT' | 'IDEA_COMMENT' | 'IDEA_LIKE' | 'IDEA_RATING' | 'IDEA_VIEW' | 'IDEA_BOOKMARK' | 'IDEA_SHARE' | 'IDEA_SUBMIT' | 'COMMENT_REPLY' | 'COMMENT_LIKE' | 'COMMENT_EDIT' | 'EVALUATION_CREATED' | 'EVALUATION_EDITED' | 'EVALUATION_DELETED' | 'EVALUATION_SUBMITTED' | 'EVALUATION_ASSESSED' | 'USER_LOGIN' | 'USER_CREATED' | 'PROFILE_IMAGE' | 'UNKNOWN'} [activityLogType] activity log type
         * @param {string} [since] filters activity logs by given start date
         * @param {string} [until] filters activity logs by given end date
         * @param {number} [userId] filters activity logs by user
         * @param {number} [ideaId] filters activity logs by idea
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryActivityLogs(activityLogType?: 'IDEA_CREATE' | 'IDEA_DELETE' | 'IDEA_EDIT' | 'IDEA_COMMENT' | 'IDEA_LIKE' | 'IDEA_RATING' | 'IDEA_VIEW' | 'IDEA_BOOKMARK' | 'IDEA_SHARE' | 'IDEA_SUBMIT' | 'COMMENT_REPLY' | 'COMMENT_LIKE' | 'COMMENT_EDIT' | 'EVALUATION_CREATED' | 'EVALUATION_EDITED' | 'EVALUATION_DELETED' | 'EVALUATION_SUBMITTED' | 'EVALUATION_ASSESSED' | 'USER_LOGIN' | 'USER_CREATED' | 'PROFILE_IMAGE' | 'UNKNOWN', since?: string, until?: string, userId?: number, ideaId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ActivityLogDto>> {
            const localVarFetchArgs = ActivityLogApiFetchParamCreator(configuration).queryActivityLogs(activityLogType, since, until, userId, ideaId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * queries comment activity logs
         * @param {'IDEA_CREATE' | 'IDEA_DELETE' | 'IDEA_EDIT' | 'IDEA_COMMENT' | 'IDEA_LIKE' | 'IDEA_RATING' | 'IDEA_VIEW' | 'IDEA_BOOKMARK' | 'IDEA_SHARE' | 'IDEA_SUBMIT' | 'COMMENT_REPLY' | 'COMMENT_LIKE' | 'COMMENT_EDIT' | 'EVALUATION_CREATED' | 'EVALUATION_EDITED' | 'EVALUATION_DELETED' | 'EVALUATION_SUBMITTED' | 'EVALUATION_ASSESSED' | 'USER_LOGIN' | 'USER_CREATED' | 'PROFILE_IMAGE' | 'UNKNOWN'} [activityLogType] activity log type
         * @param {string} [since] filters comment activity logs by given start date
         * @param {string} [until] filters comment activity logs by given end date
         * @param {number} [userId] filters comment activity logs by user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryCommentActivityLogs(activityLogType?: 'IDEA_CREATE' | 'IDEA_DELETE' | 'IDEA_EDIT' | 'IDEA_COMMENT' | 'IDEA_LIKE' | 'IDEA_RATING' | 'IDEA_VIEW' | 'IDEA_BOOKMARK' | 'IDEA_SHARE' | 'IDEA_SUBMIT' | 'COMMENT_REPLY' | 'COMMENT_LIKE' | 'COMMENT_EDIT' | 'EVALUATION_CREATED' | 'EVALUATION_EDITED' | 'EVALUATION_DELETED' | 'EVALUATION_SUBMITTED' | 'EVALUATION_ASSESSED' | 'USER_LOGIN' | 'USER_CREATED' | 'PROFILE_IMAGE' | 'UNKNOWN', since?: string, until?: string, userId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CommentActivityLogDto>> {
            const localVarFetchArgs = ActivityLogApiFetchParamCreator(configuration).queryCommentActivityLogs(activityLogType, since, until, userId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ActivityLogApi - factory interface
 * @export
 */
export const ActivityLogApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * counts activity log entries
         * @param {number} [sinceDays] ideas count since days
         * @param {'IDEA_CREATE' | 'IDEA_DELETE' | 'IDEA_EDIT' | 'IDEA_COMMENT' | 'IDEA_LIKE' | 'IDEA_RATING' | 'IDEA_VIEW' | 'IDEA_BOOKMARK' | 'IDEA_SHARE' | 'IDEA_SUBMIT' | 'COMMENT_REPLY' | 'COMMENT_LIKE' | 'COMMENT_EDIT' | 'EVALUATION_CREATED' | 'EVALUATION_EDITED' | 'EVALUATION_DELETED' | 'EVALUATION_SUBMITTED' | 'EVALUATION_ASSESSED' | 'USER_LOGIN' | 'USER_CREATED' | 'PROFILE_IMAGE' | 'UNKNOWN'} [activityLogType] activity log type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityLogsCount(sinceDays?: number, activityLogType?: 'IDEA_CREATE' | 'IDEA_DELETE' | 'IDEA_EDIT' | 'IDEA_COMMENT' | 'IDEA_LIKE' | 'IDEA_RATING' | 'IDEA_VIEW' | 'IDEA_BOOKMARK' | 'IDEA_SHARE' | 'IDEA_SUBMIT' | 'COMMENT_REPLY' | 'COMMENT_LIKE' | 'COMMENT_EDIT' | 'EVALUATION_CREATED' | 'EVALUATION_EDITED' | 'EVALUATION_DELETED' | 'EVALUATION_SUBMITTED' | 'EVALUATION_ASSESSED' | 'USER_LOGIN' | 'USER_CREATED' | 'PROFILE_IMAGE' | 'UNKNOWN', options?: any) {
            return ActivityLogApiFp(configuration).getActivityLogsCount(sinceDays, activityLogType, options)(fetch, basePath);
        },
        /**
         * queries activity logs
         * @param {'IDEA_CREATE' | 'IDEA_DELETE' | 'IDEA_EDIT' | 'IDEA_COMMENT' | 'IDEA_LIKE' | 'IDEA_RATING' | 'IDEA_VIEW' | 'IDEA_BOOKMARK' | 'IDEA_SHARE' | 'IDEA_SUBMIT' | 'COMMENT_REPLY' | 'COMMENT_LIKE' | 'COMMENT_EDIT' | 'EVALUATION_CREATED' | 'EVALUATION_EDITED' | 'EVALUATION_DELETED' | 'EVALUATION_SUBMITTED' | 'EVALUATION_ASSESSED' | 'USER_LOGIN' | 'USER_CREATED' | 'PROFILE_IMAGE' | 'UNKNOWN'} [activityLogType] activity log type
         * @param {string} [since] filters activity logs by given start date
         * @param {string} [until] filters activity logs by given end date
         * @param {number} [userId] filters activity logs by user
         * @param {number} [ideaId] filters activity logs by idea
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryActivityLogs(activityLogType?: 'IDEA_CREATE' | 'IDEA_DELETE' | 'IDEA_EDIT' | 'IDEA_COMMENT' | 'IDEA_LIKE' | 'IDEA_RATING' | 'IDEA_VIEW' | 'IDEA_BOOKMARK' | 'IDEA_SHARE' | 'IDEA_SUBMIT' | 'COMMENT_REPLY' | 'COMMENT_LIKE' | 'COMMENT_EDIT' | 'EVALUATION_CREATED' | 'EVALUATION_EDITED' | 'EVALUATION_DELETED' | 'EVALUATION_SUBMITTED' | 'EVALUATION_ASSESSED' | 'USER_LOGIN' | 'USER_CREATED' | 'PROFILE_IMAGE' | 'UNKNOWN', since?: string, until?: string, userId?: number, ideaId?: number, options?: any) {
            return ActivityLogApiFp(configuration).queryActivityLogs(activityLogType, since, until, userId, ideaId, options)(fetch, basePath);
        },
        /**
         * queries comment activity logs
         * @param {'IDEA_CREATE' | 'IDEA_DELETE' | 'IDEA_EDIT' | 'IDEA_COMMENT' | 'IDEA_LIKE' | 'IDEA_RATING' | 'IDEA_VIEW' | 'IDEA_BOOKMARK' | 'IDEA_SHARE' | 'IDEA_SUBMIT' | 'COMMENT_REPLY' | 'COMMENT_LIKE' | 'COMMENT_EDIT' | 'EVALUATION_CREATED' | 'EVALUATION_EDITED' | 'EVALUATION_DELETED' | 'EVALUATION_SUBMITTED' | 'EVALUATION_ASSESSED' | 'USER_LOGIN' | 'USER_CREATED' | 'PROFILE_IMAGE' | 'UNKNOWN'} [activityLogType] activity log type
         * @param {string} [since] filters comment activity logs by given start date
         * @param {string} [until] filters comment activity logs by given end date
         * @param {number} [userId] filters comment activity logs by user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryCommentActivityLogs(activityLogType?: 'IDEA_CREATE' | 'IDEA_DELETE' | 'IDEA_EDIT' | 'IDEA_COMMENT' | 'IDEA_LIKE' | 'IDEA_RATING' | 'IDEA_VIEW' | 'IDEA_BOOKMARK' | 'IDEA_SHARE' | 'IDEA_SUBMIT' | 'COMMENT_REPLY' | 'COMMENT_LIKE' | 'COMMENT_EDIT' | 'EVALUATION_CREATED' | 'EVALUATION_EDITED' | 'EVALUATION_DELETED' | 'EVALUATION_SUBMITTED' | 'EVALUATION_ASSESSED' | 'USER_LOGIN' | 'USER_CREATED' | 'PROFILE_IMAGE' | 'UNKNOWN', since?: string, until?: string, userId?: number, options?: any) {
            return ActivityLogApiFp(configuration).queryCommentActivityLogs(activityLogType, since, until, userId, options)(fetch, basePath);
        },
    };
};

/**
 * ActivityLogApi - object-oriented interface
 * @export
 * @class ActivityLogApi
 * @extends {BaseAPI}
 */
export class ActivityLogApi extends BaseAPI {
    /**
     * counts activity log entries
     * @param {number} [sinceDays] ideas count since days
     * @param {'IDEA_CREATE' | 'IDEA_DELETE' | 'IDEA_EDIT' | 'IDEA_COMMENT' | 'IDEA_LIKE' | 'IDEA_RATING' | 'IDEA_VIEW' | 'IDEA_BOOKMARK' | 'IDEA_SHARE' | 'IDEA_SUBMIT' | 'COMMENT_REPLY' | 'COMMENT_LIKE' | 'COMMENT_EDIT' | 'EVALUATION_CREATED' | 'EVALUATION_EDITED' | 'EVALUATION_DELETED' | 'EVALUATION_SUBMITTED' | 'EVALUATION_ASSESSED' | 'USER_LOGIN' | 'USER_CREATED' | 'PROFILE_IMAGE' | 'UNKNOWN'} [activityLogType] activity log type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityLogApi
     */
    public getActivityLogsCount(sinceDays?: number, activityLogType?: 'IDEA_CREATE' | 'IDEA_DELETE' | 'IDEA_EDIT' | 'IDEA_COMMENT' | 'IDEA_LIKE' | 'IDEA_RATING' | 'IDEA_VIEW' | 'IDEA_BOOKMARK' | 'IDEA_SHARE' | 'IDEA_SUBMIT' | 'COMMENT_REPLY' | 'COMMENT_LIKE' | 'COMMENT_EDIT' | 'EVALUATION_CREATED' | 'EVALUATION_EDITED' | 'EVALUATION_DELETED' | 'EVALUATION_SUBMITTED' | 'EVALUATION_ASSESSED' | 'USER_LOGIN' | 'USER_CREATED' | 'PROFILE_IMAGE' | 'UNKNOWN', options?: any) {
        return ActivityLogApiFp(this.configuration).getActivityLogsCount(sinceDays, activityLogType, options)(this.fetch, this.basePath);
    }

    /**
     * queries activity logs
     * @param {'IDEA_CREATE' | 'IDEA_DELETE' | 'IDEA_EDIT' | 'IDEA_COMMENT' | 'IDEA_LIKE' | 'IDEA_RATING' | 'IDEA_VIEW' | 'IDEA_BOOKMARK' | 'IDEA_SHARE' | 'IDEA_SUBMIT' | 'COMMENT_REPLY' | 'COMMENT_LIKE' | 'COMMENT_EDIT' | 'EVALUATION_CREATED' | 'EVALUATION_EDITED' | 'EVALUATION_DELETED' | 'EVALUATION_SUBMITTED' | 'EVALUATION_ASSESSED' | 'USER_LOGIN' | 'USER_CREATED' | 'PROFILE_IMAGE' | 'UNKNOWN'} [activityLogType] activity log type
     * @param {string} [since] filters activity logs by given start date
     * @param {string} [until] filters activity logs by given end date
     * @param {number} [userId] filters activity logs by user
     * @param {number} [ideaId] filters activity logs by idea
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityLogApi
     */
    public queryActivityLogs(activityLogType?: 'IDEA_CREATE' | 'IDEA_DELETE' | 'IDEA_EDIT' | 'IDEA_COMMENT' | 'IDEA_LIKE' | 'IDEA_RATING' | 'IDEA_VIEW' | 'IDEA_BOOKMARK' | 'IDEA_SHARE' | 'IDEA_SUBMIT' | 'COMMENT_REPLY' | 'COMMENT_LIKE' | 'COMMENT_EDIT' | 'EVALUATION_CREATED' | 'EVALUATION_EDITED' | 'EVALUATION_DELETED' | 'EVALUATION_SUBMITTED' | 'EVALUATION_ASSESSED' | 'USER_LOGIN' | 'USER_CREATED' | 'PROFILE_IMAGE' | 'UNKNOWN', since?: string, until?: string, userId?: number, ideaId?: number, options?: any) {
        return ActivityLogApiFp(this.configuration).queryActivityLogs(activityLogType, since, until, userId, ideaId, options)(this.fetch, this.basePath);
    }

    /**
     * queries comment activity logs
     * @param {'IDEA_CREATE' | 'IDEA_DELETE' | 'IDEA_EDIT' | 'IDEA_COMMENT' | 'IDEA_LIKE' | 'IDEA_RATING' | 'IDEA_VIEW' | 'IDEA_BOOKMARK' | 'IDEA_SHARE' | 'IDEA_SUBMIT' | 'COMMENT_REPLY' | 'COMMENT_LIKE' | 'COMMENT_EDIT' | 'EVALUATION_CREATED' | 'EVALUATION_EDITED' | 'EVALUATION_DELETED' | 'EVALUATION_SUBMITTED' | 'EVALUATION_ASSESSED' | 'USER_LOGIN' | 'USER_CREATED' | 'PROFILE_IMAGE' | 'UNKNOWN'} [activityLogType] activity log type
     * @param {string} [since] filters comment activity logs by given start date
     * @param {string} [until] filters comment activity logs by given end date
     * @param {number} [userId] filters comment activity logs by user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityLogApi
     */
    public queryCommentActivityLogs(activityLogType?: 'IDEA_CREATE' | 'IDEA_DELETE' | 'IDEA_EDIT' | 'IDEA_COMMENT' | 'IDEA_LIKE' | 'IDEA_RATING' | 'IDEA_VIEW' | 'IDEA_BOOKMARK' | 'IDEA_SHARE' | 'IDEA_SUBMIT' | 'COMMENT_REPLY' | 'COMMENT_LIKE' | 'COMMENT_EDIT' | 'EVALUATION_CREATED' | 'EVALUATION_EDITED' | 'EVALUATION_DELETED' | 'EVALUATION_SUBMITTED' | 'EVALUATION_ASSESSED' | 'USER_LOGIN' | 'USER_CREATED' | 'PROFILE_IMAGE' | 'UNKNOWN', since?: string, until?: string, userId?: number, options?: any) {
        return ActivityLogApiFp(this.configuration).queryCommentActivityLogs(activityLogType, since, until, userId, options)(this.fetch, this.basePath);
    }

}

/**
 * AppApi - fetch parameter creator
 * @export
 */
export const AppApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets application data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppState(options: any = {}): FetchArgs {
            const localVarPath = `/app/state`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets application variables customizable by admin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppVars(options: any = {}): FetchArgs {
            const localVarPath = `/app/vars`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * increments active idea state durations by 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incrementIdeaStateDurations(options: any = {}): FetchArgs {
            const localVarPath = `/incrementIdeaStateDurations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * creates or updates application variables customizable by admin
         * @param {AppVarsDto} appVars app vars to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAppVars(appVars: AppVarsDto, options: any = {}): FetchArgs {
            // verify required parameter 'appVars' is not null or undefined
            if (appVars === null || appVars === undefined) {
                throw new RequiredError('appVars','Required parameter appVars was null or undefined when calling putAppVars.');
            }
            const localVarPath = `/app/vars`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AppVarsDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(appVars || {}) : (appVars || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * sends evaluation reminder emails
         * @param {string} [at] a date param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEvaluationReminders(at?: string, options: any = {}): FetchArgs {
            const localVarPath = `/sendEvaluationReminders`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (at !== undefined) {
                localVarQueryParameter['at'] = (at as any instanceof Date) ?
                    (at as any).toISOString().substr(0,10) :
                    at;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * sends implementation reminder emails
         * @param {string} [at] a date param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendImplementationReminders(at?: string, options: any = {}): FetchArgs {
            const localVarPath = `/sendImplementationReminders`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (at !== undefined) {
                localVarQueryParameter['at'] = (at as any instanceof Date) ?
                    (at as any).toISOString().substr(0,10) :
                    at;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * recreates idea statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIdeaStatistics(options: any = {}): FetchArgs {
            const localVarPath = `/updateIdeaStatistics`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * recreates user awards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserAwards(options: any = {}): FetchArgs {
            const localVarPath = `/updateUserAwards`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * recreates user rankings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserRankings(options: any = {}): FetchArgs {
            const localVarPath = `/updateUserRankings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppApi - functional programming interface
 * @export
 */
export const AppApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Gets application data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppState(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AppState> {
            const localVarFetchArgs = AppApiFetchParamCreator(configuration).getAppState(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * gets application variables customizable by admin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppVars(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AppVarsDto> {
            const localVarFetchArgs = AppApiFetchParamCreator(configuration).getAppVars(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * increments active idea state durations by 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incrementIdeaStateDurations(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AppApiFetchParamCreator(configuration).incrementIdeaStateDurations(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * creates or updates application variables customizable by admin
         * @param {AppVarsDto} appVars app vars to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAppVars(appVars: AppVarsDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AppVarsDto> {
            const localVarFetchArgs = AppApiFetchParamCreator(configuration).putAppVars(appVars, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * sends evaluation reminder emails
         * @param {string} [at] a date param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEvaluationReminders(at?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AppApiFetchParamCreator(configuration).sendEvaluationReminders(at, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * sends implementation reminder emails
         * @param {string} [at] a date param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendImplementationReminders(at?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AppApiFetchParamCreator(configuration).sendImplementationReminders(at, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * recreates idea statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIdeaStatistics(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AppApiFetchParamCreator(configuration).updateIdeaStatistics(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * recreates user awards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserAwards(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AppApiFetchParamCreator(configuration).updateUserAwards(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * recreates user rankings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserRankings(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AppApiFetchParamCreator(configuration).updateUserRankings(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AppApi - factory interface
 * @export
 */
export const AppApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Gets application data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppState(options?: any) {
            return AppApiFp(configuration).getAppState(options)(fetch, basePath);
        },
        /**
         * gets application variables customizable by admin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppVars(options?: any) {
            return AppApiFp(configuration).getAppVars(options)(fetch, basePath);
        },
        /**
         * increments active idea state durations by 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incrementIdeaStateDurations(options?: any) {
            return AppApiFp(configuration).incrementIdeaStateDurations(options)(fetch, basePath);
        },
        /**
         * creates or updates application variables customizable by admin
         * @param {AppVarsDto} appVars app vars to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAppVars(appVars: AppVarsDto, options?: any) {
            return AppApiFp(configuration).putAppVars(appVars, options)(fetch, basePath);
        },
        /**
         * sends evaluation reminder emails
         * @param {string} [at] a date param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEvaluationReminders(at?: string, options?: any) {
            return AppApiFp(configuration).sendEvaluationReminders(at, options)(fetch, basePath);
        },
        /**
         * sends implementation reminder emails
         * @param {string} [at] a date param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendImplementationReminders(at?: string, options?: any) {
            return AppApiFp(configuration).sendImplementationReminders(at, options)(fetch, basePath);
        },
        /**
         * recreates idea statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIdeaStatistics(options?: any) {
            return AppApiFp(configuration).updateIdeaStatistics(options)(fetch, basePath);
        },
        /**
         * recreates user awards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserAwards(options?: any) {
            return AppApiFp(configuration).updateUserAwards(options)(fetch, basePath);
        },
        /**
         * recreates user rankings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserRankings(options?: any) {
            return AppApiFp(configuration).updateUserRankings(options)(fetch, basePath);
        },
    };
};

/**
 * AppApi - object-oriented interface
 * @export
 * @class AppApi
 * @extends {BaseAPI}
 */
export class AppApi extends BaseAPI {
    /**
     * Gets application data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getAppState(options?: any) {
        return AppApiFp(this.configuration).getAppState(options)(this.fetch, this.basePath);
    }

    /**
     * gets application variables customizable by admin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public getAppVars(options?: any) {
        return AppApiFp(this.configuration).getAppVars(options)(this.fetch, this.basePath);
    }

    /**
     * increments active idea state durations by 1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public incrementIdeaStateDurations(options?: any) {
        return AppApiFp(this.configuration).incrementIdeaStateDurations(options)(this.fetch, this.basePath);
    }

    /**
     * creates or updates application variables customizable by admin
     * @param {AppVarsDto} appVars app vars to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public putAppVars(appVars: AppVarsDto, options?: any) {
        return AppApiFp(this.configuration).putAppVars(appVars, options)(this.fetch, this.basePath);
    }

    /**
     * sends evaluation reminder emails
     * @param {string} [at] a date param
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public sendEvaluationReminders(at?: string, options?: any) {
        return AppApiFp(this.configuration).sendEvaluationReminders(at, options)(this.fetch, this.basePath);
    }

    /**
     * sends implementation reminder emails
     * @param {string} [at] a date param
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public sendImplementationReminders(at?: string, options?: any) {
        return AppApiFp(this.configuration).sendImplementationReminders(at, options)(this.fetch, this.basePath);
    }

    /**
     * recreates idea statistics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public updateIdeaStatistics(options?: any) {
        return AppApiFp(this.configuration).updateIdeaStatistics(options)(this.fetch, this.basePath);
    }

    /**
     * recreates user awards
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public updateUserAwards(options?: any) {
        return AppApiFp(this.configuration).updateUserAwards(options)(this.fetch, this.basePath);
    }

    /**
     * recreates user rankings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public updateUserRankings(options?: any) {
        return AppApiFp(this.configuration).updateUserRankings(options)(this.fetch, this.basePath);
    }

}

/**
 * CampaignApi - fetch parameter creator
 * @export
 */
export const CampaignApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * deletes a campaign
         * @param {number} campaignId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCampaign(campaignId: number, options: any = {}): FetchArgs {
            // verify required parameter 'campaignId' is not null or undefined
            if (campaignId === null || campaignId === undefined) {
                throw new RequiredError('campaignId','Required parameter campaignId was null or undefined when calling deleteCampaign.');
            }
            const localVarPath = `/campaign/{campaignId}`
                .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets a specific campaign
         * @param {number} campaignId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaign(campaignId: number, options: any = {}): FetchArgs {
            // verify required parameter 'campaignId' is not null or undefined
            if (campaignId === null || campaignId === undefined) {
                throw new RequiredError('campaignId','Required parameter campaignId was null or undefined when calling getCampaign.');
            }
            const localVarPath = `/campaign/{campaignId}`
                .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets all campaigns
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaigns(limit?: number, options: any = {}): FetchArgs {
            const localVarPath = `/campaigns`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * creates a new campaign
         * @param {CampaignDto} body campaign to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCampaign(body: CampaignDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postCampaign.');
            }
            const localVarPath = `/campaigns`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CampaignDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * updates a campaign.
         * @param {number} campaignId an id
         * @param {CampaignDto} body campaign to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCampaign(campaignId: number, body: CampaignDto, options: any = {}): FetchArgs {
            // verify required parameter 'campaignId' is not null or undefined
            if (campaignId === null || campaignId === undefined) {
                throw new RequiredError('campaignId','Required parameter campaignId was null or undefined when calling putCampaign.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling putCampaign.');
            }
            const localVarPath = `/campaign/{campaignId}`
                .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CampaignDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignApi - functional programming interface
 * @export
 */
export const CampaignApiFp = function(configuration?: Configuration) {
    return {
        /**
         * deletes a campaign
         * @param {number} campaignId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCampaign(campaignId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CampaignApiFetchParamCreator(configuration).deleteCampaign(campaignId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * gets a specific campaign
         * @param {number} campaignId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaign(campaignId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CampaignDto> {
            const localVarFetchArgs = CampaignApiFetchParamCreator(configuration).getCampaign(campaignId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * gets all campaigns
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaigns(limit?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CampaignDto>> {
            const localVarFetchArgs = CampaignApiFetchParamCreator(configuration).getCampaigns(limit, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * creates a new campaign
         * @param {CampaignDto} body campaign to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCampaign(body: CampaignDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CampaignDto> {
            const localVarFetchArgs = CampaignApiFetchParamCreator(configuration).postCampaign(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * updates a campaign.
         * @param {number} campaignId an id
         * @param {CampaignDto} body campaign to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCampaign(campaignId: number, body: CampaignDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CampaignDto> {
            const localVarFetchArgs = CampaignApiFetchParamCreator(configuration).putCampaign(campaignId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CampaignApi - factory interface
 * @export
 */
export const CampaignApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * deletes a campaign
         * @param {number} campaignId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCampaign(campaignId: number, options?: any) {
            return CampaignApiFp(configuration).deleteCampaign(campaignId, options)(fetch, basePath);
        },
        /**
         * gets a specific campaign
         * @param {number} campaignId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaign(campaignId: number, options?: any) {
            return CampaignApiFp(configuration).getCampaign(campaignId, options)(fetch, basePath);
        },
        /**
         * gets all campaigns
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaigns(limit?: number, options?: any) {
            return CampaignApiFp(configuration).getCampaigns(limit, options)(fetch, basePath);
        },
        /**
         * creates a new campaign
         * @param {CampaignDto} body campaign to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCampaign(body: CampaignDto, options?: any) {
            return CampaignApiFp(configuration).postCampaign(body, options)(fetch, basePath);
        },
        /**
         * updates a campaign.
         * @param {number} campaignId an id
         * @param {CampaignDto} body campaign to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCampaign(campaignId: number, body: CampaignDto, options?: any) {
            return CampaignApiFp(configuration).putCampaign(campaignId, body, options)(fetch, basePath);
        },
    };
};

/**
 * CampaignApi - object-oriented interface
 * @export
 * @class CampaignApi
 * @extends {BaseAPI}
 */
export class CampaignApi extends BaseAPI {
    /**
     * deletes a campaign
     * @param {number} campaignId an id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public deleteCampaign(campaignId: number, options?: any) {
        return CampaignApiFp(this.configuration).deleteCampaign(campaignId, options)(this.fetch, this.basePath);
    }

    /**
     * gets a specific campaign
     * @param {number} campaignId an id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public getCampaign(campaignId: number, options?: any) {
        return CampaignApiFp(this.configuration).getCampaign(campaignId, options)(this.fetch, this.basePath);
    }

    /**
     * gets all campaigns
     * @param {number} [limit] limits the returned results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public getCampaigns(limit?: number, options?: any) {
        return CampaignApiFp(this.configuration).getCampaigns(limit, options)(this.fetch, this.basePath);
    }

    /**
     * creates a new campaign
     * @param {CampaignDto} body campaign to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public postCampaign(body: CampaignDto, options?: any) {
        return CampaignApiFp(this.configuration).postCampaign(body, options)(this.fetch, this.basePath);
    }

    /**
     * updates a campaign.
     * @param {number} campaignId an id
     * @param {CampaignDto} body campaign to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public putCampaign(campaignId: number, body: CampaignDto, options?: any) {
        return CampaignApiFp(this.configuration).putCampaign(campaignId, body, options)(this.fetch, this.basePath);
    }

}

/**
 * CategoryApi - fetch parameter creator
 * @export
 */
export const CategoryApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * deletes a category
         * @param {number} categoryId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory(categoryId: number, options: any = {}): FetchArgs {
            // verify required parameter 'categoryId' is not null or undefined
            if (categoryId === null || categoryId === undefined) {
                throw new RequiredError('categoryId','Required parameter categoryId was null or undefined when calling deleteCategory.');
            }
            const localVarPath = `/categories/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get all categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories(options: any = {}): FetchArgs {
            const localVarPath = `/categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * creates a new category
         * @param {CategoryDto} body category to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCategory(body: CategoryDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postCategory.');
            }
            const localVarPath = `/categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CategoryDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * updates/creates a category
         * @param {CategoryDto} body category to store
         * @param {number} categoryId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCategory(body: CategoryDto, categoryId: number, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling putCategory.');
            }
            // verify required parameter 'categoryId' is not null or undefined
            if (categoryId === null || categoryId === undefined) {
                throw new RequiredError('categoryId','Required parameter categoryId was null or undefined when calling putCategory.');
            }
            const localVarPath = `/categories/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CategoryDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoryApi - functional programming interface
 * @export
 */
export const CategoryApiFp = function(configuration?: Configuration) {
    return {
        /**
         * deletes a category
         * @param {number} categoryId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory(categoryId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CategoryApiFetchParamCreator(configuration).deleteCategory(categoryId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * get all categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CategoryDto>> {
            const localVarFetchArgs = CategoryApiFetchParamCreator(configuration).getCategories(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * creates a new category
         * @param {CategoryDto} body category to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCategory(body: CategoryDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CategoryDto> {
            const localVarFetchArgs = CategoryApiFetchParamCreator(configuration).postCategory(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * updates/creates a category
         * @param {CategoryDto} body category to store
         * @param {number} categoryId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCategory(body: CategoryDto, categoryId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CategoryDto> {
            const localVarFetchArgs = CategoryApiFetchParamCreator(configuration).putCategory(body, categoryId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CategoryApi - factory interface
 * @export
 */
export const CategoryApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * deletes a category
         * @param {number} categoryId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategory(categoryId: number, options?: any) {
            return CategoryApiFp(configuration).deleteCategory(categoryId, options)(fetch, basePath);
        },
        /**
         * get all categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories(options?: any) {
            return CategoryApiFp(configuration).getCategories(options)(fetch, basePath);
        },
        /**
         * creates a new category
         * @param {CategoryDto} body category to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCategory(body: CategoryDto, options?: any) {
            return CategoryApiFp(configuration).postCategory(body, options)(fetch, basePath);
        },
        /**
         * updates/creates a category
         * @param {CategoryDto} body category to store
         * @param {number} categoryId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCategory(body: CategoryDto, categoryId: number, options?: any) {
            return CategoryApiFp(configuration).putCategory(body, categoryId, options)(fetch, basePath);
        },
    };
};

/**
 * CategoryApi - object-oriented interface
 * @export
 * @class CategoryApi
 * @extends {BaseAPI}
 */
export class CategoryApi extends BaseAPI {
    /**
     * deletes a category
     * @param {number} categoryId an id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public deleteCategory(categoryId: number, options?: any) {
        return CategoryApiFp(this.configuration).deleteCategory(categoryId, options)(this.fetch, this.basePath);
    }

    /**
     * get all categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public getCategories(options?: any) {
        return CategoryApiFp(this.configuration).getCategories(options)(this.fetch, this.basePath);
    }

    /**
     * creates a new category
     * @param {CategoryDto} body category to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public postCategory(body: CategoryDto, options?: any) {
        return CategoryApiFp(this.configuration).postCategory(body, options)(this.fetch, this.basePath);
    }

    /**
     * updates/creates a category
     * @param {CategoryDto} body category to store
     * @param {number} categoryId an id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public putCategory(body: CategoryDto, categoryId: number, options?: any) {
        return CategoryApiFp(this.configuration).putCategory(body, categoryId, options)(this.fetch, this.basePath);
    }

}

/**
 * CommentApi - fetch parameter creator
 * @export
 */
export const CommentApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * gets a specific comment
         * @param {number} commentId an comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComment(commentId: number, options: any = {}): FetchArgs {
            // verify required parameter 'commentId' is not null or undefined
            if (commentId === null || commentId === undefined) {
                throw new RequiredError('commentId','Required parameter commentId was null or undefined when calling getComment.');
            }
            const localVarPath = `/comments/{commentId}`
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets comments
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComments(limit?: number, options: any = {}): FetchArgs {
            const localVarPath = `/comments`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets comments count
         * @param {number} [sinceDays] ideas count since days
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsCount(sinceDays?: number, options: any = {}): FetchArgs {
            const localVarPath = `/comments/count`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sinceDays !== undefined) {
                localVarQueryParameter['sinceDays'] = sinceDays;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * creates a new comment reply
         * @param {number} commentId an comment id
         * @param {CommentDto} commentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCommentReply(commentId: number, commentDto: CommentDto, options: any = {}): FetchArgs {
            // verify required parameter 'commentId' is not null or undefined
            if (commentId === null || commentId === undefined) {
                throw new RequiredError('commentId','Required parameter commentId was null or undefined when calling postCommentReply.');
            }
            // verify required parameter 'commentDto' is not null or undefined
            if (commentDto === null || commentDto === undefined) {
                throw new RequiredError('commentDto','Required parameter commentDto was null or undefined when calling postCommentReply.');
            }
            const localVarPath = `/comments/{commentId}`
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CommentDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(commentDto || {}) : (commentDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * edits an existing comment
         * @param {number} commentId an comment id
         * @param {CommentDto} commentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putComment(commentId: number, commentDto: CommentDto, options: any = {}): FetchArgs {
            // verify required parameter 'commentId' is not null or undefined
            if (commentId === null || commentId === undefined) {
                throw new RequiredError('commentId','Required parameter commentId was null or undefined when calling putComment.');
            }
            // verify required parameter 'commentDto' is not null or undefined
            if (commentDto === null || commentDto === undefined) {
                throw new RequiredError('commentDto','Required parameter commentDto was null or undefined when calling putComment.');
            }
            const localVarPath = `/comments/{commentId}`
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CommentDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(commentDto || {}) : (commentDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * adds a like to a comment
         * @param {number} commentId an comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCommentLikes(commentId: number, options: any = {}): FetchArgs {
            // verify required parameter 'commentId' is not null or undefined
            if (commentId === null || commentId === undefined) {
                throw new RequiredError('commentId','Required parameter commentId was null or undefined when calling putCommentLikes.');
            }
            const localVarPath = `/comments/{commentId}/likes`
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommentApi - functional programming interface
 * @export
 */
export const CommentApiFp = function(configuration?: Configuration) {
    return {
        /**
         * gets a specific comment
         * @param {number} commentId an comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComment(commentId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommentDto> {
            const localVarFetchArgs = CommentApiFetchParamCreator(configuration).getComment(commentId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * gets comments
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComments(limit?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CommentDto>> {
            const localVarFetchArgs = CommentApiFetchParamCreator(configuration).getComments(limit, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * gets comments count
         * @param {number} [sinceDays] ideas count since days
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsCount(sinceDays?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = CommentApiFetchParamCreator(configuration).getCommentsCount(sinceDays, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * creates a new comment reply
         * @param {number} commentId an comment id
         * @param {CommentDto} commentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCommentReply(commentId: number, commentDto: CommentDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommentDto> {
            const localVarFetchArgs = CommentApiFetchParamCreator(configuration).postCommentReply(commentId, commentDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * edits an existing comment
         * @param {number} commentId an comment id
         * @param {CommentDto} commentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putComment(commentId: number, commentDto: CommentDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommentDto> {
            const localVarFetchArgs = CommentApiFetchParamCreator(configuration).putComment(commentId, commentDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * adds a like to a comment
         * @param {number} commentId an comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCommentLikes(commentId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = CommentApiFetchParamCreator(configuration).putCommentLikes(commentId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CommentApi - factory interface
 * @export
 */
export const CommentApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * gets a specific comment
         * @param {number} commentId an comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComment(commentId: number, options?: any) {
            return CommentApiFp(configuration).getComment(commentId, options)(fetch, basePath);
        },
        /**
         * gets comments
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComments(limit?: number, options?: any) {
            return CommentApiFp(configuration).getComments(limit, options)(fetch, basePath);
        },
        /**
         * gets comments count
         * @param {number} [sinceDays] ideas count since days
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsCount(sinceDays?: number, options?: any) {
            return CommentApiFp(configuration).getCommentsCount(sinceDays, options)(fetch, basePath);
        },
        /**
         * creates a new comment reply
         * @param {number} commentId an comment id
         * @param {CommentDto} commentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCommentReply(commentId: number, commentDto: CommentDto, options?: any) {
            return CommentApiFp(configuration).postCommentReply(commentId, commentDto, options)(fetch, basePath);
        },
        /**
         * edits an existing comment
         * @param {number} commentId an comment id
         * @param {CommentDto} commentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putComment(commentId: number, commentDto: CommentDto, options?: any) {
            return CommentApiFp(configuration).putComment(commentId, commentDto, options)(fetch, basePath);
        },
        /**
         * adds a like to a comment
         * @param {number} commentId an comment id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCommentLikes(commentId: number, options?: any) {
            return CommentApiFp(configuration).putCommentLikes(commentId, options)(fetch, basePath);
        },
    };
};

/**
 * CommentApi - object-oriented interface
 * @export
 * @class CommentApi
 * @extends {BaseAPI}
 */
export class CommentApi extends BaseAPI {
    /**
     * gets a specific comment
     * @param {number} commentId an comment id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public getComment(commentId: number, options?: any) {
        return CommentApiFp(this.configuration).getComment(commentId, options)(this.fetch, this.basePath);
    }

    /**
     * gets comments
     * @param {number} [limit] limits the returned results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public getComments(limit?: number, options?: any) {
        return CommentApiFp(this.configuration).getComments(limit, options)(this.fetch, this.basePath);
    }

    /**
     * gets comments count
     * @param {number} [sinceDays] ideas count since days
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public getCommentsCount(sinceDays?: number, options?: any) {
        return CommentApiFp(this.configuration).getCommentsCount(sinceDays, options)(this.fetch, this.basePath);
    }

    /**
     * creates a new comment reply
     * @param {number} commentId an comment id
     * @param {CommentDto} commentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public postCommentReply(commentId: number, commentDto: CommentDto, options?: any) {
        return CommentApiFp(this.configuration).postCommentReply(commentId, commentDto, options)(this.fetch, this.basePath);
    }

    /**
     * edits an existing comment
     * @param {number} commentId an comment id
     * @param {CommentDto} commentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public putComment(commentId: number, commentDto: CommentDto, options?: any) {
        return CommentApiFp(this.configuration).putComment(commentId, commentDto, options)(this.fetch, this.basePath);
    }

    /**
     * adds a like to a comment
     * @param {number} commentId an comment id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public putCommentLikes(commentId: number, options?: any) {
        return CommentApiFp(this.configuration).putCommentLikes(commentId, options)(this.fetch, this.basePath);
    }

}

/**
 * ContentApi - fetch parameter creator
 * @export
 */
export const ContentApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * deletes a widget
         * @param {number} widgetId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWidget(widgetId: number, options: any = {}): FetchArgs {
            // verify required parameter 'widgetId' is not null or undefined
            if (widgetId === null || widgetId === undefined) {
                throw new RequiredError('widgetId','Required parameter widgetId was null or undefined when calling deleteWidget.');
            }
            const localVarPath = `/widgets/{widgetId}`
                .replace(`{${"widgetId"}}`, encodeURIComponent(String(widgetId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets a widget
         * @param {number} widgetId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWidget(widgetId: number, options: any = {}): FetchArgs {
            // verify required parameter 'widgetId' is not null or undefined
            if (widgetId === null || widgetId === undefined) {
                throw new RequiredError('widgetId','Required parameter widgetId was null or undefined when calling getWidget.');
            }
            const localVarPath = `/widgets/{widgetId}`
                .replace(`{${"widgetId"}}`, encodeURIComponent(String(widgetId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get widgets without a parent.
         * @param {'PAGE' | 'NEWS'} [widgetType] the type of the widget
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWidgets(widgetType?: 'PAGE' | 'NEWS', limit?: number, options: any = {}): FetchArgs {
            const localVarPath = `/widgets`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (widgetType !== undefined) {
                localVarQueryParameter['widgetType'] = widgetType;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * performs a form upload to minio/s3
         * @param {any} file The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFormUpload(file: any, options: any = {}): FetchArgs {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling postFormUpload.');
            }
            const localVarPath = `/formUploads`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (file !== undefined) {
                localVarFormParams.set('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * performs an upload to minio/s3
         * @param {Array<UploadDto>} body upload with meta data
         * @param {string} [basePath] the upload base path
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploads(body: Array<UploadDto>, basePath?: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postUploads.');
            }
            const localVarPath = `/uploads`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (basePath !== undefined) {
                localVarQueryParameter['basePath'] = basePath;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;UploadDto&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * creates a root widget.
         * @param {WidgetDto} body widget to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWidget(body: WidgetDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postWidget.');
            }
            const localVarPath = `/widgets`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"WidgetDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * updates a root widget.
         * @param {number} widgetId an id
         * @param {WidgetDto} body widget to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWidget(widgetId: number, body: WidgetDto, options: any = {}): FetchArgs {
            // verify required parameter 'widgetId' is not null or undefined
            if (widgetId === null || widgetId === undefined) {
                throw new RequiredError('widgetId','Required parameter widgetId was null or undefined when calling putWidget.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling putWidget.');
            }
            const localVarPath = `/widgets/{widgetId}`
                .replace(`{${"widgetId"}}`, encodeURIComponent(String(widgetId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"WidgetDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentApi - functional programming interface
 * @export
 */
export const ContentApiFp = function(configuration?: Configuration) {
    return {
        /**
         * deletes a widget
         * @param {number} widgetId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWidget(widgetId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ContentApiFetchParamCreator(configuration).deleteWidget(widgetId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * gets a widget
         * @param {number} widgetId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWidget(widgetId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WidgetDto> {
            const localVarFetchArgs = ContentApiFetchParamCreator(configuration).getWidget(widgetId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * get widgets without a parent.
         * @param {'PAGE' | 'NEWS'} [widgetType] the type of the widget
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWidgets(widgetType?: 'PAGE' | 'NEWS', limit?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<WidgetDto>> {
            const localVarFetchArgs = ContentApiFetchParamCreator(configuration).getWidgets(widgetType, limit, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * performs a form upload to minio/s3
         * @param {any} file The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFormUpload(file: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = ContentApiFetchParamCreator(configuration).postFormUpload(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * performs an upload to minio/s3
         * @param {Array<UploadDto>} body upload with meta data
         * @param {string} [basePath] the upload base path
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploads(body: Array<UploadDto>, basePath?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
            const localVarFetchArgs = ContentApiFetchParamCreator(configuration).postUploads(body, basePath, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * creates a root widget.
         * @param {WidgetDto} body widget to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWidget(body: WidgetDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WidgetDto> {
            const localVarFetchArgs = ContentApiFetchParamCreator(configuration).postWidget(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * updates a root widget.
         * @param {number} widgetId an id
         * @param {WidgetDto} body widget to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWidget(widgetId: number, body: WidgetDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WidgetDto> {
            const localVarFetchArgs = ContentApiFetchParamCreator(configuration).putWidget(widgetId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ContentApi - factory interface
 * @export
 */
export const ContentApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * deletes a widget
         * @param {number} widgetId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWidget(widgetId: number, options?: any) {
            return ContentApiFp(configuration).deleteWidget(widgetId, options)(fetch, basePath);
        },
        /**
         * gets a widget
         * @param {number} widgetId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWidget(widgetId: number, options?: any) {
            return ContentApiFp(configuration).getWidget(widgetId, options)(fetch, basePath);
        },
        /**
         * get widgets without a parent.
         * @param {'PAGE' | 'NEWS'} [widgetType] the type of the widget
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWidgets(widgetType?: 'PAGE' | 'NEWS', limit?: number, options?: any) {
            return ContentApiFp(configuration).getWidgets(widgetType, limit, options)(fetch, basePath);
        },
        /**
         * performs a form upload to minio/s3
         * @param {any} file The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFormUpload(file: any, options?: any) {
            return ContentApiFp(configuration).postFormUpload(file, options)(fetch, basePath);
        },
        /**
         * performs an upload to minio/s3
         * @param {Array<UploadDto>} body upload with meta data
         * @param {string} [basePath] the upload base path
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploads(body: Array<UploadDto>, basePath?: string, options?: any) {
            return ContentApiFp(configuration).postUploads(body, basePath, options)(fetch, basePath);
        },
        /**
         * creates a root widget.
         * @param {WidgetDto} body widget to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWidget(body: WidgetDto, options?: any) {
            return ContentApiFp(configuration).postWidget(body, options)(fetch, basePath);
        },
        /**
         * updates a root widget.
         * @param {number} widgetId an id
         * @param {WidgetDto} body widget to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWidget(widgetId: number, body: WidgetDto, options?: any) {
            return ContentApiFp(configuration).putWidget(widgetId, body, options)(fetch, basePath);
        },
    };
};

/**
 * ContentApi - object-oriented interface
 * @export
 * @class ContentApi
 * @extends {BaseAPI}
 */
export class ContentApi extends BaseAPI {
    /**
     * deletes a widget
     * @param {number} widgetId an id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public deleteWidget(widgetId: number, options?: any) {
        return ContentApiFp(this.configuration).deleteWidget(widgetId, options)(this.fetch, this.basePath);
    }

    /**
     * gets a widget
     * @param {number} widgetId an id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getWidget(widgetId: number, options?: any) {
        return ContentApiFp(this.configuration).getWidget(widgetId, options)(this.fetch, this.basePath);
    }

    /**
     * get widgets without a parent.
     * @param {'PAGE' | 'NEWS'} [widgetType] the type of the widget
     * @param {number} [limit] limits the returned results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getWidgets(widgetType?: 'PAGE' | 'NEWS', limit?: number, options?: any) {
        return ContentApiFp(this.configuration).getWidgets(widgetType, limit, options)(this.fetch, this.basePath);
    }

    /**
     * performs a form upload to minio/s3
     * @param {any} file The file to upload.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public postFormUpload(file: any, options?: any) {
        return ContentApiFp(this.configuration).postFormUpload(file, options)(this.fetch, this.basePath);
    }

    /**
     * performs an upload to minio/s3
     * @param {Array<UploadDto>} body upload with meta data
     * @param {string} [basePath] the upload base path
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public postUploads(body: Array<UploadDto>, basePath?: string, options?: any) {
        return ContentApiFp(this.configuration).postUploads(body, basePath, options)(this.fetch, this.basePath);
    }

    /**
     * creates a root widget.
     * @param {WidgetDto} body widget to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public postWidget(body: WidgetDto, options?: any) {
        return ContentApiFp(this.configuration).postWidget(body, options)(this.fetch, this.basePath);
    }

    /**
     * updates a root widget.
     * @param {number} widgetId an id
     * @param {WidgetDto} body widget to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public putWidget(widgetId: number, body: WidgetDto, options?: any) {
        return ContentApiFp(this.configuration).putWidget(widgetId, body, options)(this.fetch, this.basePath);
    }

}

/**
 * EvaluationApi - fetch parameter creator
 * @export
 */
export const EvaluationApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * deletes an evaluation
         * @param {number} evaluationId an evaluation id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvaluation(evaluationId: number, options: any = {}): FetchArgs {
            // verify required parameter 'evaluationId' is not null or undefined
            if (evaluationId === null || evaluationId === undefined) {
                throw new RequiredError('evaluationId','Required parameter evaluationId was null or undefined when calling deleteEvaluation.');
            }
            const localVarPath = `/evaluations/{evaluationId}`
                .replace(`{${"evaluationId"}}`, encodeURIComponent(String(evaluationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets a specific evaluation
         * @param {number} evaluationId an evaluation id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluation(evaluationId: number, options: any = {}): FetchArgs {
            // verify required parameter 'evaluationId' is not null or undefined
            if (evaluationId === null || evaluationId === undefined) {
                throw new RequiredError('evaluationId','Required parameter evaluationId was null or undefined when calling getEvaluation.');
            }
            const localVarPath = `/evaluations/{evaluationId}`
                .replace(`{${"evaluationId"}}`, encodeURIComponent(String(evaluationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * the list of valid evaluation assessment criteria types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluationAssessmentCriteriaTypes(options: any = {}): FetchArgs {
            const localVarPath = `/evaluationAssessmentCriteriaTypes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * the list of valid evaluation criteria types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluationCriteriaTypes(options: any = {}): FetchArgs {
            const localVarPath = `/evaluationCriteriaTypes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets all evaluations
         * @param {boolean} [excludeCompleted] excludes completed ideas (not relevant in admin dashboard)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluations(excludeCompleted?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/evaluations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (excludeCompleted !== undefined) {
                localVarQueryParameter['excludeCompleted'] = excludeCompleted;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * counts evaluations
         * @param {number} [sinceDays] ideas count since days
         * @param {'submitted' | 'evaluated'} [evaluationState] evaluation state: submitted/evaluated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluationsCount(sinceDays?: number, evaluationState?: 'submitted' | 'evaluated', options: any = {}): FetchArgs {
            const localVarPath = `/evaluations/count`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (sinceDays !== undefined) {
                localVarQueryParameter['sinceDays'] = sinceDays;
            }

            if (evaluationState !== undefined) {
                localVarQueryParameter['evaluationState'] = evaluationState;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * postpones an evalution
         * @param {number} evaluationId an evaluation id
         * @param {PostponeDto} postponeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPostpone(evaluationId: number, postponeDto: PostponeDto, options: any = {}): FetchArgs {
            // verify required parameter 'evaluationId' is not null or undefined
            if (evaluationId === null || evaluationId === undefined) {
                throw new RequiredError('evaluationId','Required parameter evaluationId was null or undefined when calling postPostpone.');
            }
            // verify required parameter 'postponeDto' is not null or undefined
            if (postponeDto === null || postponeDto === undefined) {
                throw new RequiredError('postponeDto','Required parameter postponeDto was null or undefined when calling postPostpone.');
            }
            const localVarPath = `/evaluations/{evaluationId}/postpones`
                .replace(`{${"evaluationId"}}`, encodeURIComponent(String(evaluationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PostponeDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(postponeDto || {}) : (postponeDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * edits an existing evaluation
         * @param {number} evaluationId an evaluation id
         * @param {EvaluationDto} evaluationDto 
         * @param {boolean} [sendMail] prevents sending of mails, if false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEvaluation(evaluationId: number, evaluationDto: EvaluationDto, sendMail?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'evaluationId' is not null or undefined
            if (evaluationId === null || evaluationId === undefined) {
                throw new RequiredError('evaluationId','Required parameter evaluationId was null or undefined when calling putEvaluation.');
            }
            // verify required parameter 'evaluationDto' is not null or undefined
            if (evaluationDto === null || evaluationDto === undefined) {
                throw new RequiredError('evaluationDto','Required parameter evaluationDto was null or undefined when calling putEvaluation.');
            }
            const localVarPath = `/evaluations/{evaluationId}`
                .replace(`{${"evaluationId"}}`, encodeURIComponent(String(evaluationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sendMail !== undefined) {
                localVarQueryParameter['sendMail'] = sendMail;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EvaluationDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(evaluationDto || {}) : (evaluationDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EvaluationApi - functional programming interface
 * @export
 */
export const EvaluationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * deletes an evaluation
         * @param {number} evaluationId an evaluation id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvaluation(evaluationId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = EvaluationApiFetchParamCreator(configuration).deleteEvaluation(evaluationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * gets a specific evaluation
         * @param {number} evaluationId an evaluation id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluation(evaluationId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EvaluationDto> {
            const localVarFetchArgs = EvaluationApiFetchParamCreator(configuration).getEvaluation(evaluationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * the list of valid evaluation assessment criteria types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluationAssessmentCriteriaTypes(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
            const localVarFetchArgs = EvaluationApiFetchParamCreator(configuration).getEvaluationAssessmentCriteriaTypes(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * the list of valid evaluation criteria types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluationCriteriaTypes(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
            const localVarFetchArgs = EvaluationApiFetchParamCreator(configuration).getEvaluationCriteriaTypes(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * gets all evaluations
         * @param {boolean} [excludeCompleted] excludes completed ideas (not relevant in admin dashboard)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluations(excludeCompleted?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<EvaluationDto>> {
            const localVarFetchArgs = EvaluationApiFetchParamCreator(configuration).getEvaluations(excludeCompleted, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * counts evaluations
         * @param {number} [sinceDays] ideas count since days
         * @param {'submitted' | 'evaluated'} [evaluationState] evaluation state: submitted/evaluated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluationsCount(sinceDays?: number, evaluationState?: 'submitted' | 'evaluated', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = EvaluationApiFetchParamCreator(configuration).getEvaluationsCount(sinceDays, evaluationState, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * postpones an evalution
         * @param {number} evaluationId an evaluation id
         * @param {PostponeDto} postponeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPostpone(evaluationId: number, postponeDto: PostponeDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EvaluationDto> {
            const localVarFetchArgs = EvaluationApiFetchParamCreator(configuration).postPostpone(evaluationId, postponeDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * edits an existing evaluation
         * @param {number} evaluationId an evaluation id
         * @param {EvaluationDto} evaluationDto 
         * @param {boolean} [sendMail] prevents sending of mails, if false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEvaluation(evaluationId: number, evaluationDto: EvaluationDto, sendMail?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EvaluationDto> {
            const localVarFetchArgs = EvaluationApiFetchParamCreator(configuration).putEvaluation(evaluationId, evaluationDto, sendMail, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * EvaluationApi - factory interface
 * @export
 */
export const EvaluationApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * deletes an evaluation
         * @param {number} evaluationId an evaluation id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvaluation(evaluationId: number, options?: any) {
            return EvaluationApiFp(configuration).deleteEvaluation(evaluationId, options)(fetch, basePath);
        },
        /**
         * gets a specific evaluation
         * @param {number} evaluationId an evaluation id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluation(evaluationId: number, options?: any) {
            return EvaluationApiFp(configuration).getEvaluation(evaluationId, options)(fetch, basePath);
        },
        /**
         * the list of valid evaluation assessment criteria types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluationAssessmentCriteriaTypes(options?: any) {
            return EvaluationApiFp(configuration).getEvaluationAssessmentCriteriaTypes(options)(fetch, basePath);
        },
        /**
         * the list of valid evaluation criteria types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluationCriteriaTypes(options?: any) {
            return EvaluationApiFp(configuration).getEvaluationCriteriaTypes(options)(fetch, basePath);
        },
        /**
         * gets all evaluations
         * @param {boolean} [excludeCompleted] excludes completed ideas (not relevant in admin dashboard)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluations(excludeCompleted?: boolean, options?: any) {
            return EvaluationApiFp(configuration).getEvaluations(excludeCompleted, options)(fetch, basePath);
        },
        /**
         * counts evaluations
         * @param {number} [sinceDays] ideas count since days
         * @param {'submitted' | 'evaluated'} [evaluationState] evaluation state: submitted/evaluated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluationsCount(sinceDays?: number, evaluationState?: 'submitted' | 'evaluated', options?: any) {
            return EvaluationApiFp(configuration).getEvaluationsCount(sinceDays, evaluationState, options)(fetch, basePath);
        },
        /**
         * postpones an evalution
         * @param {number} evaluationId an evaluation id
         * @param {PostponeDto} postponeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPostpone(evaluationId: number, postponeDto: PostponeDto, options?: any) {
            return EvaluationApiFp(configuration).postPostpone(evaluationId, postponeDto, options)(fetch, basePath);
        },
        /**
         * edits an existing evaluation
         * @param {number} evaluationId an evaluation id
         * @param {EvaluationDto} evaluationDto 
         * @param {boolean} [sendMail] prevents sending of mails, if false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEvaluation(evaluationId: number, evaluationDto: EvaluationDto, sendMail?: boolean, options?: any) {
            return EvaluationApiFp(configuration).putEvaluation(evaluationId, evaluationDto, sendMail, options)(fetch, basePath);
        },
    };
};

/**
 * EvaluationApi - object-oriented interface
 * @export
 * @class EvaluationApi
 * @extends {BaseAPI}
 */
export class EvaluationApi extends BaseAPI {
    /**
     * deletes an evaluation
     * @param {number} evaluationId an evaluation id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvaluationApi
     */
    public deleteEvaluation(evaluationId: number, options?: any) {
        return EvaluationApiFp(this.configuration).deleteEvaluation(evaluationId, options)(this.fetch, this.basePath);
    }

    /**
     * gets a specific evaluation
     * @param {number} evaluationId an evaluation id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvaluationApi
     */
    public getEvaluation(evaluationId: number, options?: any) {
        return EvaluationApiFp(this.configuration).getEvaluation(evaluationId, options)(this.fetch, this.basePath);
    }

    /**
     * the list of valid evaluation assessment criteria types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvaluationApi
     */
    public getEvaluationAssessmentCriteriaTypes(options?: any) {
        return EvaluationApiFp(this.configuration).getEvaluationAssessmentCriteriaTypes(options)(this.fetch, this.basePath);
    }

    /**
     * the list of valid evaluation criteria types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvaluationApi
     */
    public getEvaluationCriteriaTypes(options?: any) {
        return EvaluationApiFp(this.configuration).getEvaluationCriteriaTypes(options)(this.fetch, this.basePath);
    }

    /**
     * gets all evaluations
     * @param {boolean} [excludeCompleted] excludes completed ideas (not relevant in admin dashboard)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvaluationApi
     */
    public getEvaluations(excludeCompleted?: boolean, options?: any) {
        return EvaluationApiFp(this.configuration).getEvaluations(excludeCompleted, options)(this.fetch, this.basePath);
    }

    /**
     * counts evaluations
     * @param {number} [sinceDays] ideas count since days
     * @param {'submitted' | 'evaluated'} [evaluationState] evaluation state: submitted/evaluated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvaluationApi
     */
    public getEvaluationsCount(sinceDays?: number, evaluationState?: 'submitted' | 'evaluated', options?: any) {
        return EvaluationApiFp(this.configuration).getEvaluationsCount(sinceDays, evaluationState, options)(this.fetch, this.basePath);
    }

    /**
     * postpones an evalution
     * @param {number} evaluationId an evaluation id
     * @param {PostponeDto} postponeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvaluationApi
     */
    public postPostpone(evaluationId: number, postponeDto: PostponeDto, options?: any) {
        return EvaluationApiFp(this.configuration).postPostpone(evaluationId, postponeDto, options)(this.fetch, this.basePath);
    }

    /**
     * edits an existing evaluation
     * @param {number} evaluationId an evaluation id
     * @param {EvaluationDto} evaluationDto 
     * @param {boolean} [sendMail] prevents sending of mails, if false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvaluationApi
     */
    public putEvaluation(evaluationId: number, evaluationDto: EvaluationDto, sendMail?: boolean, options?: any) {
        return EvaluationApiFp(this.configuration).putEvaluation(evaluationId, evaluationDto, sendMail, options)(this.fetch, this.basePath);
    }

}

/**
 * IdeaApi - fetch parameter creator
 * @export
 */
export const IdeaApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * creates a new idea member
         * @param {number} ideaId an idea id
         * @param {IdeaMemberDto} ideaMemberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIdeaMember(ideaId: number, ideaMemberDto: IdeaMemberDto, options: any = {}): FetchArgs {
            // verify required parameter 'ideaId' is not null or undefined
            if (ideaId === null || ideaId === undefined) {
                throw new RequiredError('ideaId','Required parameter ideaId was null or undefined when calling addIdeaMember.');
            }
            // verify required parameter 'ideaMemberDto' is not null or undefined
            if (ideaMemberDto === null || ideaMemberDto === undefined) {
                throw new RequiredError('ideaMemberDto','Required parameter ideaMemberDto was null or undefined when calling addIdeaMember.');
            }
            const localVarPath = `/ideas/{ideaId}/members`
                .replace(`{${"ideaId"}}`, encodeURIComponent(String(ideaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"IdeaMemberDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(ideaMemberDto || {}) : (ideaMemberDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * sets the deleted flag on an idea
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIdea(ideaId: number, options: any = {}): FetchArgs {
            // verify required parameter 'ideaId' is not null or undefined
            if (ideaId === null || ideaId === undefined) {
                throw new RequiredError('ideaId','Required parameter ideaId was null or undefined when calling deleteIdea.');
            }
            const localVarPath = `/ideas/{ideaId}`
                .replace(`{${"ideaId"}}`, encodeURIComponent(String(ideaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * creates a implementation certificate pdf
         * @param {number} ideaId an idea id
         * @param {number} userId a user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertificate(ideaId: number, userId: number, options: any = {}): FetchArgs {
            // verify required parameter 'ideaId' is not null or undefined
            if (ideaId === null || ideaId === undefined) {
                throw new RequiredError('ideaId','Required parameter ideaId was null or undefined when calling getCertificate.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getCertificate.');
            }
            const localVarPath = `/ideas/{ideaId}/certificates/{userId}`
                .replace(`{${"ideaId"}}`, encodeURIComponent(String(ideaId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets an idea
         * @param {number} ideaId an idea id
         * @param {boolean} [pageView] count as pageView?
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdea(ideaId: number, pageView?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'ideaId' is not null or undefined
            if (ideaId === null || ideaId === undefined) {
                throw new RequiredError('ideaId','Required parameter ideaId was null or undefined when calling getIdea.');
            }
            const localVarPath = `/ideas/{ideaId}`
                .replace(`{${"ideaId"}}`, encodeURIComponent(String(ideaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (pageView !== undefined) {
                localVarQueryParameter['pageView'] = pageView;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the activity of an idea
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeaActivities(ideaId: number, options: any = {}): FetchArgs {
            // verify required parameter 'ideaId' is not null or undefined
            if (ideaId === null || ideaId === undefined) {
                throw new RequiredError('ideaId','Required parameter ideaId was null or undefined when calling getIdeaActivities.');
            }
            const localVarPath = `/ideas/{ideaId}/activities`
                .replace(`{${"ideaId"}}`, encodeURIComponent(String(ideaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get comment of an idea
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeaComments(ideaId: number, options: any = {}): FetchArgs {
            // verify required parameter 'ideaId' is not null or undefined
            if (ideaId === null || ideaId === undefined) {
                throw new RequiredError('ideaId','Required parameter ideaId was null or undefined when calling getIdeaComments.');
            }
            const localVarPath = `/ideas/{ideaId}/comments`
                .replace(`{${"ideaId"}}`, encodeURIComponent(String(ideaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets idea evaluations
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeaEvaluations(ideaId: number, options: any = {}): FetchArgs {
            // verify required parameter 'ideaId' is not null or undefined
            if (ideaId === null || ideaId === undefined) {
                throw new RequiredError('ideaId','Required parameter ideaId was null or undefined when calling getIdeaEvaluations.');
            }
            const localVarPath = `/ideas/{ideaId}/evaluations`
                .replace(`{${"ideaId"}}`, encodeURIComponent(String(ideaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets a summary evaluation an idea
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeaEvaluationsSummary(ideaId: number, options: any = {}): FetchArgs {
            // verify required parameter 'ideaId' is not null or undefined
            if (ideaId === null || ideaId === undefined) {
                throw new RequiredError('ideaId','Required parameter ideaId was null or undefined when calling getIdeaEvaluationsSummary.');
            }
            const localVarPath = `/ideas/{ideaId}/evaluations/summary`
                .replace(`{${"ideaId"}}`, encodeURIComponent(String(ideaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets like for idea given by user
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeaLiked(ideaId: number, options: any = {}): FetchArgs {
            // verify required parameter 'ideaId' is not null or undefined
            if (ideaId === null || ideaId === undefined) {
                throw new RequiredError('ideaId','Required parameter ideaId was null or undefined when calling getIdeaLiked.');
            }
            const localVarPath = `/ideas/{ideaId}/likes`
                .replace(`{${"ideaId"}}`, encodeURIComponent(String(ideaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * creates a new idea member
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeaMembers(ideaId: number, options: any = {}): FetchArgs {
            // verify required parameter 'ideaId' is not null or undefined
            if (ideaId === null || ideaId === undefined) {
                throw new RequiredError('ideaId','Required parameter ideaId was null or undefined when calling getIdeaMembers.');
            }
            const localVarPath = `/ideas/{ideaId}/members`
                .replace(`{${"ideaId"}}`, encodeURIComponent(String(ideaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets idea state durations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeaStateDurations(options: any = {}): FetchArgs {
            const localVarPath = `/ideaStateDurations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets rating for idea given by user, or 0
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeaUserRating(ideaId: number, options: any = {}): FetchArgs {
            // verify required parameter 'ideaId' is not null or undefined
            if (ideaId === null || ideaId === undefined) {
                throw new RequiredError('ideaId','Required parameter ideaId was null or undefined when calling getIdeaUserRating.');
            }
            const localVarPath = `/ideas/{ideaId}/ratings`
                .replace(`{${"ideaId"}}`, encodeURIComponent(String(ideaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets ideas
         * @param {string} [searchTerm] a natural lanaguage full text search term for searching id
         * @param {'newest' | 'highlighted'} [ranking] Type of ranking of the returned results
         * @param {number} [limit] limits the returned results
         * @param {'full' | 'slim' | 'ref'} [dtoResponseType] the desired DTO return type object.
         * @param {boolean} [unacceptedMembers] finds ideas with unaccepted members only
         * @param {boolean} [excludeCompleted] excludes completed ideas (not relevant in admin dashboard)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeas(searchTerm?: string, ranking?: 'newest' | 'highlighted', limit?: number, dtoResponseType?: 'full' | 'slim' | 'ref', unacceptedMembers?: boolean, excludeCompleted?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/ideas`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (ranking !== undefined) {
                localVarQueryParameter['ranking'] = ranking;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (dtoResponseType !== undefined) {
                localVarQueryParameter['dtoResponseType'] = dtoResponseType;
            }

            if (unacceptedMembers !== undefined) {
                localVarQueryParameter['unacceptedMembers'] = unacceptedMembers;
            }

            if (excludeCompleted !== undefined) {
                localVarQueryParameter['excludeCompleted'] = excludeCompleted;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets ideas count
         * @param {number} [sinceDays] ideas count since days
         * @param {string} [ideastate] idea state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeasCount(sinceDays?: number, ideastate?: string, options: any = {}): FetchArgs {
            const localVarPath = `/ideas/count`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sinceDays !== undefined) {
                localVarQueryParameter['sinceDays'] = sinceDays;
            }

            if (ideastate !== undefined) {
                localVarQueryParameter['ideastate'] = ideastate;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets deleted ideas
         * @param {number} [limit] limits the returned results
         * @param {'full' | 'slim' | 'ref'} [dtoResponseType] the desired DTO return type object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeasDeleted(limit?: number, dtoResponseType?: 'full' | 'slim' | 'ref', options: any = {}): FetchArgs {
            const localVarPath = `/ideas/restore`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (dtoResponseType !== undefined) {
                localVarQueryParameter['dtoResponseType'] = dtoResponseType;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets related ideas
         * @param {number} ideaId an idea id
         * @param {boolean} [includeRelatedBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedIdeas(ideaId: number, includeRelatedBy?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'ideaId' is not null or undefined
            if (ideaId === null || ideaId === undefined) {
                throw new RequiredError('ideaId','Required parameter ideaId was null or undefined when calling getRelatedIdeas.');
            }
            const localVarPath = `/ideas/{ideaId}/relatedIdeas`
                .replace(`{${"ideaId"}}`, encodeURIComponent(String(ideaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (includeRelatedBy !== undefined) {
                localVarQueryParameter['includeRelatedBy'] = includeRelatedBy;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * is an idea bookmarked by the current user
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isIdeaBookmarked(ideaId: number, options: any = {}): FetchArgs {
            // verify required parameter 'ideaId' is not null or undefined
            if (ideaId === null || ideaId === undefined) {
                throw new RequiredError('ideaId','Required parameter ideaId was null or undefined when calling isIdeaBookmarked.');
            }
            const localVarPath = `/ideas/{ideaId}/bookmarked`
                .replace(`{${"ideaId"}}`, encodeURIComponent(String(ideaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * issues implementation certificates to users of an idea
         * @param {number} ideaId an idea id
         * @param {CertificateInfo} certificateInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueCertificate(ideaId: number, certificateInfo: CertificateInfo, options: any = {}): FetchArgs {
            // verify required parameter 'ideaId' is not null or undefined
            if (ideaId === null || ideaId === undefined) {
                throw new RequiredError('ideaId','Required parameter ideaId was null or undefined when calling issueCertificate.');
            }
            // verify required parameter 'certificateInfo' is not null or undefined
            if (certificateInfo === null || certificateInfo === undefined) {
                throw new RequiredError('certificateInfo','Required parameter certificateInfo was null or undefined when calling issueCertificate.');
            }
            const localVarPath = `/ideas/{ideaId}/certificates`
                .replace(`{${"ideaId"}}`, encodeURIComponent(String(ideaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CertificateInfo" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(certificateInfo || {}) : (certificateInfo || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a new idea
         * @param {IdeaDto} ideaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postIdea(ideaDto: IdeaDto, options: any = {}): FetchArgs {
            // verify required parameter 'ideaDto' is not null or undefined
            if (ideaDto === null || ideaDto === undefined) {
                throw new RequiredError('ideaDto','Required parameter ideaDto was null or undefined when calling postIdea.');
            }
            const localVarPath = `/ideas`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"IdeaDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(ideaDto || {}) : (ideaDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a new comment on an idea
         * @param {number} ideaId an idea id
         * @param {CommentDto} commentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postIdeaComment(ideaId: number, commentDto: CommentDto, options: any = {}): FetchArgs {
            // verify required parameter 'ideaId' is not null or undefined
            if (ideaId === null || ideaId === undefined) {
                throw new RequiredError('ideaId','Required parameter ideaId was null or undefined when calling postIdeaComment.');
            }
            // verify required parameter 'commentDto' is not null or undefined
            if (commentDto === null || commentDto === undefined) {
                throw new RequiredError('commentDto','Required parameter commentDto was null or undefined when calling postIdeaComment.');
            }
            const localVarPath = `/ideas/{ideaId}/comments`
                .replace(`{${"ideaId"}}`, encodeURIComponent(String(ideaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CommentDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(commentDto || {}) : (commentDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create a new idea evaluation
         * @param {number} ideaId an idea id
         * @param {EvaluationDto} evaluationDto 
         * @param {boolean} [sendMail] prevents sending of mails, if false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postIdeaEvaluation(ideaId: number, evaluationDto: EvaluationDto, sendMail?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'ideaId' is not null or undefined
            if (ideaId === null || ideaId === undefined) {
                throw new RequiredError('ideaId','Required parameter ideaId was null or undefined when calling postIdeaEvaluation.');
            }
            // verify required parameter 'evaluationDto' is not null or undefined
            if (evaluationDto === null || evaluationDto === undefined) {
                throw new RequiredError('evaluationDto','Required parameter evaluationDto was null or undefined when calling postIdeaEvaluation.');
            }
            const localVarPath = `/ideas/{ideaId}/evaluations`
                .replace(`{${"ideaId"}}`, encodeURIComponent(String(ideaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (sendMail !== undefined) {
                localVarQueryParameter['sendMail'] = sendMail;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EvaluationDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(evaluationDto || {}) : (evaluationDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * edits an existing idea
         * @param {number} ideaId an idea id
         * @param {IdeaDto} ideaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putIdea(ideaId: number, ideaDto: IdeaDto, options: any = {}): FetchArgs {
            // verify required parameter 'ideaId' is not null or undefined
            if (ideaId === null || ideaId === undefined) {
                throw new RequiredError('ideaId','Required parameter ideaId was null or undefined when calling putIdea.');
            }
            // verify required parameter 'ideaDto' is not null or undefined
            if (ideaDto === null || ideaDto === undefined) {
                throw new RequiredError('ideaDto','Required parameter ideaDto was null or undefined when calling putIdea.');
            }
            const localVarPath = `/ideas/{ideaId}`
                .replace(`{${"ideaId"}}`, encodeURIComponent(String(ideaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"IdeaDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(ideaDto || {}) : (ideaDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * adds a like to an idea
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putIdeaLikes(ideaId: number, options: any = {}): FetchArgs {
            // verify required parameter 'ideaId' is not null or undefined
            if (ideaId === null || ideaId === undefined) {
                throw new RequiredError('ideaId','Required parameter ideaId was null or undefined when calling putIdeaLikes.');
            }
            const localVarPath = `/ideas/{ideaId}/likes`
                .replace(`{${"ideaId"}}`, encodeURIComponent(String(ideaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * rates an idea
         * @param {number} ideaId an idea id
         * @param {number} rating rating (1-4 stars) of an idea
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putIdeaRatings(ideaId: number, rating: number, options: any = {}): FetchArgs {
            // verify required parameter 'ideaId' is not null or undefined
            if (ideaId === null || ideaId === undefined) {
                throw new RequiredError('ideaId','Required parameter ideaId was null or undefined when calling putIdeaRatings.');
            }
            // verify required parameter 'rating' is not null or undefined
            if (rating === null || rating === undefined) {
                throw new RequiredError('rating','Required parameter rating was null or undefined when calling putIdeaRatings.');
            }
            const localVarPath = `/ideas/{ideaId}/ratings`
                .replace(`{${"ideaId"}}`, encodeURIComponent(String(ideaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"number" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(rating || {}) : (rating || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * relates ideas
         * @param {number} ideaId an idea id
         * @param {number} relatedIdeaId an related idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putRelatedIdea(ideaId: number, relatedIdeaId: number, options: any = {}): FetchArgs {
            // verify required parameter 'ideaId' is not null or undefined
            if (ideaId === null || ideaId === undefined) {
                throw new RequiredError('ideaId','Required parameter ideaId was null or undefined when calling putRelatedIdea.');
            }
            // verify required parameter 'relatedIdeaId' is not null or undefined
            if (relatedIdeaId === null || relatedIdeaId === undefined) {
                throw new RequiredError('relatedIdeaId','Required parameter relatedIdeaId was null or undefined when calling putRelatedIdea.');
            }
            const localVarPath = `/ideas/{ideaId}/relatedIdeas/{relatedIdeaId}`
                .replace(`{${"ideaId"}}`, encodeURIComponent(String(ideaId)))
                .replace(`{${"relatedIdeaId"}}`, encodeURIComponent(String(relatedIdeaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * removes a related ideas from the given idea
         * @param {number} ideaId an idea id
         * @param {number} relatedIdeaId an related idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRelatedIdea(ideaId: number, relatedIdeaId: number, options: any = {}): FetchArgs {
            // verify required parameter 'ideaId' is not null or undefined
            if (ideaId === null || ideaId === undefined) {
                throw new RequiredError('ideaId','Required parameter ideaId was null or undefined when calling removeRelatedIdea.');
            }
            // verify required parameter 'relatedIdeaId' is not null or undefined
            if (relatedIdeaId === null || relatedIdeaId === undefined) {
                throw new RequiredError('relatedIdeaId','Required parameter relatedIdeaId was null or undefined when calling removeRelatedIdea.');
            }
            const localVarPath = `/ideas/{ideaId}/relatedIdeas/{relatedIdeaId}`
                .replace(`{${"ideaId"}}`, encodeURIComponent(String(ideaId)))
                .replace(`{${"relatedIdeaId"}}`, encodeURIComponent(String(relatedIdeaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * restores an existing idea
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreIdea(ideaId: number, options: any = {}): FetchArgs {
            // verify required parameter 'ideaId' is not null or undefined
            if (ideaId === null || ideaId === undefined) {
                throw new RequiredError('ideaId','Required parameter ideaId was null or undefined when calling restoreIdea.');
            }
            const localVarPath = `/ideas/restore/{ideaId}`
                .replace(`{${"ideaId"}}`, encodeURIComponent(String(ideaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * creates a bookmark on an idea for the current user
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setIdeaBookmarked(ideaId: number, options: any = {}): FetchArgs {
            // verify required parameter 'ideaId' is not null or undefined
            if (ideaId === null || ideaId === undefined) {
                throw new RequiredError('ideaId','Required parameter ideaId was null or undefined when calling setIdeaBookmarked.');
            }
            const localVarPath = `/ideas/{ideaId}/bookmarked`
                .replace(`{${"ideaId"}}`, encodeURIComponent(String(ideaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * shares an idea with other users
         * @param {number} ideaId an idea id
         * @param {ShareIdeaEmailDto} recipients 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareIdea(ideaId: number, recipients: ShareIdeaEmailDto, options: any = {}): FetchArgs {
            // verify required parameter 'ideaId' is not null or undefined
            if (ideaId === null || ideaId === undefined) {
                throw new RequiredError('ideaId','Required parameter ideaId was null or undefined when calling shareIdea.');
            }
            // verify required parameter 'recipients' is not null or undefined
            if (recipients === null || recipients === undefined) {
                throw new RequiredError('recipients','Required parameter recipients was null or undefined when calling shareIdea.');
            }
            const localVarPath = `/ideas/{ideaId}/share`
                .replace(`{${"ideaId"}}`, encodeURIComponent(String(ideaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ShareIdeaEmailDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(recipients || {}) : (recipients || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * removes a bookmark from an idea for the current user
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsetIdeaBookmarked(ideaId: number, options: any = {}): FetchArgs {
            // verify required parameter 'ideaId' is not null or undefined
            if (ideaId === null || ideaId === undefined) {
                throw new RequiredError('ideaId','Required parameter ideaId was null or undefined when calling unsetIdeaBookmarked.');
            }
            const localVarPath = `/ideas/{ideaId}/bookmarked`
                .replace(`{${"ideaId"}}`, encodeURIComponent(String(ideaId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IdeaApi - functional programming interface
 * @export
 */
export const IdeaApiFp = function(configuration?: Configuration) {
    return {
        /**
         * creates a new idea member
         * @param {number} ideaId an idea id
         * @param {IdeaMemberDto} ideaMemberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIdeaMember(ideaId: number, ideaMemberDto: IdeaMemberDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IdeaMemberDto> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).addIdeaMember(ideaId, ideaMemberDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * sets the deleted flag on an idea
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIdea(ideaId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).deleteIdea(ideaId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * creates a implementation certificate pdf
         * @param {number} ideaId an idea id
         * @param {number} userId a user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertificate(ideaId: number, userId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).getCertificate(ideaId, userId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Gets an idea
         * @param {number} ideaId an idea id
         * @param {boolean} [pageView] count as pageView?
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdea(ideaId: number, pageView?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IdeaDto> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).getIdea(ideaId, pageView, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Gets the activity of an idea
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeaActivities(ideaId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ActivityLogDto>> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).getIdeaActivities(ideaId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * get comment of an idea
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeaComments(ideaId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CommentDto>> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).getIdeaComments(ideaId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * gets idea evaluations
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeaEvaluations(ideaId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<EvaluationDto>> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).getIdeaEvaluations(ideaId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * gets a summary evaluation an idea
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeaEvaluationsSummary(ideaId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EvaluationDto> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).getIdeaEvaluationsSummary(ideaId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * gets like for idea given by user
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeaLiked(ideaId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).getIdeaLiked(ideaId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * creates a new idea member
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeaMembers(ideaId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<IdeaMemberDto>> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).getIdeaMembers(ideaId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * gets idea state durations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeaStateDurations(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<IdeaStateDurationDto>> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).getIdeaStateDurations(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * gets rating for idea given by user, or 0
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeaUserRating(ideaId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).getIdeaUserRating(ideaId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Gets ideas
         * @param {string} [searchTerm] a natural lanaguage full text search term for searching id
         * @param {'newest' | 'highlighted'} [ranking] Type of ranking of the returned results
         * @param {number} [limit] limits the returned results
         * @param {'full' | 'slim' | 'ref'} [dtoResponseType] the desired DTO return type object.
         * @param {boolean} [unacceptedMembers] finds ideas with unaccepted members only
         * @param {boolean} [excludeCompleted] excludes completed ideas (not relevant in admin dashboard)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeas(searchTerm?: string, ranking?: 'newest' | 'highlighted', limit?: number, dtoResponseType?: 'full' | 'slim' | 'ref', unacceptedMembers?: boolean, excludeCompleted?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<IdeaDto>> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).getIdeas(searchTerm, ranking, limit, dtoResponseType, unacceptedMembers, excludeCompleted, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * gets ideas count
         * @param {number} [sinceDays] ideas count since days
         * @param {string} [ideastate] idea state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeasCount(sinceDays?: number, ideastate?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).getIdeasCount(sinceDays, ideastate, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Gets deleted ideas
         * @param {number} [limit] limits the returned results
         * @param {'full' | 'slim' | 'ref'} [dtoResponseType] the desired DTO return type object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeasDeleted(limit?: number, dtoResponseType?: 'full' | 'slim' | 'ref', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<IdeaDto>> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).getIdeasDeleted(limit, dtoResponseType, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * gets related ideas
         * @param {number} ideaId an idea id
         * @param {boolean} [includeRelatedBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedIdeas(ideaId: number, includeRelatedBy?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<IdeaDto>> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).getRelatedIdeas(ideaId, includeRelatedBy, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * is an idea bookmarked by the current user
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isIdeaBookmarked(ideaId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).isIdeaBookmarked(ideaId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * issues implementation certificates to users of an idea
         * @param {number} ideaId an idea id
         * @param {CertificateInfo} certificateInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueCertificate(ideaId: number, certificateInfo: CertificateInfo, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).issueCertificate(ideaId, certificateInfo, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * create a new idea
         * @param {IdeaDto} ideaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postIdea(ideaDto: IdeaDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IdeaDto> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).postIdea(ideaDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * create a new comment on an idea
         * @param {number} ideaId an idea id
         * @param {CommentDto} commentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postIdeaComment(ideaId: number, commentDto: CommentDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CommentDto> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).postIdeaComment(ideaId, commentDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * create a new idea evaluation
         * @param {number} ideaId an idea id
         * @param {EvaluationDto} evaluationDto 
         * @param {boolean} [sendMail] prevents sending of mails, if false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postIdeaEvaluation(ideaId: number, evaluationDto: EvaluationDto, sendMail?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EvaluationDto> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).postIdeaEvaluation(ideaId, evaluationDto, sendMail, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * edits an existing idea
         * @param {number} ideaId an idea id
         * @param {IdeaDto} ideaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putIdea(ideaId: number, ideaDto: IdeaDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IdeaDto> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).putIdea(ideaId, ideaDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * adds a like to an idea
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putIdeaLikes(ideaId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).putIdeaLikes(ideaId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * rates an idea
         * @param {number} ideaId an idea id
         * @param {number} rating rating (1-4 stars) of an idea
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putIdeaRatings(ideaId: number, rating: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Rating> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).putIdeaRatings(ideaId, rating, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * relates ideas
         * @param {number} ideaId an idea id
         * @param {number} relatedIdeaId an related idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putRelatedIdea(ideaId: number, relatedIdeaId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).putRelatedIdea(ideaId, relatedIdeaId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * removes a related ideas from the given idea
         * @param {number} ideaId an idea id
         * @param {number} relatedIdeaId an related idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRelatedIdea(ideaId: number, relatedIdeaId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).removeRelatedIdea(ideaId, relatedIdeaId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * restores an existing idea
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreIdea(ideaId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IdeaDto> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).restoreIdea(ideaId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * creates a bookmark on an idea for the current user
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setIdeaBookmarked(ideaId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).setIdeaBookmarked(ideaId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * shares an idea with other users
         * @param {number} ideaId an idea id
         * @param {ShareIdeaEmailDto} recipients 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareIdea(ideaId: number, recipients: ShareIdeaEmailDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).shareIdea(ideaId, recipients, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * removes a bookmark from an idea for the current user
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsetIdeaBookmarked(ideaId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = IdeaApiFetchParamCreator(configuration).unsetIdeaBookmarked(ideaId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * IdeaApi - factory interface
 * @export
 */
export const IdeaApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * creates a new idea member
         * @param {number} ideaId an idea id
         * @param {IdeaMemberDto} ideaMemberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIdeaMember(ideaId: number, ideaMemberDto: IdeaMemberDto, options?: any) {
            return IdeaApiFp(configuration).addIdeaMember(ideaId, ideaMemberDto, options)(fetch, basePath);
        },
        /**
         * sets the deleted flag on an idea
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIdea(ideaId: number, options?: any) {
            return IdeaApiFp(configuration).deleteIdea(ideaId, options)(fetch, basePath);
        },
        /**
         * creates a implementation certificate pdf
         * @param {number} ideaId an idea id
         * @param {number} userId a user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCertificate(ideaId: number, userId: number, options?: any) {
            return IdeaApiFp(configuration).getCertificate(ideaId, userId, options)(fetch, basePath);
        },
        /**
         * Gets an idea
         * @param {number} ideaId an idea id
         * @param {boolean} [pageView] count as pageView?
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdea(ideaId: number, pageView?: boolean, options?: any) {
            return IdeaApiFp(configuration).getIdea(ideaId, pageView, options)(fetch, basePath);
        },
        /**
         * Gets the activity of an idea
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeaActivities(ideaId: number, options?: any) {
            return IdeaApiFp(configuration).getIdeaActivities(ideaId, options)(fetch, basePath);
        },
        /**
         * get comment of an idea
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeaComments(ideaId: number, options?: any) {
            return IdeaApiFp(configuration).getIdeaComments(ideaId, options)(fetch, basePath);
        },
        /**
         * gets idea evaluations
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeaEvaluations(ideaId: number, options?: any) {
            return IdeaApiFp(configuration).getIdeaEvaluations(ideaId, options)(fetch, basePath);
        },
        /**
         * gets a summary evaluation an idea
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeaEvaluationsSummary(ideaId: number, options?: any) {
            return IdeaApiFp(configuration).getIdeaEvaluationsSummary(ideaId, options)(fetch, basePath);
        },
        /**
         * gets like for idea given by user
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeaLiked(ideaId: number, options?: any) {
            return IdeaApiFp(configuration).getIdeaLiked(ideaId, options)(fetch, basePath);
        },
        /**
         * creates a new idea member
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeaMembers(ideaId: number, options?: any) {
            return IdeaApiFp(configuration).getIdeaMembers(ideaId, options)(fetch, basePath);
        },
        /**
         * gets idea state durations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeaStateDurations(options?: any) {
            return IdeaApiFp(configuration).getIdeaStateDurations(options)(fetch, basePath);
        },
        /**
         * gets rating for idea given by user, or 0
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeaUserRating(ideaId: number, options?: any) {
            return IdeaApiFp(configuration).getIdeaUserRating(ideaId, options)(fetch, basePath);
        },
        /**
         * Gets ideas
         * @param {string} [searchTerm] a natural lanaguage full text search term for searching id
         * @param {'newest' | 'highlighted'} [ranking] Type of ranking of the returned results
         * @param {number} [limit] limits the returned results
         * @param {'full' | 'slim' | 'ref'} [dtoResponseType] the desired DTO return type object.
         * @param {boolean} [unacceptedMembers] finds ideas with unaccepted members only
         * @param {boolean} [excludeCompleted] excludes completed ideas (not relevant in admin dashboard)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeas(searchTerm?: string, ranking?: 'newest' | 'highlighted', limit?: number, dtoResponseType?: 'full' | 'slim' | 'ref', unacceptedMembers?: boolean, excludeCompleted?: boolean, options?: any) {
            return IdeaApiFp(configuration).getIdeas(searchTerm, ranking, limit, dtoResponseType, unacceptedMembers, excludeCompleted, options)(fetch, basePath);
        },
        /**
         * gets ideas count
         * @param {number} [sinceDays] ideas count since days
         * @param {string} [ideastate] idea state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeasCount(sinceDays?: number, ideastate?: string, options?: any) {
            return IdeaApiFp(configuration).getIdeasCount(sinceDays, ideastate, options)(fetch, basePath);
        },
        /**
         * Gets deleted ideas
         * @param {number} [limit] limits the returned results
         * @param {'full' | 'slim' | 'ref'} [dtoResponseType] the desired DTO return type object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeasDeleted(limit?: number, dtoResponseType?: 'full' | 'slim' | 'ref', options?: any) {
            return IdeaApiFp(configuration).getIdeasDeleted(limit, dtoResponseType, options)(fetch, basePath);
        },
        /**
         * gets related ideas
         * @param {number} ideaId an idea id
         * @param {boolean} [includeRelatedBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedIdeas(ideaId: number, includeRelatedBy?: boolean, options?: any) {
            return IdeaApiFp(configuration).getRelatedIdeas(ideaId, includeRelatedBy, options)(fetch, basePath);
        },
        /**
         * is an idea bookmarked by the current user
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isIdeaBookmarked(ideaId: number, options?: any) {
            return IdeaApiFp(configuration).isIdeaBookmarked(ideaId, options)(fetch, basePath);
        },
        /**
         * issues implementation certificates to users of an idea
         * @param {number} ideaId an idea id
         * @param {CertificateInfo} certificateInfo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueCertificate(ideaId: number, certificateInfo: CertificateInfo, options?: any) {
            return IdeaApiFp(configuration).issueCertificate(ideaId, certificateInfo, options)(fetch, basePath);
        },
        /**
         * create a new idea
         * @param {IdeaDto} ideaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postIdea(ideaDto: IdeaDto, options?: any) {
            return IdeaApiFp(configuration).postIdea(ideaDto, options)(fetch, basePath);
        },
        /**
         * create a new comment on an idea
         * @param {number} ideaId an idea id
         * @param {CommentDto} commentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postIdeaComment(ideaId: number, commentDto: CommentDto, options?: any) {
            return IdeaApiFp(configuration).postIdeaComment(ideaId, commentDto, options)(fetch, basePath);
        },
        /**
         * create a new idea evaluation
         * @param {number} ideaId an idea id
         * @param {EvaluationDto} evaluationDto 
         * @param {boolean} [sendMail] prevents sending of mails, if false
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postIdeaEvaluation(ideaId: number, evaluationDto: EvaluationDto, sendMail?: boolean, options?: any) {
            return IdeaApiFp(configuration).postIdeaEvaluation(ideaId, evaluationDto, sendMail, options)(fetch, basePath);
        },
        /**
         * edits an existing idea
         * @param {number} ideaId an idea id
         * @param {IdeaDto} ideaDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putIdea(ideaId: number, ideaDto: IdeaDto, options?: any) {
            return IdeaApiFp(configuration).putIdea(ideaId, ideaDto, options)(fetch, basePath);
        },
        /**
         * adds a like to an idea
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putIdeaLikes(ideaId: number, options?: any) {
            return IdeaApiFp(configuration).putIdeaLikes(ideaId, options)(fetch, basePath);
        },
        /**
         * rates an idea
         * @param {number} ideaId an idea id
         * @param {number} rating rating (1-4 stars) of an idea
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putIdeaRatings(ideaId: number, rating: number, options?: any) {
            return IdeaApiFp(configuration).putIdeaRatings(ideaId, rating, options)(fetch, basePath);
        },
        /**
         * relates ideas
         * @param {number} ideaId an idea id
         * @param {number} relatedIdeaId an related idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putRelatedIdea(ideaId: number, relatedIdeaId: number, options?: any) {
            return IdeaApiFp(configuration).putRelatedIdea(ideaId, relatedIdeaId, options)(fetch, basePath);
        },
        /**
         * removes a related ideas from the given idea
         * @param {number} ideaId an idea id
         * @param {number} relatedIdeaId an related idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRelatedIdea(ideaId: number, relatedIdeaId: number, options?: any) {
            return IdeaApiFp(configuration).removeRelatedIdea(ideaId, relatedIdeaId, options)(fetch, basePath);
        },
        /**
         * restores an existing idea
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreIdea(ideaId: number, options?: any) {
            return IdeaApiFp(configuration).restoreIdea(ideaId, options)(fetch, basePath);
        },
        /**
         * creates a bookmark on an idea for the current user
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setIdeaBookmarked(ideaId: number, options?: any) {
            return IdeaApiFp(configuration).setIdeaBookmarked(ideaId, options)(fetch, basePath);
        },
        /**
         * shares an idea with other users
         * @param {number} ideaId an idea id
         * @param {ShareIdeaEmailDto} recipients 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareIdea(ideaId: number, recipients: ShareIdeaEmailDto, options?: any) {
            return IdeaApiFp(configuration).shareIdea(ideaId, recipients, options)(fetch, basePath);
        },
        /**
         * removes a bookmark from an idea for the current user
         * @param {number} ideaId an idea id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsetIdeaBookmarked(ideaId: number, options?: any) {
            return IdeaApiFp(configuration).unsetIdeaBookmarked(ideaId, options)(fetch, basePath);
        },
    };
};

/**
 * IdeaApi - object-oriented interface
 * @export
 * @class IdeaApi
 * @extends {BaseAPI}
 */
export class IdeaApi extends BaseAPI {
    /**
     * creates a new idea member
     * @param {number} ideaId an idea id
     * @param {IdeaMemberDto} ideaMemberDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public addIdeaMember(ideaId: number, ideaMemberDto: IdeaMemberDto, options?: any) {
        return IdeaApiFp(this.configuration).addIdeaMember(ideaId, ideaMemberDto, options)(this.fetch, this.basePath);
    }

    /**
     * sets the deleted flag on an idea
     * @param {number} ideaId an idea id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public deleteIdea(ideaId: number, options?: any) {
        return IdeaApiFp(this.configuration).deleteIdea(ideaId, options)(this.fetch, this.basePath);
    }

    /**
     * creates a implementation certificate pdf
     * @param {number} ideaId an idea id
     * @param {number} userId a user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public getCertificate(ideaId: number, userId: number, options?: any) {
        return IdeaApiFp(this.configuration).getCertificate(ideaId, userId, options)(this.fetch, this.basePath);
    }

    /**
     * Gets an idea
     * @param {number} ideaId an idea id
     * @param {boolean} [pageView] count as pageView?
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public getIdea(ideaId: number, pageView?: boolean, options?: any) {
        return IdeaApiFp(this.configuration).getIdea(ideaId, pageView, options)(this.fetch, this.basePath);
    }

    /**
     * Gets the activity of an idea
     * @param {number} ideaId an idea id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public getIdeaActivities(ideaId: number, options?: any) {
        return IdeaApiFp(this.configuration).getIdeaActivities(ideaId, options)(this.fetch, this.basePath);
    }

    /**
     * get comment of an idea
     * @param {number} ideaId an idea id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public getIdeaComments(ideaId: number, options?: any) {
        return IdeaApiFp(this.configuration).getIdeaComments(ideaId, options)(this.fetch, this.basePath);
    }

    /**
     * gets idea evaluations
     * @param {number} ideaId an idea id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public getIdeaEvaluations(ideaId: number, options?: any) {
        return IdeaApiFp(this.configuration).getIdeaEvaluations(ideaId, options)(this.fetch, this.basePath);
    }

    /**
     * gets a summary evaluation an idea
     * @param {number} ideaId an idea id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public getIdeaEvaluationsSummary(ideaId: number, options?: any) {
        return IdeaApiFp(this.configuration).getIdeaEvaluationsSummary(ideaId, options)(this.fetch, this.basePath);
    }

    /**
     * gets like for idea given by user
     * @param {number} ideaId an idea id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public getIdeaLiked(ideaId: number, options?: any) {
        return IdeaApiFp(this.configuration).getIdeaLiked(ideaId, options)(this.fetch, this.basePath);
    }

    /**
     * creates a new idea member
     * @param {number} ideaId an idea id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public getIdeaMembers(ideaId: number, options?: any) {
        return IdeaApiFp(this.configuration).getIdeaMembers(ideaId, options)(this.fetch, this.basePath);
    }

    /**
     * gets idea state durations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public getIdeaStateDurations(options?: any) {
        return IdeaApiFp(this.configuration).getIdeaStateDurations(options)(this.fetch, this.basePath);
    }

    /**
     * gets rating for idea given by user, or 0
     * @param {number} ideaId an idea id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public getIdeaUserRating(ideaId: number, options?: any) {
        return IdeaApiFp(this.configuration).getIdeaUserRating(ideaId, options)(this.fetch, this.basePath);
    }

    /**
     * Gets ideas
     * @param {string} [searchTerm] a natural lanaguage full text search term for searching id
     * @param {'newest' | 'highlighted'} [ranking] Type of ranking of the returned results
     * @param {number} [limit] limits the returned results
     * @param {'full' | 'slim' | 'ref'} [dtoResponseType] the desired DTO return type object.
     * @param {boolean} [unacceptedMembers] finds ideas with unaccepted members only
     * @param {boolean} [excludeCompleted] excludes completed ideas (not relevant in admin dashboard)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public getIdeas(searchTerm?: string, ranking?: 'newest' | 'highlighted', limit?: number, dtoResponseType?: 'full' | 'slim' | 'ref', unacceptedMembers?: boolean, excludeCompleted?: boolean, options?: any) {
        return IdeaApiFp(this.configuration).getIdeas(searchTerm, ranking, limit, dtoResponseType, unacceptedMembers, excludeCompleted, options)(this.fetch, this.basePath);
    }

    /**
     * gets ideas count
     * @param {number} [sinceDays] ideas count since days
     * @param {string} [ideastate] idea state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public getIdeasCount(sinceDays?: number, ideastate?: string, options?: any) {
        return IdeaApiFp(this.configuration).getIdeasCount(sinceDays, ideastate, options)(this.fetch, this.basePath);
    }

    /**
     * Gets deleted ideas
     * @param {number} [limit] limits the returned results
     * @param {'full' | 'slim' | 'ref'} [dtoResponseType] the desired DTO return type object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public getIdeasDeleted(limit?: number, dtoResponseType?: 'full' | 'slim' | 'ref', options?: any) {
        return IdeaApiFp(this.configuration).getIdeasDeleted(limit, dtoResponseType, options)(this.fetch, this.basePath);
    }

    /**
     * gets related ideas
     * @param {number} ideaId an idea id
     * @param {boolean} [includeRelatedBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public getRelatedIdeas(ideaId: number, includeRelatedBy?: boolean, options?: any) {
        return IdeaApiFp(this.configuration).getRelatedIdeas(ideaId, includeRelatedBy, options)(this.fetch, this.basePath);
    }

    /**
     * is an idea bookmarked by the current user
     * @param {number} ideaId an idea id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public isIdeaBookmarked(ideaId: number, options?: any) {
        return IdeaApiFp(this.configuration).isIdeaBookmarked(ideaId, options)(this.fetch, this.basePath);
    }

    /**
     * issues implementation certificates to users of an idea
     * @param {number} ideaId an idea id
     * @param {CertificateInfo} certificateInfo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public issueCertificate(ideaId: number, certificateInfo: CertificateInfo, options?: any) {
        return IdeaApiFp(this.configuration).issueCertificate(ideaId, certificateInfo, options)(this.fetch, this.basePath);
    }

    /**
     * create a new idea
     * @param {IdeaDto} ideaDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public postIdea(ideaDto: IdeaDto, options?: any) {
        return IdeaApiFp(this.configuration).postIdea(ideaDto, options)(this.fetch, this.basePath);
    }

    /**
     * create a new comment on an idea
     * @param {number} ideaId an idea id
     * @param {CommentDto} commentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public postIdeaComment(ideaId: number, commentDto: CommentDto, options?: any) {
        return IdeaApiFp(this.configuration).postIdeaComment(ideaId, commentDto, options)(this.fetch, this.basePath);
    }

    /**
     * create a new idea evaluation
     * @param {number} ideaId an idea id
     * @param {EvaluationDto} evaluationDto 
     * @param {boolean} [sendMail] prevents sending of mails, if false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public postIdeaEvaluation(ideaId: number, evaluationDto: EvaluationDto, sendMail?: boolean, options?: any) {
        return IdeaApiFp(this.configuration).postIdeaEvaluation(ideaId, evaluationDto, sendMail, options)(this.fetch, this.basePath);
    }

    /**
     * edits an existing idea
     * @param {number} ideaId an idea id
     * @param {IdeaDto} ideaDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public putIdea(ideaId: number, ideaDto: IdeaDto, options?: any) {
        return IdeaApiFp(this.configuration).putIdea(ideaId, ideaDto, options)(this.fetch, this.basePath);
    }

    /**
     * adds a like to an idea
     * @param {number} ideaId an idea id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public putIdeaLikes(ideaId: number, options?: any) {
        return IdeaApiFp(this.configuration).putIdeaLikes(ideaId, options)(this.fetch, this.basePath);
    }

    /**
     * rates an idea
     * @param {number} ideaId an idea id
     * @param {number} rating rating (1-4 stars) of an idea
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public putIdeaRatings(ideaId: number, rating: number, options?: any) {
        return IdeaApiFp(this.configuration).putIdeaRatings(ideaId, rating, options)(this.fetch, this.basePath);
    }

    /**
     * relates ideas
     * @param {number} ideaId an idea id
     * @param {number} relatedIdeaId an related idea id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public putRelatedIdea(ideaId: number, relatedIdeaId: number, options?: any) {
        return IdeaApiFp(this.configuration).putRelatedIdea(ideaId, relatedIdeaId, options)(this.fetch, this.basePath);
    }

    /**
     * removes a related ideas from the given idea
     * @param {number} ideaId an idea id
     * @param {number} relatedIdeaId an related idea id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public removeRelatedIdea(ideaId: number, relatedIdeaId: number, options?: any) {
        return IdeaApiFp(this.configuration).removeRelatedIdea(ideaId, relatedIdeaId, options)(this.fetch, this.basePath);
    }

    /**
     * restores an existing idea
     * @param {number} ideaId an idea id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public restoreIdea(ideaId: number, options?: any) {
        return IdeaApiFp(this.configuration).restoreIdea(ideaId, options)(this.fetch, this.basePath);
    }

    /**
     * creates a bookmark on an idea for the current user
     * @param {number} ideaId an idea id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public setIdeaBookmarked(ideaId: number, options?: any) {
        return IdeaApiFp(this.configuration).setIdeaBookmarked(ideaId, options)(this.fetch, this.basePath);
    }

    /**
     * shares an idea with other users
     * @param {number} ideaId an idea id
     * @param {ShareIdeaEmailDto} recipients 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public shareIdea(ideaId: number, recipients: ShareIdeaEmailDto, options?: any) {
        return IdeaApiFp(this.configuration).shareIdea(ideaId, recipients, options)(this.fetch, this.basePath);
    }

    /**
     * removes a bookmark from an idea for the current user
     * @param {number} ideaId an idea id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaApi
     */
    public unsetIdeaBookmarked(ideaId: number, options?: any) {
        return IdeaApiFp(this.configuration).unsetIdeaBookmarked(ideaId, options)(this.fetch, this.basePath);
    }

}

/**
 * IdeaMemberApi - fetch parameter creator
 * @export
 */
export const IdeaMemberApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * deletes an idea member
         * @param {number} ideaMemberId an idea member id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIdeaMember(ideaMemberId: number, options: any = {}): FetchArgs {
            // verify required parameter 'ideaMemberId' is not null or undefined
            if (ideaMemberId === null || ideaMemberId === undefined) {
                throw new RequiredError('ideaMemberId','Required parameter ideaMemberId was null or undefined when calling deleteIdeaMember.');
            }
            const localVarPath = `/ideaMembers/{ideaMemberId}`
                .replace(`{${"ideaMemberId"}}`, encodeURIComponent(String(ideaMemberId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets a specific idea member
         * @param {number} ideaMemberId an idea member id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeaMember(ideaMemberId: number, options: any = {}): FetchArgs {
            // verify required parameter 'ideaMemberId' is not null or undefined
            if (ideaMemberId === null || ideaMemberId === undefined) {
                throw new RequiredError('ideaMemberId','Required parameter ideaMemberId was null or undefined when calling getIdeaMember.');
            }
            const localVarPath = `/ideaMembers/{ideaMemberId}`
                .replace(`{${"ideaMemberId"}}`, encodeURIComponent(String(ideaMemberId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * edits an existing idea member
         * @param {number} ideaMemberId an idea member id
         * @param {IdeaMemberDto} ideaMemberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putIdeaMember(ideaMemberId: number, ideaMemberDto: IdeaMemberDto, options: any = {}): FetchArgs {
            // verify required parameter 'ideaMemberId' is not null or undefined
            if (ideaMemberId === null || ideaMemberId === undefined) {
                throw new RequiredError('ideaMemberId','Required parameter ideaMemberId was null or undefined when calling putIdeaMember.');
            }
            // verify required parameter 'ideaMemberDto' is not null or undefined
            if (ideaMemberDto === null || ideaMemberDto === undefined) {
                throw new RequiredError('ideaMemberDto','Required parameter ideaMemberDto was null or undefined when calling putIdeaMember.');
            }
            const localVarPath = `/ideaMembers/{ideaMemberId}`
                .replace(`{${"ideaMemberId"}}`, encodeURIComponent(String(ideaMemberId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"IdeaMemberDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(ideaMemberDto || {}) : (ideaMemberDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IdeaMemberApi - functional programming interface
 * @export
 */
export const IdeaMemberApiFp = function(configuration?: Configuration) {
    return {
        /**
         * deletes an idea member
         * @param {number} ideaMemberId an idea member id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIdeaMember(ideaMemberId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = IdeaMemberApiFetchParamCreator(configuration).deleteIdeaMember(ideaMemberId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * gets a specific idea member
         * @param {number} ideaMemberId an idea member id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeaMember(ideaMemberId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IdeaMemberDto> {
            const localVarFetchArgs = IdeaMemberApiFetchParamCreator(configuration).getIdeaMember(ideaMemberId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * edits an existing idea member
         * @param {number} ideaMemberId an idea member id
         * @param {IdeaMemberDto} ideaMemberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putIdeaMember(ideaMemberId: number, ideaMemberDto: IdeaMemberDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IdeaMemberDto> {
            const localVarFetchArgs = IdeaMemberApiFetchParamCreator(configuration).putIdeaMember(ideaMemberId, ideaMemberDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * IdeaMemberApi - factory interface
 * @export
 */
export const IdeaMemberApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * deletes an idea member
         * @param {number} ideaMemberId an idea member id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteIdeaMember(ideaMemberId: number, options?: any) {
            return IdeaMemberApiFp(configuration).deleteIdeaMember(ideaMemberId, options)(fetch, basePath);
        },
        /**
         * gets a specific idea member
         * @param {number} ideaMemberId an idea member id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdeaMember(ideaMemberId: number, options?: any) {
            return IdeaMemberApiFp(configuration).getIdeaMember(ideaMemberId, options)(fetch, basePath);
        },
        /**
         * edits an existing idea member
         * @param {number} ideaMemberId an idea member id
         * @param {IdeaMemberDto} ideaMemberDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putIdeaMember(ideaMemberId: number, ideaMemberDto: IdeaMemberDto, options?: any) {
            return IdeaMemberApiFp(configuration).putIdeaMember(ideaMemberId, ideaMemberDto, options)(fetch, basePath);
        },
    };
};

/**
 * IdeaMemberApi - object-oriented interface
 * @export
 * @class IdeaMemberApi
 * @extends {BaseAPI}
 */
export class IdeaMemberApi extends BaseAPI {
    /**
     * deletes an idea member
     * @param {number} ideaMemberId an idea member id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaMemberApi
     */
    public deleteIdeaMember(ideaMemberId: number, options?: any) {
        return IdeaMemberApiFp(this.configuration).deleteIdeaMember(ideaMemberId, options)(this.fetch, this.basePath);
    }

    /**
     * gets a specific idea member
     * @param {number} ideaMemberId an idea member id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaMemberApi
     */
    public getIdeaMember(ideaMemberId: number, options?: any) {
        return IdeaMemberApiFp(this.configuration).getIdeaMember(ideaMemberId, options)(this.fetch, this.basePath);
    }

    /**
     * edits an existing idea member
     * @param {number} ideaMemberId an idea member id
     * @param {IdeaMemberDto} ideaMemberDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdeaMemberApi
     */
    public putIdeaMember(ideaMemberId: number, ideaMemberDto: IdeaMemberDto, options?: any) {
        return IdeaMemberApiFp(this.configuration).putIdeaMember(ideaMemberId, ideaMemberDto, options)(this.fetch, this.basePath);
    }

}

/**
 * NotificationApi - fetch parameter creator
 * @export
 */
export const NotificationApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * dismissses a user notification
         * @param {number} notificationId an user notification id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dismissUserNotification(notificationId: number, options: any = {}): FetchArgs {
            // verify required parameter 'notificationId' is not null or undefined
            if (notificationId === null || notificationId === undefined) {
                throw new RequiredError('notificationId','Required parameter notificationId was null or undefined when calling dismissUserNotification.');
            }
            const localVarPath = `/notifications/{notificationId}`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * dismisses all notifications of the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dismissUserNotifications(options: any = {}): FetchArgs {
            const localVarPath = `/notifications`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets notifications for the current user
         * @param {boolean} [unread] unread only or all notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserNotifications(unread?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/notifications`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (unread !== undefined) {
                localVarQueryParameter['unread'] = unread;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * dismissses a user notification
         * @param {number} notificationId an user notification id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dismissUserNotification(notificationId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = NotificationApiFetchParamCreator(configuration).dismissUserNotification(notificationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * dismisses all notifications of the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dismissUserNotifications(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = NotificationApiFetchParamCreator(configuration).dismissUserNotifications(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * gets notifications for the current user
         * @param {boolean} [unread] unread only or all notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserNotifications(unread?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<UserNotificationDto>> {
            const localVarFetchArgs = NotificationApiFetchParamCreator(configuration).getUserNotifications(unread, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * dismissses a user notification
         * @param {number} notificationId an user notification id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dismissUserNotification(notificationId: number, options?: any) {
            return NotificationApiFp(configuration).dismissUserNotification(notificationId, options)(fetch, basePath);
        },
        /**
         * dismisses all notifications of the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dismissUserNotifications(options?: any) {
            return NotificationApiFp(configuration).dismissUserNotifications(options)(fetch, basePath);
        },
        /**
         * gets notifications for the current user
         * @param {boolean} [unread] unread only or all notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserNotifications(unread?: boolean, options?: any) {
            return NotificationApiFp(configuration).getUserNotifications(unread, options)(fetch, basePath);
        },
    };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * dismissses a user notification
     * @param {number} notificationId an user notification id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public dismissUserNotification(notificationId: number, options?: any) {
        return NotificationApiFp(this.configuration).dismissUserNotification(notificationId, options)(this.fetch, this.basePath);
    }

    /**
     * dismisses all notifications of the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public dismissUserNotifications(options?: any) {
        return NotificationApiFp(this.configuration).dismissUserNotifications(options)(this.fetch, this.basePath);
    }

    /**
     * gets notifications for the current user
     * @param {boolean} [unread] unread only or all notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public getUserNotifications(unread?: boolean, options?: any) {
        return NotificationApiFp(this.configuration).getUserNotifications(unread, options)(this.fetch, this.basePath);
    }

}

/**
 * RankingApi - fetch parameter creator
 * @export
 */
export const RankingApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ideas count grouped by user
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRankingIdeas(limit?: number, options: any = {}): FetchArgs {
            const localVarPath = `/userRankingIdeas`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ideas count grouped by user
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRankingIdeasRecent(limit?: number, options: any = {}): FetchArgs {
            const localVarPath = `/userRankingIdeasRecent`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * scores grouped by user
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRankingPoints(limit?: number, options: any = {}): FetchArgs {
            const localVarPath = `/userRankingPoints`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * scores grouped by user
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRankingPointsRecent(limit?: number, options: any = {}): FetchArgs {
            const localVarPath = `/userRankingPointsRecent`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * queries user scores
         * @param {'IDEA_SEND' | 'COMMENT' | 'IDEA_SUBMIT' | 'IDEA_SUBMIT_WITH_DESC' | 'IDEA_SUBMIT_WITH_IMAGE' | 'IDEA_SUBMIT_COMPLETE' | 'PROFILE_IMAGE' | 'IDEA_MEMBER_REGISTRY' | 'IDEA_MEMBER_APPROVAL' | 'INITIAL' | 'INITIAL_COMMENTS' | 'IDEA_IMPLEMENTED'} [userScoreType] user score type
         * @param {string} [since] filters user scores by given start date
         * @param {string} [until] filters user scores by given end date
         * @param {number} [userId] filters user scores by user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryUserScores(userScoreType?: 'IDEA_SEND' | 'COMMENT' | 'IDEA_SUBMIT' | 'IDEA_SUBMIT_WITH_DESC' | 'IDEA_SUBMIT_WITH_IMAGE' | 'IDEA_SUBMIT_COMPLETE' | 'PROFILE_IMAGE' | 'IDEA_MEMBER_REGISTRY' | 'IDEA_MEMBER_APPROVAL' | 'INITIAL' | 'INITIAL_COMMENTS' | 'IDEA_IMPLEMENTED', since?: string, until?: string, userId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/userScores`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (userScoreType !== undefined) {
                localVarQueryParameter['userScoreType'] = userScoreType;
            }

            if (since !== undefined) {
                localVarQueryParameter['since'] = (since as any instanceof Date) ?
                    (since as any).toISOString().substr(0,10) :
                    since;
            }

            if (until !== undefined) {
                localVarQueryParameter['until'] = (until as any instanceof Date) ?
                    (until as any).toISOString().substr(0,10) :
                    until;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RankingApi - functional programming interface
 * @export
 */
export const RankingApiFp = function(configuration?: Configuration) {
    return {
        /**
         * ideas count grouped by user
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRankingIdeas(limit?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserRankingsDto> {
            const localVarFetchArgs = RankingApiFetchParamCreator(configuration).getUserRankingIdeas(limit, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * ideas count grouped by user
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRankingIdeasRecent(limit?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserRankingsDto> {
            const localVarFetchArgs = RankingApiFetchParamCreator(configuration).getUserRankingIdeasRecent(limit, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * scores grouped by user
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRankingPoints(limit?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserRankingsDto> {
            const localVarFetchArgs = RankingApiFetchParamCreator(configuration).getUserRankingPoints(limit, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * scores grouped by user
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRankingPointsRecent(limit?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserRankingsDto> {
            const localVarFetchArgs = RankingApiFetchParamCreator(configuration).getUserRankingPointsRecent(limit, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * queries user scores
         * @param {'IDEA_SEND' | 'COMMENT' | 'IDEA_SUBMIT' | 'IDEA_SUBMIT_WITH_DESC' | 'IDEA_SUBMIT_WITH_IMAGE' | 'IDEA_SUBMIT_COMPLETE' | 'PROFILE_IMAGE' | 'IDEA_MEMBER_REGISTRY' | 'IDEA_MEMBER_APPROVAL' | 'INITIAL' | 'INITIAL_COMMENTS' | 'IDEA_IMPLEMENTED'} [userScoreType] user score type
         * @param {string} [since] filters user scores by given start date
         * @param {string} [until] filters user scores by given end date
         * @param {number} [userId] filters user scores by user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryUserScores(userScoreType?: 'IDEA_SEND' | 'COMMENT' | 'IDEA_SUBMIT' | 'IDEA_SUBMIT_WITH_DESC' | 'IDEA_SUBMIT_WITH_IMAGE' | 'IDEA_SUBMIT_COMPLETE' | 'PROFILE_IMAGE' | 'IDEA_MEMBER_REGISTRY' | 'IDEA_MEMBER_APPROVAL' | 'INITIAL' | 'INITIAL_COMMENTS' | 'IDEA_IMPLEMENTED', since?: string, until?: string, userId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<UserScoreDto>> {
            const localVarFetchArgs = RankingApiFetchParamCreator(configuration).queryUserScores(userScoreType, since, until, userId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * RankingApi - factory interface
 * @export
 */
export const RankingApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * ideas count grouped by user
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRankingIdeas(limit?: number, options?: any) {
            return RankingApiFp(configuration).getUserRankingIdeas(limit, options)(fetch, basePath);
        },
        /**
         * ideas count grouped by user
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRankingIdeasRecent(limit?: number, options?: any) {
            return RankingApiFp(configuration).getUserRankingIdeasRecent(limit, options)(fetch, basePath);
        },
        /**
         * scores grouped by user
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRankingPoints(limit?: number, options?: any) {
            return RankingApiFp(configuration).getUserRankingPoints(limit, options)(fetch, basePath);
        },
        /**
         * scores grouped by user
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRankingPointsRecent(limit?: number, options?: any) {
            return RankingApiFp(configuration).getUserRankingPointsRecent(limit, options)(fetch, basePath);
        },
        /**
         * queries user scores
         * @param {'IDEA_SEND' | 'COMMENT' | 'IDEA_SUBMIT' | 'IDEA_SUBMIT_WITH_DESC' | 'IDEA_SUBMIT_WITH_IMAGE' | 'IDEA_SUBMIT_COMPLETE' | 'PROFILE_IMAGE' | 'IDEA_MEMBER_REGISTRY' | 'IDEA_MEMBER_APPROVAL' | 'INITIAL' | 'INITIAL_COMMENTS' | 'IDEA_IMPLEMENTED'} [userScoreType] user score type
         * @param {string} [since] filters user scores by given start date
         * @param {string} [until] filters user scores by given end date
         * @param {number} [userId] filters user scores by user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryUserScores(userScoreType?: 'IDEA_SEND' | 'COMMENT' | 'IDEA_SUBMIT' | 'IDEA_SUBMIT_WITH_DESC' | 'IDEA_SUBMIT_WITH_IMAGE' | 'IDEA_SUBMIT_COMPLETE' | 'PROFILE_IMAGE' | 'IDEA_MEMBER_REGISTRY' | 'IDEA_MEMBER_APPROVAL' | 'INITIAL' | 'INITIAL_COMMENTS' | 'IDEA_IMPLEMENTED', since?: string, until?: string, userId?: number, options?: any) {
            return RankingApiFp(configuration).queryUserScores(userScoreType, since, until, userId, options)(fetch, basePath);
        },
    };
};

/**
 * RankingApi - object-oriented interface
 * @export
 * @class RankingApi
 * @extends {BaseAPI}
 */
export class RankingApi extends BaseAPI {
    /**
     * ideas count grouped by user
     * @param {number} [limit] limits the returned results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RankingApi
     */
    public getUserRankingIdeas(limit?: number, options?: any) {
        return RankingApiFp(this.configuration).getUserRankingIdeas(limit, options)(this.fetch, this.basePath);
    }

    /**
     * ideas count grouped by user
     * @param {number} [limit] limits the returned results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RankingApi
     */
    public getUserRankingIdeasRecent(limit?: number, options?: any) {
        return RankingApiFp(this.configuration).getUserRankingIdeasRecent(limit, options)(this.fetch, this.basePath);
    }

    /**
     * scores grouped by user
     * @param {number} [limit] limits the returned results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RankingApi
     */
    public getUserRankingPoints(limit?: number, options?: any) {
        return RankingApiFp(this.configuration).getUserRankingPoints(limit, options)(this.fetch, this.basePath);
    }

    /**
     * scores grouped by user
     * @param {number} [limit] limits the returned results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RankingApi
     */
    public getUserRankingPointsRecent(limit?: number, options?: any) {
        return RankingApiFp(this.configuration).getUserRankingPointsRecent(limit, options)(this.fetch, this.basePath);
    }

    /**
     * queries user scores
     * @param {'IDEA_SEND' | 'COMMENT' | 'IDEA_SUBMIT' | 'IDEA_SUBMIT_WITH_DESC' | 'IDEA_SUBMIT_WITH_IMAGE' | 'IDEA_SUBMIT_COMPLETE' | 'PROFILE_IMAGE' | 'IDEA_MEMBER_REGISTRY' | 'IDEA_MEMBER_APPROVAL' | 'INITIAL' | 'INITIAL_COMMENTS' | 'IDEA_IMPLEMENTED'} [userScoreType] user score type
     * @param {string} [since] filters user scores by given start date
     * @param {string} [until] filters user scores by given end date
     * @param {number} [userId] filters user scores by user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RankingApi
     */
    public queryUserScores(userScoreType?: 'IDEA_SEND' | 'COMMENT' | 'IDEA_SUBMIT' | 'IDEA_SUBMIT_WITH_DESC' | 'IDEA_SUBMIT_WITH_IMAGE' | 'IDEA_SUBMIT_COMPLETE' | 'PROFILE_IMAGE' | 'IDEA_MEMBER_REGISTRY' | 'IDEA_MEMBER_APPROVAL' | 'INITIAL' | 'INITIAL_COMMENTS' | 'IDEA_IMPLEMENTED', since?: string, until?: string, userId?: number, options?: any) {
        return RankingApiFp(this.configuration).queryUserScores(userScoreType, since, until, userId, options)(this.fetch, this.basePath);
    }

}

/**
 * TranslationApi - fetch parameter creator
 * @export
 */
export const TranslationApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * gets a translation definition
         * @param {string} lang an ISO language code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslations(lang: string, options: any = {}): FetchArgs {
            // verify required parameter 'lang' is not null or undefined
            if (lang === null || lang === undefined) {
                throw new RequiredError('lang','Required parameter lang was null or undefined when calling getTranslations.');
            }
            const localVarPath = `/translations/{lang}`
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * creates or updates a translation definition
         * @param {string} lang an ISO language code
         * @param {any} translationDto a translations object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTranslations(lang: string, translationDto: any, options: any = {}): FetchArgs {
            // verify required parameter 'lang' is not null or undefined
            if (lang === null || lang === undefined) {
                throw new RequiredError('lang','Required parameter lang was null or undefined when calling putTranslations.');
            }
            // verify required parameter 'translationDto' is not null or undefined
            if (translationDto === null || translationDto === undefined) {
                throw new RequiredError('translationDto','Required parameter translationDto was null or undefined when calling putTranslations.');
            }
            const localVarPath = `/translations/{lang}`
                .replace(`{${"lang"}}`, encodeURIComponent(String(lang)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"any" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(translationDto || {}) : (translationDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TranslationApi - functional programming interface
 * @export
 */
export const TranslationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * gets a translation definition
         * @param {string} lang an ISO language code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslations(lang: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TranslationDto> {
            const localVarFetchArgs = TranslationApiFetchParamCreator(configuration).getTranslations(lang, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * creates or updates a translation definition
         * @param {string} lang an ISO language code
         * @param {any} translationDto a translations object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTranslations(lang: string, translationDto: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TranslationDto> {
            const localVarFetchArgs = TranslationApiFetchParamCreator(configuration).putTranslations(lang, translationDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TranslationApi - factory interface
 * @export
 */
export const TranslationApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * gets a translation definition
         * @param {string} lang an ISO language code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslations(lang: string, options?: any) {
            return TranslationApiFp(configuration).getTranslations(lang, options)(fetch, basePath);
        },
        /**
         * creates or updates a translation definition
         * @param {string} lang an ISO language code
         * @param {any} translationDto a translations object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTranslations(lang: string, translationDto: any, options?: any) {
            return TranslationApiFp(configuration).putTranslations(lang, translationDto, options)(fetch, basePath);
        },
    };
};

/**
 * TranslationApi - object-oriented interface
 * @export
 * @class TranslationApi
 * @extends {BaseAPI}
 */
export class TranslationApi extends BaseAPI {
    /**
     * gets a translation definition
     * @param {string} lang an ISO language code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationApi
     */
    public getTranslations(lang: string, options?: any) {
        return TranslationApiFp(this.configuration).getTranslations(lang, options)(this.fetch, this.basePath);
    }

    /**
     * creates or updates a translation definition
     * @param {string} lang an ISO language code
     * @param {any} translationDto a translations object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationApi
     */
    public putTranslations(lang: string, translationDto: any, options?: any) {
        return TranslationApiFp(this.configuration).putTranslations(lang, translationDto, options)(this.fetch, this.basePath);
    }

}

/**
 * UserApi - fetch parameter creator
 * @export
 */
export const UserApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Flags a user deleted
         * @param {number} userId a user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: number, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling deleteUser.');
            }
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the admin users/ideamanagers for the LV of the currently logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUsers(options: any = {}): FetchArgs {
            const localVarPath = `/adminUsers`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the currently authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthenticatedUser(options: any = {}): FetchArgs {
            const localVarPath = `/authenticatedUser`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets this users bookmarked ideas
         * @param {number} userId a user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookmarkedIdeas(userId: number, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getBookmarkedIdeas.');
            }
            const localVarPath = `/users/{userId}/bookmarkedIdeas`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets this experts evaluations
         * @param {number} userId a user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpertEvaluations(userId: number, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getExpertEvaluations.');
            }
            const localVarPath = `/users/{userId}/evaluations`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a user
         * @param {string} userValue an user id or username
         * @param {'id' | 'username'} [by] by id or username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userValue: string, by?: 'id' | 'username', options: any = {}): FetchArgs {
            // verify required parameter 'userValue' is not null or undefined
            if (userValue === null || userValue === undefined) {
                throw new RequiredError('userValue','Required parameter userValue was null or undefined when calling getUser.');
            }
            const localVarPath = `/users/{userValue}`
                .replace(`{${"userValue"}}`, encodeURIComponent(String(userValue)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (by !== undefined) {
                localVarQueryParameter['by'] = by;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a user's activities
         * @param {number} userId a user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserActivities(userId: number, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getUserActivities.');
            }
            const localVarPath = `/users/{userId}/activities`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get the awards for the user
         * @param {number} userId a user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAwards(userId: number, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getUserAwards.');
            }
            const localVarPath = `/users/{userId}/awards`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get comment of user
         * @param {number} userId a user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserComments(userId: number, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getUserComments.');
            }
            const localVarPath = `/users/{userId}/comments`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a user's ideas
         * @param {number} userId a user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserIdeas(userId: number, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getUserIdeas.');
            }
            const localVarPath = `/users/{userId}/ideas`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets this user idea memberships
         * @param {number} userId a user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserMemberships(userId: number, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getUserMemberships.');
            }
            const localVarPath = `/users/{userId}/memberships`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * searches users
         * @param {string} [searchTerm] a natural lanaguage full text search term for searching id
         * @param {number} [limit] limits the returned results
         * @param {'full' | 'slim' | 'ref'} [dtoResponseType] the desired DTO return type object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(searchTerm?: string, limit?: number, dtoResponseType?: 'full' | 'slim' | 'ref', options: any = {}): FetchArgs {
            const localVarPath = `/users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (dtoResponseType !== undefined) {
                localVarQueryParameter['dtoResponseType'] = dtoResponseType;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets users count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersCount(options: any = {}): FetchArgs {
            const localVarPath = `/users/count`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hides/shows user in rankings (only for admins)
         * @param {number} userId a user id
         * @param {string} exclude 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putExcludeFromRankings(userId: number, exclude: string, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling putExcludeFromRankings.');
            }
            // verify required parameter 'exclude' is not null or undefined
            if (exclude === null || exclude === undefined) {
                throw new RequiredError('exclude','Required parameter exclude was null or undefined when calling putExcludeFromRankings.');
            }
            const localVarPath = `/users/{userId}/excludeFromRankings`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(exclude || {}) : (exclude || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hides/shows an admin in innovation manager boxes
         * @param {number} userId a user id
         * @param {string} hide 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putHideAdmin(userId: number, hide: string, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling putHideAdmin.');
            }
            // verify required parameter 'hide' is not null or undefined
            if (hide === null || hide === undefined) {
                throw new RequiredError('hide','Required parameter hide was null or undefined when calling putHideAdmin.');
            }
            const localVarPath = `/users/{userId}/hideAdmin`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(hide || {}) : (hide || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * uploads a user profile image
         * @param {UploadDto} userProfileImage user profile image upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProfileImage(userProfileImage: UploadDto, options: any = {}): FetchArgs {
            // verify required parameter 'userProfileImage' is not null or undefined
            if (userProfileImage === null || userProfileImage === undefined) {
                throw new RequiredError('userProfileImage','Required parameter userProfileImage was null or undefined when calling putProfileImage.');
            }
            const localVarPath = `/profileImage`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UploadDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(userProfileImage || {}) : (userProfileImage || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Saves user details
         * @param {number} userId a user id
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUser(userId: number, userDto: UserDto, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling putUser.');
            }
            // verify required parameter 'userDto' is not null or undefined
            if (userDto === null || userDto === undefined) {
                throw new RequiredError('userDto','Required parameter userDto was null or undefined when calling putUser.');
            }
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(userDto || {}) : (userDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * sets a user admin (only for admins)
         * @param {number} userId a user id
         * @param {string} admin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserAdmin(userId: number, admin: string, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling putUserAdmin.');
            }
            // verify required parameter 'admin' is not null or undefined
            if (admin === null || admin === undefined) {
                throw new RequiredError('admin','Required parameter admin was null or undefined when calling putUserAdmin.');
            }
            const localVarPath = `/users/{userId}/admin`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(admin || {}) : (admin || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * flags a user external/internal (only for admins)
         * @param {number} userId a user id
         * @param {string} external 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserExternal(userId: number, external: string, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling putUserExternal.');
            }
            // verify required parameter 'external' is not null or undefined
            if (external === null || external === undefined) {
                throw new RequiredError('external','Required parameter external was null or undefined when calling putUserExternal.');
            }
            const localVarPath = `/users/{userId}/external`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", ["access:admin"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(external || {}) : (external || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hides/shows tutorial on startpage for user
         * @param {string} dismissTutorial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserTutorialDismissed(dismissTutorial: string, options: any = {}): FetchArgs {
            // verify required parameter 'dismissTutorial' is not null or undefined
            if (dismissTutorial === null || dismissTutorial === undefined) {
                throw new RequiredError('dismissTutorial','Required parameter dismissTutorial was null or undefined when calling putUserTutorialDismissed.');
            }
            const localVarPath = `/tutorialDismissed`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("auth0", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            // authentication bearer_token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(dismissTutorial || {}) : (dismissTutorial || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Flags a user deleted
         * @param {number} userId a user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).deleteUser(userId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Gets the admin users/ideamanagers for the LV of the currently logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUsers(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<UserDto>> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).getAdminUsers(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Gets the currently authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthenticatedUser(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserDto> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).getAuthenticatedUser(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * gets this users bookmarked ideas
         * @param {number} userId a user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookmarkedIdeas(userId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<IdeaDto>> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).getBookmarkedIdeas(userId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * gets this experts evaluations
         * @param {number} userId a user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpertEvaluations(userId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<EvaluationDto>> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).getExpertEvaluations(userId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Gets a user
         * @param {string} userValue an user id or username
         * @param {'id' | 'username'} [by] by id or username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userValue: string, by?: 'id' | 'username', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserDto> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).getUser(userValue, by, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Gets a user's activities
         * @param {number} userId a user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserActivities(userId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ActivityLogDto>> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).getUserActivities(userId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * get the awards for the user
         * @param {number} userId a user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAwards(userId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserAwardsDto> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).getUserAwards(userId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * get comment of user
         * @param {number} userId a user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserComments(userId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CommentDto>> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).getUserComments(userId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Gets a user's ideas
         * @param {number} userId a user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserIdeas(userId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<IdeaDto>> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).getUserIdeas(userId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * gets this user idea memberships
         * @param {number} userId a user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserMemberships(userId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<IdeaMemberDto>> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).getUserMemberships(userId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * searches users
         * @param {string} [searchTerm] a natural lanaguage full text search term for searching id
         * @param {number} [limit] limits the returned results
         * @param {'full' | 'slim' | 'ref'} [dtoResponseType] the desired DTO return type object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(searchTerm?: string, limit?: number, dtoResponseType?: 'full' | 'slim' | 'ref', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<UserDto>> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).getUsers(searchTerm, limit, dtoResponseType, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * gets users count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersCount(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).getUsersCount(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * hides/shows user in rankings (only for admins)
         * @param {number} userId a user id
         * @param {string} exclude 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putExcludeFromRankings(userId: number, exclude: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).putExcludeFromRankings(userId, exclude, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * hides/shows an admin in innovation manager boxes
         * @param {number} userId a user id
         * @param {string} hide 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putHideAdmin(userId: number, hide: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).putHideAdmin(userId, hide, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * uploads a user profile image
         * @param {UploadDto} userProfileImage user profile image upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProfileImage(userProfileImage: UploadDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserDto> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).putProfileImage(userProfileImage, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Saves user details
         * @param {number} userId a user id
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUser(userId: number, userDto: UserDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserDto> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).putUser(userId, userDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * sets a user admin (only for admins)
         * @param {number} userId a user id
         * @param {string} admin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserAdmin(userId: number, admin: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).putUserAdmin(userId, admin, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * flags a user external/internal (only for admins)
         * @param {number} userId a user id
         * @param {string} external 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserExternal(userId: number, external: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).putUserExternal(userId, external, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * hides/shows tutorial on startpage for user
         * @param {string} dismissTutorial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserTutorialDismissed(dismissTutorial: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = UserApiFetchParamCreator(configuration).putUserTutorialDismissed(dismissTutorial, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Flags a user deleted
         * @param {number} userId a user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: number, options?: any) {
            return UserApiFp(configuration).deleteUser(userId, options)(fetch, basePath);
        },
        /**
         * Gets the admin users/ideamanagers for the LV of the currently logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUsers(options?: any) {
            return UserApiFp(configuration).getAdminUsers(options)(fetch, basePath);
        },
        /**
         * Gets the currently authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthenticatedUser(options?: any) {
            return UserApiFp(configuration).getAuthenticatedUser(options)(fetch, basePath);
        },
        /**
         * gets this users bookmarked ideas
         * @param {number} userId a user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookmarkedIdeas(userId: number, options?: any) {
            return UserApiFp(configuration).getBookmarkedIdeas(userId, options)(fetch, basePath);
        },
        /**
         * gets this experts evaluations
         * @param {number} userId a user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExpertEvaluations(userId: number, options?: any) {
            return UserApiFp(configuration).getExpertEvaluations(userId, options)(fetch, basePath);
        },
        /**
         * Gets a user
         * @param {string} userValue an user id or username
         * @param {'id' | 'username'} [by] by id or username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userValue: string, by?: 'id' | 'username', options?: any) {
            return UserApiFp(configuration).getUser(userValue, by, options)(fetch, basePath);
        },
        /**
         * Gets a user's activities
         * @param {number} userId a user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserActivities(userId: number, options?: any) {
            return UserApiFp(configuration).getUserActivities(userId, options)(fetch, basePath);
        },
        /**
         * get the awards for the user
         * @param {number} userId a user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAwards(userId: number, options?: any) {
            return UserApiFp(configuration).getUserAwards(userId, options)(fetch, basePath);
        },
        /**
         * get comment of user
         * @param {number} userId a user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserComments(userId: number, options?: any) {
            return UserApiFp(configuration).getUserComments(userId, options)(fetch, basePath);
        },
        /**
         * Gets a user's ideas
         * @param {number} userId a user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserIdeas(userId: number, options?: any) {
            return UserApiFp(configuration).getUserIdeas(userId, options)(fetch, basePath);
        },
        /**
         * gets this user idea memberships
         * @param {number} userId a user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserMemberships(userId: number, options?: any) {
            return UserApiFp(configuration).getUserMemberships(userId, options)(fetch, basePath);
        },
        /**
         * searches users
         * @param {string} [searchTerm] a natural lanaguage full text search term for searching id
         * @param {number} [limit] limits the returned results
         * @param {'full' | 'slim' | 'ref'} [dtoResponseType] the desired DTO return type object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(searchTerm?: string, limit?: number, dtoResponseType?: 'full' | 'slim' | 'ref', options?: any) {
            return UserApiFp(configuration).getUsers(searchTerm, limit, dtoResponseType, options)(fetch, basePath);
        },
        /**
         * gets users count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersCount(options?: any) {
            return UserApiFp(configuration).getUsersCount(options)(fetch, basePath);
        },
        /**
         * hides/shows user in rankings (only for admins)
         * @param {number} userId a user id
         * @param {string} exclude 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putExcludeFromRankings(userId: number, exclude: string, options?: any) {
            return UserApiFp(configuration).putExcludeFromRankings(userId, exclude, options)(fetch, basePath);
        },
        /**
         * hides/shows an admin in innovation manager boxes
         * @param {number} userId a user id
         * @param {string} hide 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putHideAdmin(userId: number, hide: string, options?: any) {
            return UserApiFp(configuration).putHideAdmin(userId, hide, options)(fetch, basePath);
        },
        /**
         * uploads a user profile image
         * @param {UploadDto} userProfileImage user profile image upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProfileImage(userProfileImage: UploadDto, options?: any) {
            return UserApiFp(configuration).putProfileImage(userProfileImage, options)(fetch, basePath);
        },
        /**
         * Saves user details
         * @param {number} userId a user id
         * @param {UserDto} userDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUser(userId: number, userDto: UserDto, options?: any) {
            return UserApiFp(configuration).putUser(userId, userDto, options)(fetch, basePath);
        },
        /**
         * sets a user admin (only for admins)
         * @param {number} userId a user id
         * @param {string} admin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserAdmin(userId: number, admin: string, options?: any) {
            return UserApiFp(configuration).putUserAdmin(userId, admin, options)(fetch, basePath);
        },
        /**
         * flags a user external/internal (only for admins)
         * @param {number} userId a user id
         * @param {string} external 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserExternal(userId: number, external: string, options?: any) {
            return UserApiFp(configuration).putUserExternal(userId, external, options)(fetch, basePath);
        },
        /**
         * hides/shows tutorial on startpage for user
         * @param {string} dismissTutorial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserTutorialDismissed(dismissTutorial: string, options?: any) {
            return UserApiFp(configuration).putUserTutorialDismissed(dismissTutorial, options)(fetch, basePath);
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * Flags a user deleted
     * @param {number} userId a user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUser(userId: number, options?: any) {
        return UserApiFp(this.configuration).deleteUser(userId, options)(this.fetch, this.basePath);
    }

    /**
     * Gets the admin users/ideamanagers for the LV of the currently logged in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getAdminUsers(options?: any) {
        return UserApiFp(this.configuration).getAdminUsers(options)(this.fetch, this.basePath);
    }

    /**
     * Gets the currently authenticated user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getAuthenticatedUser(options?: any) {
        return UserApiFp(this.configuration).getAuthenticatedUser(options)(this.fetch, this.basePath);
    }

    /**
     * gets this users bookmarked ideas
     * @param {number} userId a user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getBookmarkedIdeas(userId: number, options?: any) {
        return UserApiFp(this.configuration).getBookmarkedIdeas(userId, options)(this.fetch, this.basePath);
    }

    /**
     * gets this experts evaluations
     * @param {number} userId a user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getExpertEvaluations(userId: number, options?: any) {
        return UserApiFp(this.configuration).getExpertEvaluations(userId, options)(this.fetch, this.basePath);
    }

    /**
     * Gets a user
     * @param {string} userValue an user id or username
     * @param {'id' | 'username'} [by] by id or username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(userValue: string, by?: 'id' | 'username', options?: any) {
        return UserApiFp(this.configuration).getUser(userValue, by, options)(this.fetch, this.basePath);
    }

    /**
     * Gets a user's activities
     * @param {number} userId a user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserActivities(userId: number, options?: any) {
        return UserApiFp(this.configuration).getUserActivities(userId, options)(this.fetch, this.basePath);
    }

    /**
     * get the awards for the user
     * @param {number} userId a user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserAwards(userId: number, options?: any) {
        return UserApiFp(this.configuration).getUserAwards(userId, options)(this.fetch, this.basePath);
    }

    /**
     * get comment of user
     * @param {number} userId a user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserComments(userId: number, options?: any) {
        return UserApiFp(this.configuration).getUserComments(userId, options)(this.fetch, this.basePath);
    }

    /**
     * Gets a user's ideas
     * @param {number} userId a user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserIdeas(userId: number, options?: any) {
        return UserApiFp(this.configuration).getUserIdeas(userId, options)(this.fetch, this.basePath);
    }

    /**
     * gets this user idea memberships
     * @param {number} userId a user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserMemberships(userId: number, options?: any) {
        return UserApiFp(this.configuration).getUserMemberships(userId, options)(this.fetch, this.basePath);
    }

    /**
     * searches users
     * @param {string} [searchTerm] a natural lanaguage full text search term for searching id
     * @param {number} [limit] limits the returned results
     * @param {'full' | 'slim' | 'ref'} [dtoResponseType] the desired DTO return type object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUsers(searchTerm?: string, limit?: number, dtoResponseType?: 'full' | 'slim' | 'ref', options?: any) {
        return UserApiFp(this.configuration).getUsers(searchTerm, limit, dtoResponseType, options)(this.fetch, this.basePath);
    }

    /**
     * gets users count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUsersCount(options?: any) {
        return UserApiFp(this.configuration).getUsersCount(options)(this.fetch, this.basePath);
    }

    /**
     * hides/shows user in rankings (only for admins)
     * @param {number} userId a user id
     * @param {string} exclude 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public putExcludeFromRankings(userId: number, exclude: string, options?: any) {
        return UserApiFp(this.configuration).putExcludeFromRankings(userId, exclude, options)(this.fetch, this.basePath);
    }

    /**
     * hides/shows an admin in innovation manager boxes
     * @param {number} userId a user id
     * @param {string} hide 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public putHideAdmin(userId: number, hide: string, options?: any) {
        return UserApiFp(this.configuration).putHideAdmin(userId, hide, options)(this.fetch, this.basePath);
    }

    /**
     * uploads a user profile image
     * @param {UploadDto} userProfileImage user profile image upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public putProfileImage(userProfileImage: UploadDto, options?: any) {
        return UserApiFp(this.configuration).putProfileImage(userProfileImage, options)(this.fetch, this.basePath);
    }

    /**
     * Saves user details
     * @param {number} userId a user id
     * @param {UserDto} userDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public putUser(userId: number, userDto: UserDto, options?: any) {
        return UserApiFp(this.configuration).putUser(userId, userDto, options)(this.fetch, this.basePath);
    }

    /**
     * sets a user admin (only for admins)
     * @param {number} userId a user id
     * @param {string} admin 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public putUserAdmin(userId: number, admin: string, options?: any) {
        return UserApiFp(this.configuration).putUserAdmin(userId, admin, options)(this.fetch, this.basePath);
    }

    /**
     * flags a user external/internal (only for admins)
     * @param {number} userId a user id
     * @param {string} external 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public putUserExternal(userId: number, external: string, options?: any) {
        return UserApiFp(this.configuration).putUserExternal(userId, external, options)(this.fetch, this.basePath);
    }

    /**
     * hides/shows tutorial on startpage for user
     * @param {string} dismissTutorial 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public putUserTutorialDismissed(dismissTutorial: string, options?: any) {
        return UserApiFp(this.configuration).putUserTutorialDismissed(dismissTutorial, options)(this.fetch, this.basePath);
    }

}

